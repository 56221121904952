import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, Outlet, useMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRef } from "react";

import Header from "../../components/Header";
import ViewLoader from "../../components/ViewLoader";
import Footer from "../../components/Footer";
import TabBar from "../../components/TabBar";
import useBgLoaded from "../../hooks/useBgLoaded";

export default function Feature() {
  const { t } = useTranslation();
  const match = useMatch('/features/:lastPart');
  const currentSection = match?.params.lastPart || 'brands';
  const bgImage = useRef<HTMLDivElement>(null);
  const imgLoaded = useBgLoaded(bgImage.current);

  const user = useSelector((state: any) => state.user);

  const tabs = [
    {
      section: 'brands',
      translate: 'features.tabs.brands',
      url: '/features/brands'
    },
    {
      section: 'distributors',
      translate: 'features.tabs.distributors',
      url: '/features/distributors'
    },
    {
      section: 'etailers',
      translate: 'features.tabs.etailer',
      url: '/features/etailers'
    }
  ];

  return (
    <div className="view-wrapper">
      <Header/>

      <ViewLoader/>

      <div className="view header-margin features">
        <TabBar tabs={tabs} active={currentSection} />

        <section className="fade-content">

          <Outlet/>

          <div className="features-app">
            <div className="section-inner">
              <div className="section-content">
                <h2 className="dash dash-after dash-primary">
                  {t('features.brands.app.title')}
                </h2>
                <div>
                  <Trans i18nKey={'features.brands.app.text'} />
                </div>
              </div>
            </div>
            <div className="macbook">
              <div className="frame">
                <div>
                  {/*slideshow or video here */}
                </div>
              </div>
            </div>
            <div ref={bgImage} className={`section-bg to-reveal ${imgLoaded ? 'loaded' : ''}`}></div>
          </div>
          {!user.isConnected && <div className="features-cta">
            <div className="section-inner">
              <div className="section-header">
                <h2>
                  {t('features.brands.cta.title')}
                </h2>
                <div>
                  {t('features.brands.cta.text')}
                </div>
              </div>
              <div className="section-content">
                <Link className="button button-primary" to="/signup?type=brand-distributor">
                  {t('actions.signupFree')}
                </Link>
              </div>
            </div>
          </div>}
        </section>

      </div>

      <Footer/>
    </div>
  );
}
