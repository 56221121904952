// export const API_URL = 'https://husboro.com/api/v1';
// export const API_URL = 'https://dev.husboro.com';

export const API_URL = `${process.env.REACT_APP_API_URL}/api`;
export const API_FILES_URL = `${process.env.REACT_APP_API_URL}/`;
export const SUBSCRIPTION_API_URL = process.env.REACT_APP_API_URL;
// export const SUBSCRIPTION_API_URL = 'https://dev.husboro.com/';

export const configConstant = {
  env: 'production',

  api: {
    url: 'https://husboro.com/api/v1',
    key: ''
  },

  stripe: {
    url: 'undefined',
    key: 'undefined'
  },

  pubNub: {
    subscribeKey: 'sub-c-8a9b06a4-cee5-11e7-a18a-6e7ee5016d0b',//'undefined',
    publishKey: 'pub-c-a532c499-643e-42cb-b527-e58a0e6c59b5',//'undefined',
  },

  domain: {
    url: '//husboro.com'
  },

  dropbox: {
    url: '//husboro.com/dropbox'
  },

  commissionRate: 0.1,

  patterns: {
    number: /^[1-9]\d*$/,
    percentage: /^[1-9]$|^[1-9][0-9]$|^(100)$/,

    accountNumber: /^.*$/,
    transitNumber: /^.*$/,
    institutionCode: /^.*$/,
    swiftCode: /^.*$/,
    abaNumber: /^.*$/,
    email: /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/,
    password: /^(?=.*\d)[0-9a-zA-Z]{8,}$/,
    phone: /^\d{10}$/,
    postalCode: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
    zipCode: /^\d{5}(?:[-\s]\d{4})?$/,
    cardNumber: /^\d{16}$/,
    cardCvv2: /^\d{3,4}$/,
    url: /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  },




  list: {
    brandsPerLoad: 12,
    productsPerLoad: 24
  },




  modelOptions: {
    default: {
      updateOn: 'default',
      allowInvalid: 'true'
    }
  },





  brands: {
    categories: [
      {
        id: 0,
        label: 'all',
        name: 'All',
        url: '/?category=all' // generated in-app
      },
      {
        id: 1,
        label: 'toys-kids-babies',
        name: 'Toys (kids & babies)',
        url: '?category=toys-kids-babies' // generated in-app
      },
      {
        id: 2,
        label: 'animal',
        name: 'Animal',
        url: '?category=animal' // generated in-app
      },
      {
        id: 3,
        label: 'apparel-shoes-jewelry',
        name: 'Apparel, shoes & jewelry',
        url: '/?category=apparel-shoes-jewelry' // generated in-app
      },
      {
        id: 4,
        label: 'beauty',
        name: 'Beauty',
        url: '/?category=beauty' // generated in-app
      },
      {
        id: 5,
        label: 'automotive',
        name: 'Automotive',
        url: '/?category=automotive' // generated in-app
      },
      {
        id: 6,
        label: 'electronics',
        name: 'Electronics',
        url: '/?category=electronics' // generated in-app
      },
      {
        id: 7,
        label: 'home-garden-tools',
        name: 'Home, garden & tools',
        url: '/?category=home-garden-tools' // generated in-app
      },
      {
        id: 8,
        label: 'foodies',
        name: 'Foodies',
        url: '/?category=foodies' // generated in-app
      },
      {
        id: 9,
        label: 'sports',
        name: 'Sports',
        url: '/?category=sports' // generated in-app
      },
      {
        id: 10,
        label: 'adult',
        name: 'Adult',
        url: '/?category=adult' // generated in-app
      },
      {
        id: 11,
        label: 'other',
        name: 'Other',
        url: '/?category=other' // generated in-app
      }
    ],
    subcategories: {
      1: [
        {
          id: 0,
          label: 'all',
          name: 'All',
          url: '?subcategory=all' // generated in-app
        },
        {
          id: 1,
          label: 'toys-games',
          name: 'Toys & Games',
          url: '?subcategory=toys-games' // generated in-app
        },
        {
          id: 2,
          label: 'baby',
          name: 'Baby',
          url: '?subcategory=baby' // generated in-app
        },
        {
          id: 3,
          label: 'kid-clothing',
          name: 'Kid Clothing',
          url: '?subcategory=kid-clothing' // generated in-app
        },
        {
          id: 4,
          label: 'baby-clothing',
          name: 'Baby Clothing',
          url: '?subcategory=baby-clothing' // generated in-app
        },
        {
          id: 5,
          label: 'birthdays',
          name: 'Birthdays',
          url: '?subcategory=birthdays' // generated in-app
        }
      ]
    }
  },

  forceLogoutCodes: [
  ],

  redirects: {
    home: 'home',
    login: 'identification.login',
    dashboard: 'account.dashboard'
  },

  notification: {
    autoDismissDelay: 3400
  },

  selectorOptions: {
    avatar: {
      maxFiles: 1,
      multiple: false,
      accept: '.jpg,.jpeg,.png',
      validate: {
        pattern: '.jpg,.jpeg,.png',
        size: {
          max: '10MB'
        },
        width: {
          min: 360
        },
        height: {
          min: 360
        }
      }
    },
    logo: {
      maxFiles: 1,
      multiple: true,
      accept: '.jpg,.jpeg,.png',
      validate: {
        pattern: '.jpg,.jpeg,.png',
        size: {
          max: '10MB'
        },
        width: {
          min: 360
        },
        height: {
          min: 360
        }
      }
    },
    cover: {
      maxFiles: 1,
      multiple: true,
      accept: '.jpg,.jpeg,.png',
      validate: {
        pattern: '.jpg,.jpeg,.png',
        size: {
          max: '10MB'
        },
        width: {
          min: 1280
        },
        height: {
          min: 720
        }
      }
    },
    image: {
      maxFiles: 1,
      multiple: true,
      accept: '.jpg,.jpeg,.png',
      validate: {
        pattern: '.jpg,.jpeg,.png',
        size: {
          max: '10MB'
        },
        width: {
          min: 360
        },
        height: {
          min: 360
        }
      }
    },
    spreadsheet: {
      maxFiles: 1,
      multiple: false,
      accept: '.xls,.xlsx', // csv ?
      validate: {
        pattern: '.xls,.xlsx', // csv ?
        size: {
          max: '2MB'
        }
      }
    }
  },


  upload: {
    avatar: [
      {
        mode: 'avatar',
        width: 360,
        height: 360,
        imageType: 'image/jpeg',
        quality: 0.8
      },
      {
        mode: 'avatar1x',
        width: 180,
        height: 180,
        imageType: 'image/jpeg',
        quality: 0.8
      }
    ],
    logo: [
      {
        mode: 'logo',
        width: 360,
        height: 360,
        imageType: 'image/jpeg',
        quality: 0.8
      },
      {
        mode: 'logo1x',
        width: 180,
        height: 180,
        imageType: 'image/jpeg',
        quality: 0.8
      }
    ],
    cover: [
      {
        mode: 'cover',
        width: 1280,
        height: 720,
        imageType: 'image/jpeg',
        quality: 0.8
      },
      {
        mode: 'cover1x',
        width: 640,
        height: 360,
        imageType: 'image/jpeg',
        quality: 0.8
      }
    ],
    image: [
      {
        mode: 'image',
        width: 360,
        height: 360,
        imageType: 'image/jpeg',
        quality: 0.8
      },
      {
        mode: 'image1x',
        width: 180,
        height: 180,
        imageType: 'image/jpeg',
        quality: 0.8
      }
    ]
  },

  zoom: {
    avatar: {
      values: {
        min: 0,
        max: 0.5
      },
      limits: {
        min: 0,
        max: 0.5,
        default: 0.5,
        steps: 4
      }
    },
    logo: {
      values: {
        min: 0,
        max: 0.5
      },
      limits: {
        min: 0,
        max: 0.5,
        default: 0.5,
        steps: 4
      }
    },
    cover: {
      values: {
        min: 0,
        max: 0.5
      },
      limits: {
        min: 0,
        max: 0.5,
        default: 0.5,
        steps: 4
      }
    },
    image: {
      values: {
        min: 0,
        max: 0.5
      },
      limits: {
        min: 0,
        max: 0.5,
        default: 0.5,
        steps: 4
      }
    }
  },

  mode: {
    avatar: {
      width: 180,
      height: 180,
      zoom: {
        min: 0.25,
        max: 0.5,
        default: 0.5
      }
    },
    logo: {
      width: 180,
      height: 180,
      zoom: {
        min: 0.25,
        max: 0.5,
        default: 0.5
      }
    },
    cover: {
      width: 640,
      height: 360,
      zoom: {
        min: 0.25,
        max: 0.5,
        default: 0.5
      }
    },
    image: {
      width: 180,
      height: 180,
      zoom: {
        min: 0.25,
        max: 0.5,
        default: 0.5
      }
    }
  },

  crop: {
  },

  navigation: {
  },

  cache: [
  ]
};

export const storage = {
  data: {
    // make sure we load all of this from db
    countries: [
      { value: 'CA', label: 'Canada' },
      { value: 'US', label: 'United States' },
      //...
      { value: 'AF', label: 'Afghanistan' },
      { value: 'AX', label: 'Åland Islands' },
      { value: 'AL', label: 'Albania' },
      { value: 'DZ', label: 'Algeria' },
      { value: 'AS', label: 'American Samoa' },
      { value: 'AD', label: 'AndorrA' },
      { value: 'AO', label: 'Angola' },
      { value: 'AI', label: 'Anguilla' },
      { value: 'AQ', label: 'Antarctica' },
      { value: 'AG', label: 'Antigua and Barbuda' },
      { value: 'AR', label: 'Argentina' },
      { value: 'AM', label: 'Armenia' },
      { value: 'AW', label: 'Aruba' },
      { value: 'AU', label: 'Australia' },
      { value: 'AT', label: 'Austria' },
      { value: 'AZ', label: 'Azerbaijan' },
      { value: 'BS', label: 'Bahamas' },
      { value: 'BH', label: 'Bahrain' },
      { value: 'BD', label: 'Bangladesh' },
      { value: 'BB', label: 'Barbados' },
      { value: 'BY', label: 'Belarus' },
      { value: 'BE', label: 'Belgium' },
      { value: 'BZ', label: 'Belize' },
      { value: 'BJ', label: 'Benin' },
      { value: 'BM', label: 'Bermuda' },
      { value: 'BT', label: 'Bhutan' },
      { value: 'BO', label: 'Bolivia' },
      { value: 'BA', label: 'Bosnia and Herzegovina' },
      { value: 'BW', label: 'Botswana' },
      { value: 'BV', label: 'Bouvet Island' },
      { value: 'BR', label: 'Brazil' },
      { value: 'IO', label: 'British Indian Ocean Territory' },
      { value: 'BN', label: 'Brunei Darussalam' },
      { value: 'BG', label: 'Bulgaria' },
      { value: 'BF', label: 'Burkina Faso' },
      { value: 'BI', label: 'Burundi' },
      { value: 'KH', label: 'Cambodia' },
      { value: 'CM', label: 'Cameroon' },
      { value: 'CV', label: 'Cape Verde' },
      { value: 'KY', label: 'Cayman Islands' },
      { value: 'CF', label: 'Central African Republic' },
      { value: 'TD', label: 'Chad' },
      { value: 'CL', label: 'Chile' },
      { value: 'CN', label: 'China' },
      { value: 'CX', label: 'Christmas Island' },
      { value: 'CC', label: 'Cocos (Keeling) Islands' },
      { value: 'CO', label: 'Colombia' },
      { value: 'KM', label: 'Comoros' },
      { value: 'CG', label: 'Congo' },
      { value: 'CD', label: 'Congo, The Democratic Republic of the' },
      { value: 'CK', label: 'Cook Islands' },
      { value: 'CR', label: 'Costa Rica' },
      { value: 'CI', label: 'Cote D\'Ivoire' },
      { value: 'HR', label: 'Croatia' },
      { value: 'CU', label: 'Cuba' },
      { value: 'CY', label: 'Cyprus' },
      { value: 'CZ', label: 'Czech Republic' },
      { value: 'DK', label: 'Denmark' },
      { value: 'DJ', label: 'Djibouti' },
      { value: 'DM', label: 'Dominica' },
      { value: 'DO', label: 'Dominican Republic' },
      { value: 'EC', label: 'Ecuador' },
      { value: 'EG', label: 'Egypt' },
      { value: 'SV', label: 'El Salvador' },
      { value: 'GQ', label: 'Equatorial Guinea' },
      { value: 'ER', label: 'Eritrea' },
      { value: 'EE', label: 'Estonia' },
      { value: 'ET', label: 'Ethiopia' },
      { value: 'FK', label: 'Falkland Islands (Malvinas)' },
      { value: 'FO', label: 'Faroe Islands' },
      { value: 'FJ', label: 'Fiji' },
      { value: 'FI', label: 'Finland' },
      { value: 'FR', label: 'France' },
      { value: 'GF', label: 'French Guiana' },
      { value: 'PF', label: 'French Polynesia' },
      { value: 'TF', label: 'French Southern Territories' },
      { value: 'GA', label: 'Gabon' },
      { value: 'GM', label: 'Gambia' },
      { value: 'GE', label: 'Georgia' },
      { value: 'DE', label: 'Germany' },
      { value: 'GH', label: 'Ghana' },
      { value: 'GI', label: 'Gibraltar' },
      { value: 'GR', label: 'Greece' },
      { value: 'GL', label: 'Greenland' },
      { value: 'GD', label: 'Grenada' },
      { value: 'GP', label: 'Guadeloupe' },
      { value: 'GU', label: 'Guam' },
      { value: 'GT', label: 'Guatemala' },
      { value: 'GG', label: 'Guernsey' },
      { value: 'GN', label: 'Guinea' },
      { value: 'GW', label: 'Guinea-Bissau' },
      { value: 'GY', label: 'Guyana' },
      { value: 'HT', label: 'Haiti' },
      { value: 'HM', label: 'Heard Island and Mcdonald Islands' },
      { value: 'VA', label: 'Holy See (Vatican City State)' },
      { value: 'HN', label: 'Honduras' },
      { value: 'HK', label: 'Hong Kong' },
      { value: 'HU', label: 'Hungary' },
      { value: 'IS', label: 'Iceland' },
      { value: 'IN', label: 'India' },
      { value: 'ID', label: 'Indonesia' },
      { value: 'IR', label: 'Iran, Islamic Republic Of' },
      { value: 'IQ', label: 'Iraq' },
      { value: 'IE', label: 'Ireland' },
      { value: 'IM', label: 'Isle of Man' },
      { value: 'IL', label: 'Israel' },
      { value: 'IT', label: 'Italy' },
      { value: 'JM', label: 'Jamaica' },
      { value: 'JP', label: 'Japan' },
      { value: 'JE', label: 'Jersey' },
      { value: 'JO', label: 'Jordan' },
      { value: 'KZ', label: 'Kazakhstan' },
      { value: 'KE', label: 'Kenya' },
      { value: 'KI', label: 'Kiribati' },
      { value: 'KP', label: 'Korea, Democratic People\'s Republic of' },
      { value: 'KR', label: 'Korea, Republic of' },
      { value: 'KW', label: 'Kuwait' },
      { value: 'KG', label: 'Kyrgyzstan' },
      { value: 'LA', label: 'Lao People\'s Democratic Republic' },
      { value: 'LV', label: 'Latvia' },
      { value: 'LB', label: 'Lebanon' },
      { value: 'LS', label: 'Lesotho' },
      { value: 'LR', label: 'Liberia' },
      { value: 'LY', label: 'Libyan Arab Jamahiriya' },
      { value: 'LI', label: 'Liechtenstein' },
      { value: 'LT', label: 'Lithuania' },
      { value: 'LU', label: 'Luxembourg' },
      { value: 'MO', label: 'Macao' },
      { value: 'MK', label: 'Macedonia, The Former Yugoslav Republic of' },
      { value: 'MG', label: 'Madagascar' },
      { value: 'MW', label: 'Malawi' },
      { value: 'MY', label: 'Malaysia' },
      { value: 'MV', label: 'Maldives' },
      { value: 'ML', label: 'Mali' },
      { value: 'MT', label: 'Malta' },
      { value: 'MH', label: 'Marshall Islands' },
      { value: 'MQ', label: 'Martinique' },
      { value: 'MR', label: 'Mauritania' },
      { value: 'MU', label: 'Mauritius' },
      { value: 'YT', label: 'Mayotte' },
      { value: 'MX', label: 'Mexico' },
      { value: 'FM', label: 'Micronesia, Federated States of' },
      { value: 'MD', label: 'Moldova, Republic of' },
      { value: 'MC', label: 'Monaco' },
      { value: 'MN', label: 'Mongolia' },
      { value: 'MS', label: 'Montserrat' },
      { value: 'MA', label: 'Morocco' },
      { value: 'MZ', label: 'Mozambique' },
      { value: 'MM', label: 'Myanmar' },
      { value: 'NA', label: 'Namibia' },
      { value: 'NR', label: 'Nauru' },
      { value: 'NP', label: 'Nepal' },
      { value: 'NL', label: 'Netherlands' },
      { value: 'AN', label: 'Netherlands Antilles' },
      { value: 'NC', label: 'New Caledonia' },
      { value: 'NZ', label: 'New Zealand' },
      { value: 'NI', label: 'Nicaragua' },
      { value: 'NE', label: 'Niger' },
      { value: 'NG', label: 'Nigeria' },
      { value: 'NU', label: 'Niue' },
      { value: 'NF', label: 'Norfolk Island' },
      { value: 'MP', label: 'Northern Mariana Islands' },
      { value: 'NO', label: 'Norway' },
      { value: 'OM', label: 'Oman' },
      { value: 'PK', label: 'Pakistan' },
      { value: 'PW', label: 'Palau' },
      { value: 'PS', label: 'Palestinian Territory, Occupied' },
      { value: 'PA', label: 'Panama' },
      { value: 'PG', label: 'Papua New Guinea' },
      { value: 'PY', label: 'Paraguay' },
      { value: 'PE', label: 'Peru' },
      { value: 'PH', label: 'Philippines' },
      { value: 'PN', label: 'Pitcairn' },
      { value: 'PL', label: 'Poland' },
      { value: 'PT', label: 'Portugal' },
      { value: 'PR', label: 'Puerto Rico' },
      { value: 'QA', label: 'Qatar' },
      { value: 'RE', label: 'Reunion' },
      { value: 'RO', label: 'Romania' },
      { value: 'RU', label: 'Russian Federation' },
      { value: 'RW', label: 'Rwanda' },
      { value: 'SH', label: 'Saint Helena' },
      { value: 'KN', label: 'Saint Kitts and Nevis' },
      { value: 'LC', label: 'Saint Lucia' },
      { value: 'PM', label: 'Saint Pierre and Miquelon' },
      { value: 'VC', label: 'Saint Vincent and the Grenadines' },
      { value: 'WS', label: 'Samoa' },
      { value: 'SM', label: 'San Marino' },
      { value: 'ST', label: 'Sao Tome and Principe' },
      { value: 'SA', label: 'Saudi Arabia' },
      { value: 'SN', label: 'Senegal' },
      { value: 'CS', label: 'Serbia and Montenegro' },
      { value: 'SC', label: 'Seychelles' },
      { value: 'SL', label: 'Sierra Leone' },
      { value: 'SG', label: 'Singapore' },
      { value: 'SK', label: 'Slovakia' },
      { value: 'SI', label: 'Slovenia' },
      { value: 'SB', label: 'Solomon Islands' },
      { value: 'SO', label: 'Somalia' },
      { value: 'ZA', label: 'South Africa' },
      { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
      { value: 'ES', label: 'Spain' },
      { value: 'LK', label: 'Sri Lanka' },
      { value: 'SD', label: 'Sudan' },
      { value: 'SR', label: 'Suriname' },
      { value: 'SJ', label: 'Svalbard and Jan Mayen' },
      { value: 'SZ', label: 'Swaziland' },
      { value: 'SE', label: 'Sweden' },
      { value: 'CH', label: 'Switzerland' },
      { value: 'SY', label: 'Syrian Arab Republic' },
      { value: 'TW', label: 'Taiwan, Province of China' },
      { value: 'TJ', label: 'Tajikistan' },
      { value: 'TZ', label: 'Tanzania, United Republic of' },
      { value: 'TH', label: 'Thailand' },
      { value: 'TL', label: 'Timor-Leste' },
      { value: 'TG', label: 'Togo' },
      { value: 'TK', label: 'Tokelau' },
      { value: 'TO', label: 'Tonga' },
      { value: 'TT', label: 'Trinidad and Tobago' },
      { value: 'TN', label: 'Tunisia' },
      { value: 'TR', label: 'Turkey' },
      { value: 'TM', label: 'Turkmenistan' },
      { value: 'TC', label: 'Turks and Caicos Islands' },
      { value: 'TV', label: 'Tuvalu' },
      { value: 'UG', label: 'Uganda' },
      { value: 'UA', label: 'Ukraine' },
      { value: 'AE', label: 'United Arab Emirates' },
      { value: 'GB', label: 'United Kingdom' },
      { value: 'UM', label: 'United States Minor Outlying Islands' },
      { value: 'UY', label: 'Uruguay' },
      { value: 'UZ', label: 'Uzbekistan' },
      { value: 'VU', label: 'Vanuatu' },
      { value: 'VE', label: 'Venezuela' },
      { value: 'VN', label: 'Viet Nam' },
      { value: 'VG', label: 'Virgin Islands, British' },
      { value: 'VI', label: 'Virgin Islands, U.S.' },
      { value: 'WF', label: 'Wallis and Futuna' },
      { value: 'EH', label: 'Western Sahara' },
      { value: 'YE', label: 'Yemen' },
      { value: 'ZM', label: 'Zambia' },
      { value: 'ZW', label: 'Zimbabwe' }
    ],
    states: {
      CA: [
        { value: 'AB',label: 'Alberta' },
        { value: 'BC',label: 'British Columbia' },
        { value: 'MB', label: 'Manitoba' },
        { value: 'NB', label: 'New Brunswick' },
        { value: 'NL', label: 'Newfoundland and Labrador' },
        { value: 'NS', label: 'Nova Scotia' },
        { value: 'NU', label: 'Nunavut' },
        { value: 'NT', label: 'Northwest Territories'},
        { value: 'ON', label: 'Ontario' },
        { value: 'PE', label: 'Prince Edward Island' },
        { value: 'QC', label: 'Quebec' },
        { value: 'SK', label: 'Saskatchewan' },
        { value: 'YT', label: 'Yukon' }
      ],
      US: [
        { value: 'AL', label: 'Alabama' },
        { value: 'AK', label: 'Alaska' },
        { value: 'AZ', label: 'Arizona' },
        { value: 'AR', label: 'Arkansas' },
        { value: 'CA', label: 'California' },
        { value: 'CO', label: 'Colorado' },
        { value: 'CT', label: 'Connecticut' },
        { value: 'DC', label: 'District of Columbia' },
        { value: 'DE', label: 'Delaware' },
        { value: 'FL', label: 'Florida' },
        { value: 'GA', label: 'Georgia' },
        { value: 'HI', label: 'Hawaii' },
        { value: 'ID', label: 'Idaho' },
        { value: 'IL', label: 'Illinois' },
        { value: 'IN', label: 'Indiana' },
        { value: 'IA', label: 'Iowa' },
        { value: 'KS', label: 'Kansas' },
        { value: 'KY', label: 'Kentucky' },
        { value: 'LA', label: 'Louisiana' },
        { value: 'ME', label: 'Maine' },
        { value: 'MD', label: 'Maryland' },
        { value: 'MA', label: 'Massachusetts' },
        { value: 'MI', label: 'Michigan' },
        { value: 'MN', label: 'Minnesota' },
        { value: 'MS', label: 'Mississippi' },
        { value: 'MO', label: 'Missouri' },
        { value: 'MT', label: 'Montana' },
        { value: 'NE', label: 'Nebraska' },
        { value: 'NV', label: 'Nevada' },
        { value: 'NH', label: 'New Hampshire' },
        { value: 'NJ', label: 'New Jersey' },
        { value: 'NM', label: 'New Mexico' },
        { value: 'NY', label: 'New York' },
        { value: 'NC', label: 'North Carolina' },
        { value: 'ND', label: 'North Dakota' },
        { value: 'OH', label: 'Ohio' },
        { value: 'OK', label: 'Oklahoma' },
        { value: 'OR', label: 'Oregon' },
        { value: 'PA', label: 'Pennsylvania' },
        { value: 'RI', label: 'Rhode Island' },
        { value: 'SC', label: 'South Carolina' },
        { value: 'SD', label: 'South Dakota' },
        { value: 'TN', label: 'Tennessee' },
        { value: 'TX', label: 'Texas' },
        { value: 'UT', label: 'Utah' },
        { value: 'VT', label: 'Vermont' },
        { value: 'VA', label: 'Virginia' },
        { value: 'WA', label: 'Washington' },
        { value: 'WV', label: 'West Virginia' },
        { value: 'WI', label: 'Wisconsin' },
        { value: 'WY', label: 'Wyoming' },
        { value: 'AS', label: 'American Samoa' },
        { value: 'GU', label: 'Guam' },
        { value: 'MP', label: 'Northern Mariana Islands' },
        { value: 'PR', label: 'Puerto Rico' },
        { value: 'UM', label: 'United States Minor Outlying Islands' },
        { value: 'VI', label: 'Virgin Islands' }
      ]
    },
    categories: [],
    subcategories: {}
  }
}
