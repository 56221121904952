import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";

import Avatar from "../Avatar";
import { API_URL } from "../../constants";
import { logout } from "src/resucers/user/userSlice";

export default function Header({ isBg = true }) {
  const { t } = useTranslation();
  const [rates, setRates] = useState<any | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // @ts-ignore
  const user = useSelector((state) => state.user);
  const userType = user?.profile?.type?.id;
  const isBayer = userType === 2;

  const setIsSticky = () => {
    const header = document.querySelector('.header-bottom');
    const scrollTop = window.scrollY;

    if (header) {
      scrollTop >= 30 ? header.classList.add('sticky') : header.classList.remove('sticky');
      scrollTop >= 120 && !isBg ? header.classList.add('header-bg') : header.classList.remove('header-bg');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setIsSticky);
    return () => {
      window.removeEventListener('scroll', setIsSticky);
    };
  }, []);

  useEffect(() => {
    axios.get(API_URL + '/rates')
      .then((response) => {
        setRates(response.data.rates);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const contextualMenu = useMemo(() =>[
    {
      type: 'link',
      label: 'actions.dashboard',
      value: 'dashboard',
      disabled: false
    },
    {
      type: 'link',
      label: 'actions.manageCompany',
      value: 'company',
      disabled: false
    },
    ...(!isBayer ? [{
      type: 'link',
      label: 'actions.manageBrands',
      value: 'brands',
      disabled: false
    }] : [] as any),
    {
      type: 'divider'
    },
    {
      type: 'link',
      label: 'actions.editAccount',
      value: 'account',
      disabled: false
    },
    {
      type: 'link',
      label: 'actions.logout',
      value: 'logout',
      disabled: false
    }
  ], []);

  const contextualMenuCallback = function(item: any) {
    switch(item.value) {
      case 'dashboard':
        navigate('/account/dashboard');
        break;
      case 'account':
        navigate('/account/user', {
          state: {
            section: 'profile'
          }
        });
        break;
      case 'logout':
        dispatch(logout());
        navigate('/');
        break;
      case 'company':
        navigate('/account/company', {
          state: {
            section: 'profile'
          }
        });
        break;
      case 'brands':
        navigate('/account/brands', {
          state: {
            section: null,
            selection: null
          }
        });
        break;
    }
  };

  return (
    <header className={`header fade-content ${isBg ? 'header-bg' : ''}`}>
      <div className="header-top">
        <div className="header-top-inner">
          <div>
            {/*<span ng-if="$ctrl.data.env != \'production\'" translate="environment" translate-values="{ env: $ctrl.data.env }"></span>*/}
          </div>
          {rates?.CAD && <div className="rates">
            <span>Today</span>
            <span className="badge">1 USD</span>
            <span>=</span>
            {rates?.CAD && <span className="badge">{rates.CAD + ' CAD'}</span>}
            {rates?.EUR && <span className="badge">{rates.EUR + ' EUR'}</span>}
          </div>}
        </div>
      </div>
      <div className="header-bottom">
        <div className="header-bottom-inner">
          <div className="logo">
            <Link to="/">{t('husboro.name')}</Link>
            <span>{t('husboro.tagline')}</span>
          </div>
          <div>
            {!user.isConnected && <div className="menu fade-content">
              <NavLink
                className={({isActive}) => `txt-uppercase ${isActive ? 'active' : ''}`}
                to="/features"
              >
                {t('header.menu.features')}
              </NavLink>
              <Link className="txt-uppercase" to="/login">{t('header.menu.browseBrands')}</Link>
              <span className="spacer"></span>
              <NavLink
                className={({isActive}) => `txt-uppercase ${isActive ? 'active' : ''}`}
                to="/login"
              >
                {t('actions.login')}
              </NavLink>
              <Link
                className="button button-primary"
                to="/signup"
              >
                {t('actions.signupFree')}
              </Link>
            </div>}
            {user.isConnected && <div className="menu fade-content">
              <NavLink
                className={({isActive}) => `txt-uppercase ${isActive ? 'active' : ''}`}
                to="/browse-brands"
              >
                {t('header.menu.browseBrands')}
              </NavLink>
              {/*<a className="txt-uppercase" ui-sref="browse" ui-sref-active="active" translate="header.menu.viewProfile"></a>*/}
              {/* define brand or distributor url... maybe in User */}
              <span className="spacer"></span>
              <Link className="icon" to="/account/offers" state={{ selection: null, section: null }}>
                <span className="icon-24-offer"></span>
                {!!user.profile?.newOffersCount && <strong className="badge badge-pill bg-primary">
                  {user.profile.newOffersCount}
                </strong>}
              </Link>
              <Link className="icon" to="/account/messages">
                <span className="icon-24-message"></span>
                {!!user?.profile?.unreadMessagesCount && <strong className="badge badge-pill bg-primary"
                >
                  {user.profile.unreadMessagesCount}
                </strong>}
              </Link>
              <div className="header-panel-wrapper">
                <Avatar className="avatar-small" model={{
                  name: user.profile?.name,
                  image: user.profile?.account?.avatar
                }} />
                <div className="header-panel">
                  <ul className="contextual-menu-list">
                    {contextualMenu.map((item: any, index) => (
                      <li key={item.value + index}>
                        {item.type === 'link' && <button
                           className={item.disabled ? 'disabled' : ''}
                           onClick={() => contextualMenuCallback(item)}
                        >
                          {t(item.label)}
                        </button>}
                        {item.type === 'divider' && <div className="divider"></div>}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </header>
  );
}
