import { default as React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "src/api";
import { API_URL } from "src/constants";
import { parseApiError } from "../../../utils";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import ImageGallery from 'react-image-gallery';
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import { useSelector } from "react-redux";

export default function ProductView() {
  const params = useParams();
  const brandId = params.id;
  const id = params.productId;
  const notification = useNotification();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState<any>(null);
  const user = useSelector((state: any) => state.user);
  const userType = user?.profile?.type?.id;
  const isBuyer = userType === 2;

  useEffect(() => {
    setIsLoading(true);
    api.get(API_URL + '/products/' + id)
      .then((response) => {
        const data = response.data.data;
        setProduct(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        parseApiError(error, notification, null);
      });
  }, [id]);

  const goToOffer  = () => {
    navigate('/brand-view/' + brandId + '/products?chosenProduct=' + product.id)
  };

  const images = product?.images?.map((i: string) => ({
    original: i,
    thumbnail: i,
    originalWidth: 500
  }));

  return (
    <div>
      {isLoading && <Loader />}
      {product && <div className="section-inner" style={{ maxWidth: '1200px' }} >
        <div className="section-header">
          <h1>{product.name}</h1>
        </div>
        <div className="product-details">
          <div style={{ width: '600px' }}>
            {images && <ImageGallery
              items={images}
              showPlayButton={false}
              showFullscreenButton={false}
              thumbnailPosition="left"
            />}
          </div>
          <div className="product-details-info">
            <div className="product-main-info">
              <div className="wholesale">
                <strong>
                  SKU
                </strong>
                <small>
                  {product.sku}
                </small>
              </div>
              <div className="wholesale">
                <strong>
                  WHOLESALE
                </strong>
                <small>
                  {isBuyer ? '$' + product.wholesale : '-'}
                </small>
              </div>
              <div className="wholesale">
                <strong>
                  MSRP
                </strong>
                <small>
                  ${product.msrp}
                </small>
              </div>
            </div>
            <div className="action-wrap">
              <button className={`button button-block button-success ${!isBuyer ? 'button-disabled' : ''}`} onClick={goToOffer}>
                Create offer
              </button>
            </div>
            <p>
              {product.description}
            </p>
          </div>
        </div>
      </div>}
    </div>
  );
}
