import { Trans, useTranslation } from "react-i18next";
import { default as React, useCallback, useEffect, useState } from "react";
import FileSelector from "../../../components/FileSelector";
import Loader from "../../../components/Loader";
import api from "../../../api";
import { API_FILES_URL, API_URL } from "../../../constants";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import { parseApiError } from "../../../utils";
import { Link, useParams } from "react-router-dom";

export default function BrandProducts() {
  const {t} = useTranslation();
  const notification = useNotification();
  const params = useParams();

  const id = params.id;

  const [fileError, setFileError] = useState('');
  const [spreadsheetError, setSpreadsheetError] = useState<any[]>([]);
  const [isParsing, setIsParsing] = useState(false);
  const [products, setProducts] = useState<any>([]);

  const getBrandProducts = () => {
    api.get(API_URL + '/brands/' + id)
      .then(function (res) {
        const data = res.data.data;

        setProducts(data.products);
      })
      .catch((error) => {
        console.log(error);
        parseApiError(error, notification, null);
      });
  };

  useEffect(() => {
    getBrandProducts();
  }, []);

  const handleUpload = useCallback((files: any) => {
    setIsParsing(true);
    const file = files[0];
    const data = new FormData();

    data.append('products', file, file.name);

    api.post(API_URL + `/brands/${id}/products`, data, {headers: {"Content-Type": "multipart/form-data"}})
      .then((res) => {
        const data = res.data.data;

        if (data.errors?.length) {
          setIsParsing(false);
          setFileError(data.errors[0].message);
          setSpreadsheetError(data.errors);
          return
        }

        setIsParsing(false);
        notification.show({
          type: 'success',
          message: {
            translate: 'Brand product has been successfully uploaded!'
          },
          autoDismiss: true
        });

        getBrandProducts();
      })
      .catch((error) => {
        parseApiError(error, notification, null);
        setIsParsing(false);
      });
  }, []);

  const handleError = (files: any) => {

  };

  const removeSpreadsheet = () => {

  };

  const templateUrl = API_FILES_URL + 'template.xlsx';

  return (
    <section className="fade-content">
      <div className="section-header dash dash-after dash-primary">
        <h1>
          {t('account.brand.products.header.title')}
        </h1>
        <div>
          <Trans i18nKey={"account.brand.products.header.text"}/>
        </div>
      </div>
      <div>
        <Link
          className="icon"
          style={{
            justifyContent: 'center',
            margin: '0 auto',
            textAlign: 'center'
          }}
          to={templateUrl}
          target="_blank"
          download
        >
          <span className="icon-link-file"></span>
          <span>
           {t('actions.downloadTemplate')}
         </span>
        </Link>
      </div>
      <div className="column">
        <div className="column-left">
          <div className="section-content form">
            <ul className="form-fieldset">
              <li>
                <div>
                  <strong className="txt-primary">
                    {t('account.brand.products.spreadsheet.title')}
                  </strong>
                  <div className="txt-grey">
                    <Trans i18nKey={"account.brand.products.spreadsheet.text"}/>
                  </div>
                </div>
              </li>
              <li>
                <div className="spreadsheet-drop">
                  <FileSelector
                    options={{}}
                    info={t('field.info.spreadsheet')}
                    error={fileError}
                    onChange={handleUpload}
                    onError={handleError}
                  >
                    {isParsing && <div className="rollup">
                      <Loader />
                    </div>}
                    {!isParsing && !!products?.length && <div style={{ paddingTop: '40px', textAlign: 'center' }}>
                       <div
                         style={{
                         display: 'inline-block',
                         padding: '16px',
                         backgroundColor: '#fff',
                         borderRadius: '4px',
                         border: '1px solid #ddd',
                         position: 'relative'
                       }}
                       >
                         <div
                           style={{
                             display: 'absolute',
                             width: '24px',
                             height: '24px',
                             top: '-12px',
                             right: '-12px',
                             zIndex: '1',
                             backgroundColor: '#151515',
                             borderRadius: '50%',
                             color: 'white',
                             textAlign: 'center',
                             cursor: 'pointer'
                           }}
                           onClick={removeSpreadsheet}>x</div>
                         <strong>Inventory spreadsheet</strong><br />
                         <small translate-values="{ productCount: $ctrl.data.form.spreadsheet.valueCount }" translate-interpolation="messageformat">
                           {products.length} {t('general.productCount', {count: products.length})}
                         </small>
                       </div>
                     </div>}
                    </FileSelector>
                </div>
              </li>
            </ul>
          </div>
          {!!spreadsheetError.length && <div className="section-content fade">
            <div className="parser-error-table">
              <div className="parser-error-table-body">
                <div className="row row-header">
                  <div className="col col-line">
                    {t('table.label.lineNo')}
                  </div>
                  <div className="col col-error">
                    {t('table.label.errorMessage')}
                  </div>
                </div>
                {spreadsheetError.map((productError: any) => (
                  <div className="row" key={productError.row}>
                  <div className="col col-line">
                    {productError.row}
                  </div>
                  <div className="col col-error">
                    {productError.message}
                  </div>
                </div>
                ))}
              </div>
            </div>
          </div>}

          {!!products?.length && <div className="section-content fade">
            <div className="offer-card">
              <div className="offer-card-body">
                <div className="row row-header">
                  <div className="col col-product">Products</div>
                  <div className="col col-qty">Qty</div>
                  <div className="col col-msrp">MSRP</div>
                  <div className="col col-wholesale">Wholesale</div>
                </div>
                {products.map((product: any) => (
                  <div className="row row-main" key={product.id}>
                    <div className="col col-product">
                      <small>
                        {product.sku}
                      </small>
                      <strong>
                        {product.name}
                      </strong>
                    </div>
                    <div className="cols">
                      <div className="row" style={{flex: 1}}>
                        <div className="col col-qty">
                          <strong>{product.inventory}</strong>
                        </div>
                        <div className="col col-msrp"><strong>
                          ${product.msrp}
                        </strong></div>
                        <div className="col col-wholesale"><strong>
                          ${product.wholesale}
                        </strong></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>}
        </div>
        <div className="column-right">
          <div>
            <div ng-if="!$ctrl.hasInitializeOffer" className="fade-section"
                 style={{
                   backgroundColor: 'rgba(175,131,57,0.3)',
                   borderRadius: '4px',
                   padding: '20px',
                   border: '1px solid rgba(175,131,57,0.1)',
                   marginBottom: '40px'
                 }}
            >
              <h3 style={{color: 'rgba(0, 0, 0, 0.8)'}}>Three simple steps:</h3>
              <ol
                style={{
                  paddingTop: '10px',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: 'rgba(0, 0, 0, 0.4)',
                  marginLeft: '15px'
                }}
              >
                <li style={{display: 'list-item', padding: '10px 0'}}>
                  <a href={templateUrl} target="_self">Download our
                    template;</a>
                </li>
                <li style={{display: 'list-item', padding: '10px 0'}}>Copy and paste your inventory;</li>
                <li style={{display: 'list-item', padding: '10px 0'}}>Upload it back to your account. Don't worry to
                  make a
                  mistake... our automated system will identify any incorrect information. Now you're ready to manage
                  your Husboro dashboard.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
