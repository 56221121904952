import * as React  from "react";
import { useCallback, useMemo } from "react";
import { useFormikContext, FieldProps, Field } from 'formik';

import TextField from "../../components/Textfield";

interface SocialMedia {
  name: '',
  url: ''
}

export default function SocialMediaForm({
    values
}: {
  values: {
    name: string;
    url: string;
  }[]
}) {
  const { setFieldValue } = useFormikContext();
  const properties: SocialMedia[] = useMemo(() => {
    return values as SocialMedia[]
  }, [values]);

  const addNewProperty = useCallback((e: any) => {
    e?.preventDefault();

    const newProperty: SocialMedia = {
      name: '',
      url: ''
    };

    const newProperties = [...properties, newProperty];

    setFieldValue('socialNetworks', newProperties);
  }, [properties]);

  const removeProperty = (propertyIndex: number) => {
    const newProperties = [...properties];

    newProperties.splice(propertyIndex, 1);

    setFieldValue('socialNetworks', newProperties);
  };

  return (
    <ul className="form-fieldset form-fieldset-warehouses" style={{padding: 0, margin: 0, border: 'none'}}>
      {properties && properties.map((socialMedia: any, index) => (
        <li key={index}>
          <div>
            <Field name={`socialNetworks[${index}].name`}>
              {({field}: FieldProps<any>) => (
                <TextField
                  id={field.name}
                  label="Name"
                  type="text"
                  {...field}
                />
              )}
            </Field>
          </div>
          <div>
            <Field name={`socialNetworks[${index}].url`}>
              {({field}: any) => (
                <TextField
                  id={field.url}
                  label="Url"
                  type="text"
                  {...field}
                />
              )}
            </Field>
          </div>
          <div style={{width: '30%', verticalAlign: 'middle'}}>
            <button
              className="button button-primary button-outline"
              onClick={() => removeProperty(index)}
              style={{padding: 0, border: 'none'}}
            >
              Remove
            </button>
          </div>
        </li>
      ))}
      <li>
        <div>
          <button
            className="button button-primary button-outline"
            onClick={addNewProperty}
            style={{padding: 0, border: 'none'}}
          >
            Add Item
          </button>
        </div>
      </li>
    </ul>
  );
}
