import * as React from "react";
import { useCallback, useMemo } from "react";
import { useFormikContext, Field } from 'formik';

import TextField from "../../../components/Textfield";
import AddImageIcon from "../../../assets/icons/picture.svg";
import LinkIcon from "../../../assets/icons/link.svg";
import DeleteLinkIcon from "../../../assets/icons/delete-outlined.svg";
import DeleteIcon from "../../../assets/icons/delete.svg";

export default function LinksImagesForm({ images, values, handleChangeImage, removeImage }: {
  images: any[];
  values: any;
  handleChangeImage: any;
  removeImage: any
}) {
  const { setFieldValue } = useFormikContext();
  const properties: string[] = useMemo(() => {
    return values
  }, [values]);
  const hiddenFileInput = React.useRef<any>(null);

  const addNewProperty = useCallback((e: any) => {
    e?.preventDefault();

    const newProperty = '';

    const newProperties = [...properties, newProperty];

    setFieldValue('links', newProperties);
  }, [properties]);

  const removeProperty = (propertyIndex: number) => {
    const newProperties = [...properties];

    newProperties.splice(propertyIndex, 1);

    setFieldValue('links', newProperties);
  };

  const handleUploadClick = (e: any) => {
    hiddenFileInput.current?.click();
  };

  return (
    <>
      {!!images.length && <div className="images-list">
        {images.map((image: any, index: number) =>
          <div key={image.preview} className="image-wrap">
            <img src={image.preview} alt="image"/>
            <button
              className="button button-primary button-outline"
              onClick={() => removeImage(index)}
            >
              <img src={DeleteIcon} alt="remove" />
            </button>
          </div>
        )}
      </div>}
      <ul className="form-fieldset form-fieldset-links">
        {properties && properties.map((socialMedia: any, index) => (
          <li key={index}>
            <div>
              <Field name={`links[${index}]`}>
                {({field}: any) => (
                  <TextField
                    id={field.url}
                    label="Url"
                    type="text"
                    {...field}
                    placeholder="https://..."
                  />
                )}
              </Field>
              <button
                className="button button-primary button-outline"
                onClick={() => removeProperty(index)}
              >
                <img src={DeleteLinkIcon} alt="remove" />
              </button>
            </div>
          </li>
        ))}
      </ul>
      <div className="action-buttons">
        <input
          accept="image/png, image/jpeg"
          id="icon-button-file"
          type="file"
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          onChange={handleChangeImage}
        />
        <button className="upload-image-button" onClick={handleUploadClick}>
          <img src={AddImageIcon} alt="add image" />
        </button>
        <button className="add-link-button" onClick={addNewProperty}>
          <img src={LinkIcon} alt="add image"/>
        </button>
      </div>
    </>
  );
}
