import * as React from "react";
import { Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Loader from "../../components/Loader";
import TextField from "../../components/Textfield";
import Header from "../../components/Header";
import ViewLoader from "../../components/ViewLoader";
import Footer from "../../components/../components/Footer";
import TextboxField from "../../components/TextboxField";
import { API_URL } from "../../constants";
import { parseApiError } from "../../utils";
import { useNotification } from "../../components/NotificationAlert/useNotification";

interface ContactFormValues {
  name: string;
  email: string;
  message: string;
}

export default function Contact() {
  const {t} = useTranslation();
  const notification = useNotification();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const initialValues = {
    name: '',
    email: '',
    message: '',
  };

  const onSubmit = (formValue: ContactFormValues, { resetForm }: FormikHelpers<ContactFormValues>) => {
    setIsSaving(true);

    axios.post(API_URL + '/contact', formValue)
      .then( function() {
        setIsSaving(false);

        notification.show({
          type: 'success',
          message: {
            translate: 'notification.contactSent'
          },
          autoDismiss: true
        });
        resetForm();
      })
      .catch((error) => {
        setIsSaving(false);
        parseApiError(error, notification, navigate);
      });
  };

  return (
    <div className="view-wrapper">
      <Header/>
      <ViewLoader/>

      <div className="view header-margin contact">
        <div className="section-inner">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .max(255, t('field.error.name.maxlength'))
                .required(t('field.error.name.required')),
              email: Yup.string()
                .email(t('field.error.email.pattern'))
                .required(t('field.error.email.required')),
            })}
          >
            {({values, errors, touched, handleSubmit, handleChange, handleBlur}) => (
              <form onSubmit={handleSubmit}>
                <section className="fade-content">
                  <div className="fade-content">
                    <div className="section-header">
                      <h1 className="dash dash-after dash-primary">
                        {t('contact.title')}
                      </h1>
                      <div>
                        {t('contact.text')}
                      </div>
                    </div>
                    <div className="section-content form" style={{maxWidth: '480px'}}>
                      <ul className="form-fieldset">
                        <li>
                          <div>
                            <TextField
                              id="name"
                              label={t('field.label.yourName')}
                              type="text"
                              error={errors.name}
                              value={values.name}
                              touched={touched.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div>
                            <TextField
                              id="email"
                              label={t('field.label.email')}
                              info={t('field.info.companyDomainName')}
                              type="text"
                              error={errors.email}
                              value={values.email}
                              touched={touched.email}
                              pattern="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div>
                            <TextboxField
                              id="message"
                              label="Message"
                              error={errors.message}
                              value={values.message}
                              touched={touched.message}
                              rows={3}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="section-action">
                      {isSaving && <Loader/>}
                      {!isSaving && <div>
                        <button type="submit" className="button button-success" onClick={() => handleSubmit()}>
                          {t('actions.send')}
                        </button>
                      </div>}
                    </div>
                  </div>
                </section>
              </form>
            )}
          </Formik>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
