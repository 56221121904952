import { ReactNode } from "react";

interface TextFieldProps {
  id: string;
  type: string;
  name?: string;
  min?: number;
  max?: number;
  label: string | ReactNode;
  error?: string;
  info?: string;
  placeholder?: string;
  disabled?: boolean;
  touched?: boolean;
  value: string;
  pattern?: string;
  onChange?: Function;
  onBlur?: Function;
  onFocus?: Function;
}

export default function TextField({
  id,
  type,
  name,
  min,
  max,
  label,
  error,
  info,
  placeholder,
  disabled,
  touched = false,
  value,
  pattern,
  onFocus = () => {},
  onChange = () => {},
  onBlur = () => {}
}: TextFieldProps) {
  return (
    <div className={`field textfield ${error && touched ? 'has-error' : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        id={id}
        type={type}
        name={name}
        min={min}
        max={max}
        placeholder={placeholder}
        pattern={pattern}
        value={value}
        onFocus={onFocus as any}
        onChange={onChange as any}
        onBlur={onBlur as any}
        disabled={disabled}
        tabIndex={0}
      />
      {info && !error && <small className="field-info">{info}</small>}
      {error && touched && <small className="field-error">{error}</small>}
    </div>
  );
}
