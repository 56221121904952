import { Field } from "formik";

interface CheckboxFieldProps {
  name?: string;
  value?: string;
  disabled?: boolean;
  onChange?: any;
}

export default function CheckboxField({ name, value, disabled, onChange = () => {} }: CheckboxFieldProps) {
  return (
    <div className="checkbox">
      <Field type="checkbox" name={name} disabled={disabled}>
        {({ field }: any) => (
          <input
            {...field}
            type="checkbox"
            checked={value || field.value}
            onChange={(e: any) => {
              field.onChange(field.name)(e);
              onChange(e);
            }}
          />
        )}
      </Field>
    </div>
  );
}
