import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageEditorCrop, { CropResult } from "../ImageEditorCrop";
import Slider from "../Slider";
import { round } from "./utils";

interface ImageEditorProps {
  data: {
    image: File;
    zoom: {
      values: {min: number; max: number;};
      limits: {min: number; max: number;};
    };
    upload: {
      height: number;
      imageType: string;
      mode: string;
      quality: number;
      width: number;
    }[];
    mode: {
      width: number;
      height: number;
    };
  };
  onCancel: () => void
  onSave: (data: CropResult, filename: string) => void
}

export default function ImageEditor({ data, onCancel, onSave }: ImageEditorProps) {
  const {t} = useTranslation();
  const [values, setValues] = useState<{min: number; max: number;}>({ min: 0, max: 0 });
  const [limits, setLimits] = useState<{min: number; max: number;}>({ min: 0, max: 0 });
  const editorCropRef = useRef<any>(null);

  useEffect(() => {
   if (data?.image) {
     let reader = new FileReader();

     reader.readAsDataURL(data?.image);
     reader.onload = function (e) {
       let image = new Image();

       image.src = e.target?.result as string;

       image.onload = function () {
         let height = image.height;
         let width = image.width;

         let ratio = {
             w: 180 / width,
             h: 180 / height
         };

         let min = ratio.w > ratio.h ? round('round', ratio.w, -2) : round('round', ratio.h, -2);

         setValues({
           ...data?.zoom?.values,
           min
         });
         setLimits({
           ...data?.zoom?.limits,
           min
         });
       };
     };
   }
  }, [data]);

  const onValueChange = (value: {min: number; max: number;}) => {
    setValues(value);
  };

  const save = () => {
    if (editorCropRef.current) {
      editorCropRef.current.saveCrop({
        upload: data.upload,
        callback: (res: CropResult) => {
          onSave(res, data?.image?.name);
        }
      });
    }
  };

  const close = () => {
    if(typeof onCancel === 'function') {
      onCancel && onCancel();
    }
  };

  return (
    // should split to fade overlay first then window with a delay
    <div className="image-editor fade">
      <div className="image-editor-window">
        <div className="image-editor-window-header">
          <strong>Edit your image</strong>
          <button className="icon" onClick={() => close()}>
            <span className="icon-modal-close"></span>
          </button>
        </div>
        <div className="image-editor-body" style={{ flex: 1 }}>
          <div className="image-editor-inner-body" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div>Drag to reposition your avatar. Use the slider to zoom it.</div>
            <div className="image-editor-area">
              {data?.image && <ImageEditorCrop
                ref={editorCropRef}
                image={data.image}
                zoom={values.max}
                mode={data.mode} />}
            </div>
            <div style={{
              padding: '8px',
              backgroundColor: '#f9f9f9',
              borderTop: '1px solid #eee',
              borderBottom: '1px solid #eee',
              display: 'flex',
              justifyContent: 'center'
            }}>
              <div style={{ margin: '8px', width: '100%', maxWidth: '480px' }}>
                <Slider
                  lock="min"
                  value={values}
                  limitValues={limits}
                  onValueChange={onValueChange}
                />
              </div>
            </div>
            <div style={{ padding: '16px', display: 'flex', justifyContent: 'center' }}>
              <button className="button button-success" style={{ margin: '8px' }} onClick={() => save()}>
                {t('actions.save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
