import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import ImagePreloader from "../../components/ImagePreloader";
import productPlaceholder from "../../assets/images/product-placeholder.jpeg";
import Loader from "../../components/Loader";
import { API_URL } from "../../constants";

export default function ProductsList({ currentSection }: { currentSection: string }) {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [products, setProducts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const getProducts = (nextPage: number) => {
    const apiUrl = currentSection === 'special' ? API_URL + '/products/special' : API_URL +  '/products/home';
    const category = params.get('category');

    setIsLoading(true);
    axios.get(apiUrl, {
        params: {
          page: nextPage,
          category_id: category
        }
      })
      .then((response) => {
        setProducts((state: any) => [...state, ...response.data.data]);
        setHasMore(nextPage < response.data.meta.last_page);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setProducts([]);
    setPage(1);
    getProducts(1);
  }, [params]);

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (!isLoading && hasMore && scrollTop + clientHeight >= scrollHeight) {
      setPage(page + 1);
      getProducts(page + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll)
  }, [page, isLoading, hasMore]);

  const selectProduct = (product: any) => {
    navigate(`/brand-view/${product.brand.id}/products/${product.id}`);
  };

  return (
    <>
      <div className="product-list-grid">
        {products.map((product: any) => (
          <div key={product.id}>
            <div className="product-card" onClick={() => selectProduct(product)}>
              <div className="product-header">
                <ImagePreloader
                  url={
                    product.images?.length ? product.images?.[0] : productPlaceholder
                  }
                />
              </div>
              <div className="product-body">
                <div className="product-name">
                  {product.name}
                </div>
                <small className="price">${product.msrp}</small>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isLoading || hasMore && <Loader className="full-width"/>}
    </>
  );
}
