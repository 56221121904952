import * as React from "react";
import { useEffect, useMemo, useCallback, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import ImageSelector, { FileError } from "../../components/ImageSelector";
import Loader from "../../components/Loader";
import { API_URL, configConstant } from "../../constants";
import ImageEditor from "../../components/ImageEditor";
import TextField from "../../components/Textfield";
import PhoneField from "../../components/PhoneField";
import { parseApiError } from "../../utils";
import { useNotification } from "../../components/NotificationAlert/useNotification";
import Select from "../../components/Select";

interface Avatar {
  avatar: {
    blob: Blob;
    url: string;
  };
  filename: string;
  previewUrl: string;
}

interface CategoryEntity {
  id: string;
  name: string;
}

interface CategoryOption {
  value: string;
  label: string;
  name: string;
}

interface Zoom {
  values: {
    min: number;
    max: number;
  },
  limits: {
    min: number;
    max: number;
    default: number;
    steps: number;
  }
}

interface Mode {
  width: number;
  height: number;
  zoom: {
    min: number;
    max: number;
    default: number;
  }
}

interface Upload {
  mode: string;
  width: number;
  height: number;
  imageType: string;
  quality: number;
}

interface ImageEditorOptions {
  image: File;
  zoom: Zoom;
  upload: Upload[],
  mode: Mode;
}

interface ProfileFormValues {
  name: string;
  title: string;
  phone_number: string;
  emergency_phone_number: string;
  linkedin: string;
  articleCategories: string[];
}

export default function ProfileForm({ onNext, userData }: { onNext: Function, userData: { name: string } }) {
  const {t} = useTranslation();
  const [search] = useSearchParams();
  const notification = useNotification();

  const [categories, setCategories] = useState<CategoryOption[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [avatar, setAvatar] = useState<Avatar | null>(null);
  const [avatarError, setAvatarError] = useState('');
  const [imageEditorOptions, setImageEditorOptions] = useState<ImageEditorOptions | null>(null);

  const code = search.get('code');

  useEffect(() => {
    axios.get(API_URL + '/news/categories')
      .then((response) => {
        const categories = (response.data?.data || []).map((c: CategoryEntity) => ({
          value: c.id,
          label: c.name,
          name: c.name
        }));

        setCategories(categories);
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeAvatar = useCallback((files: File[]) => {
    setIsOpen(true);
    setAvatarError('');
    setImageEditorOptions({
      image: files[0],
      zoom: configConstant.zoom.avatar,
      upload: configConstant.upload.avatar,
      mode: configConstant.mode.avatar
    });
  }, []);

  const onLoadAvatarError = useCallback((errors: FileError[]) => {
    const error = errors[0];
    setAvatarError(`${error.code} ${error.message}`);
  }, []);

  const removeAvatar = () => {
    setAvatar(null);
  };

  const onCloseImageEditor = () => {
    setIsOpen(false);
    setImageEditorOptions(null);
  };

  const onSaveImage = (result: any, filename: string) => {
    setAvatar({
      ...result,
      previewUrl: result.avatar.url,
      filename
    });

    setIsOpen(false);
    setImageEditorOptions(null);
  };

  const onSubmit = (formValue: ProfileFormValues, { setErrors }: FormikHelpers<ProfileFormValues>) => {
    let data = {
      code: code,
      name: formValue.name,
      title: formValue.title,
      phone_number: formValue.phone_number,
      emergency_phone_number: formValue.emergency_phone_number,
      linkedin: formValue.linkedin,
      article_categories: formValue.articleCategories
    };

    setIsSaving(true);

    axios.post(API_URL + '/accounts', data)
      .then(function () {
        if (!avatar?.avatar) {
          setIsSaving(false);
          onNext && onNext();
          return;
        }

        const data = new FormData();

        data.append('code', code || '');
        data.append('avatar', avatar?.avatar?.blob, avatar?.filename);

        axios.post(API_URL + '/accounts/avatar', data, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            setIsSaving(false);
            onNext && onNext();
          })
          .catch((error) => {
            parseApiError(error, notification, null, setErrors);
            setIsSaving(false);
          });
      })
      .catch((error) => {
        if (error.response.data.errors.code) {
          setIsSaving(false);
          onNext && onNext();
          return;
        }

        parseApiError(error, notification, null, setErrors);
        setIsSaving(false);
      });
  };

  const avatarSelectorOptions = useMemo(() => configConstant.selectorOptions.avatar, []);

  const initialValues: ProfileFormValues = useMemo(() => ({
    name: userData.name,
    title: '',
    linkedin: '',
    phone_number: '',
    emergency_phone_number: '',
    articleCategories: [],
  }) ,[userData]);

  return (
    <>
      {isOpen && imageEditorOptions &&
        <ImageEditor data={imageEditorOptions} onSave={onSaveImage} onCancel={onCloseImageEditor}/>
      }
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255, t('field.error.name.maxlength'))
            .required(t('field.error.name.required')),
          title: Yup.string()
            .max(255, t('field.error.title.maxlength')),
          phone_number: Yup.string()
            .required(t('field.error.phone.required')),
          emergency_phone_number: Yup.string()
            .required(t('field.error.emergencyPhone.required')),
          linkedin: Yup.string()
            .max(255, t('field.error.linkedIn.maxlength'))
        })}
      >
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="section-header">
              <h1>
                {t('activation.profile.header.title')}
              </h1>
              <div>
                {t('activation.profile.header.text')}
              </div>
            </div>
            <div className="section-content form">
              <ul className="form-fieldset">
                <li>
                  <div>
                    <ImageSelector
                      label={<Trans components={{span: <span></span>}} i18nKey={'field.label.avatarOptional'} />}
                      info={t('field.info.avatar')}
                      error={avatarError}
                      options={avatarSelectorOptions}
                      onChange={onChangeAvatar}
                      onError={onLoadAvatarError}
                    >
                      {avatar?.previewUrl && <div style={{ paddingTop: '40px' }}>
                        <div style={{ margin: '0 auto', width: '120px', position: 'relative' }}>
                          <div
                            style={{
                              position: 'absolute',
                              width: '24px',
                              height: '24px',
                              top: '-12px',
                              right: '-12px',
                              zIndex: 1,
                              backgroundColor: '#151515',
                              borderRadius: '50%',
                              color: 'white',
                              textAlign: 'center',
                              cursor: 'pointer'
                            }}
                            onClick={() => removeAvatar()}>x
                          </div>
                          <img
                            style={{ display: 'block', width: '120px', height: 'auto' }}
                            src={avatar.previewUrl}
                            alt="preview"
                          />
                        </div>
                      </div>}
                    </ImageSelector>
                  </div>
                  <div></div>
                </li>
                <li>
                  <div>
                    <TextField
                      id="name"
                      label={t('field.label.name')}
                      type="text"
                      error={errors.name}
                      value={values.name}
                      touched={touched.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div>
                    <TextField
                      id="title"
                      label={<Trans components={{span: <span></span>}} i18nKey={'field.label.titleOptional'} />}
                      type="text"
                      error={errors.title}
                      value={values.title}
                      touched={touched.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                </li>
                <li>
                  <div>
                    <PhoneField
                      id="phone_number"
                      type="tel"
                      label={t('field.label.phone')}
                      error={errors.phone_number}
                      value={values.phone_number}
                      touched={touched.phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div>
                    <PhoneField
                      id="emergency_phone_number"
                      type="tel"
                      label={t('field.label.emergencyPhone')}
                      error={errors.emergency_phone_number}
                      value={values.emergency_phone_number}
                      touched={touched.emergency_phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <TextField
                      id="linkedin"
                      label={<Trans components={{span: <span></span>}} i18nKey={'field.label.linkedInOptional'} />}
                      type="text"
                      error={errors.linkedin}
                      value={values.linkedin}
                      touched={touched.linkedin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div>
                    <Select
                      id="articleCategories"
                      name="articleCategories"
                      label={t('field.label.articleCategories')}
                      error={errors.articleCategories as string}
                      value={values.articleCategories}
                      touched={touched.articleCategories}
                      options={categories}
                      multiple
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div className="section-action">
              {isSaving && <Loader/>}
              {!isSaving && <div>
                <button type="submit" className="button button-success" onClick={() => handleSubmit()}>
                  {t('button.label.nextStep')}
                </button>
              </div>}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
