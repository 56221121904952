import * as React from "react";
import { Outlet } from "react-router-dom";

export default function OffersPage() {

  return (
    <div className="section-inner">
      <Outlet/>
    </div>
  );
}
