import CreateArticleForm from "./CreateArticleForm";
import * as React from "react";
import axios from "axios";
import moment from 'moment';
import { useState } from "react";
import { useEffect } from "react";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import { API_FILES_URL, API_URL } from "../../../constants";
import api from "../../../api";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import Avatar from "../../../components/Avatar";
import Loader from "../../../components/Loader";
import { parseApiError } from "../../../utils";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import DeleteIcon from "../../../assets/icons/delete.svg";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default function Articles({ isUserConnected, user, userCompany }: {
  user: any;
  userCompany: any;
  isUserConnected: boolean
}) {
  const {t} = useTranslation();
  const notification = useNotification();

  const [articles, setArticles] = useState<any>([]);
  const [currentCategory, setCurrentCategory] = useState<any>(null);
  const [categories, setCategories] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [editArticle, setEditArticle] = useState(null);

  const getArticles = (nextPage: number) => {
    setIsLoading(true);

    axios.get(API_URL + '/news', {
        params: {
          page: nextPage,
          category_id: !currentCategory || currentCategory === 'all' ? undefined : currentCategory
        }
      })
      .then((response: any) => {
        setArticles(
          (state: any) => [
            ...state,
            ...response.data.data
              .map((a: any) => ({
                ...a,
                createdAt: moment(a.created_at).format('YYYY-MM-DD hh:mm A')
              }))
          ]);
        setHasMore(page !== response.data.meta.last_page);
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    axios.get(API_URL + '/news/categories')
      .then((response: any) => {
        const categories = (response.data?.data || []).map((c: any) => ({
          id: c.id,
          value: c.id,
          label: c.name,
          name: c.name
        }));

        setCategories([
          {
            id: '',
            value: 'all',
            label: 'All',
            name: 'All'
          },
          ...categories
        ]);
      })
      .catch((error: any) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getArticles(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentCategory]);

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (!isLoading && hasMore && scrollTop + clientHeight >= scrollHeight) {
      setPage(page + 1)
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll)
  }, [page, currentCategory, isLoading, hasMore]);

  const selectCategory = (category: any) => {
    setCurrentCategory(category.value);
    setArticles([]);
    setPage(1)

    // if (category.label === 'all') {
    //   searchParams.delete('category');
    // } else {
    //   searchParams.set('category', category.id);
    // }
    //
    // setSearchParams(searchParams);
  };

  const saveImages = useCallback((item: any, images: any[]) => {
    const data = new FormData();

    images.map((image: any) => {
      data.append('images[]', image?.raw, image?.raw?.filename);
    });

    api.post(API_URL + `/news/${item.id}/images`, data, {headers: {"Content-Type": "multipart/form-data"}})
      .then((res: any) => {
        setArticles((state: any) =>
          state.map((i: any) => (i.id === item.id ?
            {
              ...res.data.data,
              createdAt: moment(res.data.data.created_at).format('YYYY-MM-DD hh:mm A')
            } : i)
          ));
      })
      .catch((error) => {
        console.error(error)
      });
  }, []);

  const removeImages = useCallback((item: any, images: any[]) => {
    const data = new FormData();

    images.map((image: string) => {
      data.append('clear_images[]', image);
    });

    return api.post(API_URL + `/news/${item.id}/images`, data, {headers: {"Content-Type": "multipart/form-data"}})
      .catch((error) => {
        console.error(error)
      });
  }, []);

  const updateArticle = async (editArticle: any, data: any, images: any, resetForm: any) => {
    const imagesMap = images?.map((i: any) => i.preview?.split(API_FILES_URL)[1]) || [];
    const imagesToRemove = editArticle.images?.filter((i: string) => !imagesMap.includes(i)) || [];

    if (imagesToRemove.length) {
      await removeImages(editArticle, imagesToRemove);
    }

    api.put(API_URL + '/news/' + editArticle.id, data)
      .then(function (res: any) {
        const imagesToSave = images.filter((img: any) => !!img.raw);

        if (imagesToSave.length) {
          saveImages(res.data.data, imagesToSave);
        } else {
          setArticles((state: any) => state.map((a: any) => {
              if (a.id === editArticle.id) {
                return {
                  ...res.data.data,
                  createdAt: moment(res.data.data.created_at).format('YYYY-MM-DD hh:mm A')
                }
              } else {
                return a;
              }
            })
          );
        }
        setEditArticle(null);
        resetForm();
      })
      .catch((error: any) => {
        parseApiError(error, notification, null);
        if (editArticle) {
          setEditArticle(null);
          resetForm();
        }
      });
  };

  const onSubmit = (values: any, images: any[], resetForm: any) => {
    const data = {
      "text": values.message,
      "links": values.links.filter((link: string) => !!link),
      "categories": values.articleCategories
    };

    if (editArticle) {
      return updateArticle(editArticle, data, images, resetForm)
    }

    api.post(API_URL + '/news', data)
      .then(function (res: any) {
        if (images.length) {
          saveImages(res.data.data, images);
        }

        setArticles((state: any) => [
          {
            ...res.data.data,
            createdAt: moment(res.data.data.created_at).format('YYYY-MM-DD hh:mm A')
          },
          ...state
        ]);

        resetForm();
      })
      .catch((error: any) => {
        parseApiError(error, notification, null);
      });
  };

  const confirmDelete = () => {
    return new Promise(resolve => {
      const options = {
        title: 'Remove article',
        message: 'Are you sure?',
        closeOnEscape: true,
        closeOnClickOutside: true,
        customUI: ({ title, message, onClose }: any) => {
          return (
            <div className="modal modal-review-offer" style={{overflow: "scroll"}}>
              <div className="modal-window" style={{ width: "50%" }}>
                <div className="modal-window-header">
                  <strong>
                    {title}
                  </strong>
                  <a className="icon" onClick={onClose}>
                    <i className="icon-modal-close"/>
                  </a>
                </div>
                <div>
                  <div>
                    <div className="modal-window-body" style={{padding: "30px 15px"}}>
                      <span>
                        {message}
                      </span>
                    </div>
                    <div className="modal-window-footer">
                      <button type="button" className="button" onClick={onClose}  style={{ marginRight: "16px"}}>
                        <span>
                          {t('actions.cancel')}
                        </span>
                      </button>
                      <button type="button" className="button button-primary" onClick={() => {
                        onClose();
                        resolve(true);
                      }}>
                        <span>
                           Remove
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      };

      confirmAlert(options);
    });
  };

  const removeArticle = async (id: number) => {
    const confirmed = await confirmDelete();

    if (!confirmed) {
      return;
    }

    api.delete(API_URL + '/news/' + id)
      .then(function () {
        setArticles((state: any) => state.filter((a: any) => a.id !== id));

        if (editArticle) {
          setEditArticle(null);
        }
      })
      .catch((error: any) => {
        parseApiError(error, notification, null);
      });
  };

  const onEditArticle = (article: any) => {
    setEditArticle(article);
  };

  return (
    <>
      {isUserConnected && <CreateArticleForm
        user={user}
        categories={categories}
        editArticle={editArticle}
        onSubmit={onSubmit}
      />}

      {!isUserConnected && <div className="unauthorized-content">
        <Link className="button gold" to="/login">
          {t('actions.signUpToDiscoverMoreBrands')}
        </Link>
      </div>}

      <div className="articles-filters">
        <hr />
        <div className="articles-filters-dropdown">
          Filter by category:
          <Dropdown
            options={categories}
            onChange={selectCategory}
            placeholder="All"
            className='dropdown'
          />
        </div>
      </div>

      <div>
        {articles.map((article: any) => (
          <div key={'article-' + article.id} className="article-card">
            <div className="article-card-content">
              {user?.profile?.isAdmin && userCompany?.id === article?.company?.id && <button
                className="button button-primary button-outline edit-btn"
                onClick={() => onEditArticle(article)}
              >
                edit
              </button>}
              {user?.profile?.isAdmin && userCompany?.id === article?.company?.id && <button
                className="button button-primary button-outline"
                onClick={() => removeArticle(article.id)}
              >
                <img src={DeleteIcon} alt="remove" />
              </button>}
              <div className="article-header">
                <Avatar className="avatar-small" model={{
                  name: article.company.name,
                  image: article.company.logo
                }} />
                <div className="article-header-info">
                  <span>{article.company.name}</span>
                  <small>{article.createdAt}</small>
                </div>
              </div>
              <p>
                {article.text}
              </p>
              {article.links.map((link: string, index: number) => (
                <p key={link + index}>
                  <a href={link} target="_blank">{link}</a>
                </p>
              ))}
              <div className="article-images">
                {article?.images && article?.images.map((image: any) =>
                  <div key={image}>
                    <img src={API_FILES_URL + image} alt="image" />
                  </div>
                )}
              </div>
              <div className="article-footer">
              </div>
            </div>
          </div>
        ))}

        {isLoading || hasMore && <Loader className="full-width"/>}
      </div>
    </>
  );
}
