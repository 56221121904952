import * as React from "react";
import { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { API_URL } from "../../constants";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import Header from "../../components/../components/Header";
import ViewLoader from "../../components/ViewLoader";
import TextField from "../../components/Textfield";
import { parseApiError } from "../../utils";
import { useNotification } from "../../components/NotificationAlert/useNotification";

const passwordPattern = /^(?=.*\d)[0-9a-zA-Z]{8,}$/;

interface ResetPasswordFormValues {
  newPassword: string;
  confirmNewPassword: string;
}

export default function ResetPassword() {
  const {t} = useTranslation();
  const notification = useNotification();
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const [currentStatus, setCurrentStatus] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const initialValues: ResetPasswordFormValues = {
    newPassword: '',
    confirmNewPassword: '',
  };
  const code = search.get('code');

  const onSubmit = async (formValue: ResetPasswordFormValues, { setErrors }: FormikHelpers<ResetPasswordFormValues>) => {
    let data = {
      password: formValue.newPassword,
      password_confirmation: formValue.confirmNewPassword,
      code: code
    };

    setIsSaving(true);

    axios.post(API_URL + '/password/reset', data)
      .then( () => {
        setIsSaving(false);
        setCurrentStatus('sent');
      })
      .catch((res) => {
        parseApiError(res, notification, navigate, setErrors);
        setIsSaving(false);
      });
  };

  return (
    <div className="view-wrapper">
      <Header/>

      <ViewLoader/>

      <div className="view view-content header-margin identification">
        <div className="section-inner">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              newPassword: Yup.string()
                .matches(passwordPattern, t('field.error.password.pattern'))
                .required(t('field.error.password.required')),
              confirmNewPassword: Yup.string()
                .test('passwords-match', t('field.error.confirmPassword.match'), function (value) {
                  return this.parent.newPassword === value
                })
                .required(t('field.error.confirmPassword.required')),
            })}
          >
            {({values, errors, touched, handleSubmit, handleChange, handleBlur}) => (
              <form onSubmit={handleSubmit}>
                <section className="reset fade-content">
                  {!currentStatus && <div className="fade-content">
                    <div className="section-header">
                      <h1 className="dash dash-after dash-primary">
                        {t('identification.reset.default.header.title')}
                      </h1>
                      <div>
                        <Trans i18nKey={'identification.reset.default.header.text'} />
                      </div>
                    </div>
                    <div className="section-content form">
                      <ul className="form-fieldset">
                        <li>
                          <div>
                            <TextField
                              id="newPassword"
                              label={t('field.label.password')}
                              type="password"
                              error={errors.newPassword}
                              value={values.newPassword}
                              touched={touched.newPassword}
                              pattern="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div>
                            <TextField
                              id="confirmNewPassword"
                              label={t('field.label.confirmNewPassword')}
                              type="password"
                              error={errors.confirmNewPassword}
                              value={values.confirmNewPassword}
                              touched={touched.confirmNewPassword}
                              pattern="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="section-action">
                      {isSaving && <Loader/>}
                      {!isSaving && <div>
                        <button type="submit"
                                className="button button-success"
                                onClick={() => handleSubmit()}
                        >
                          {t('actions.resetMyPassword')}
                        </button>
                      </div>}
                    </div>
                  </div>}
                  {currentStatus === 'sent' && <div className="fade-content">
                    <div className="section-header">
                      <h1 className="dash dash-after dash-primary">
                        {t('identification.reset.sent.header.title')}
                      </h1>
                      <div>
                        <Trans i18nKey={'identification.reset.sent.header.text'} />
                      </div>
                    </div>
                  </div>}
                </section>
              </form>
            )}
          </Formik>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
