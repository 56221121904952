import { default as React } from "react";
import { Outlet, useMatch } from "react-router-dom";
import { useSelector } from "react-redux";

import TabBar from "../../../components/TabBar";

export default function Company() {
  const match = useMatch('/account/company/:lastPart/*');
  const currentSection = match?.params.lastPart || 'profile';
  const user = useSelector((state: any) => state.user);
  const userType = user?.profile?.type?.id;
  const isBuyer = userType === 2;

  const tabs = [
    {
      section: 'profile',
      translate: 'account.company.tabs.profile',
      url: '/account/company'
    },
    {
      section: 'members',
      translate: 'account.company.tabs.members',
      url: '/account/company/members'
    },
    ...(!isBuyer ? [{
      section: 'warehouses',
      translate: 'account.company.tabs.warehouses',
      url: '/account/company/warehouses'
    }] : [])
  ];

  return (
    <>
      <TabBar tabs={tabs} active={currentSection}/>
      <div className="section-inner">
        <Outlet />
      </div>
    </>
  );
}
