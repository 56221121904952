import * as React from "react";
import { useEffect, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axios from "axios";

import api from "../../api";
import Header from "../../components/Header";
import Footer from "../../components/../components/Footer";
import ViewLoader from "../../components/ViewLoader";
import Loader from "../../components/Loader";
import TextField from "../../components/Textfield";
import { parseApiError, STATUS_NOT_SUBSCRIBED } from "../../utils";
import { useNotification } from "../../components/NotificationAlert/useNotification";
import { API_URL, SUBSCRIPTION_API_URL } from "../../constants";
import { setCurrentUser, setUserLoggedIn } from "../../resucers/user/userSlice";

interface LoginFormValues {
  email: string;
  password: string;
}

type LocationProps = {
  state: {
    from: Location;
    status?: string;
    notification?: string;
  };
};

const SUBSCRIPRION_COST = 599;

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation() as unknown as LocationProps;
  const notification = useNotification();
  const dispatch = useDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');
  const [showSubscriptionPlan, setShowSubscriptionPlan] = useState(false);
  const [userId, setUserId] = useState('');

  const initialValues = {
    email: '',
    password: ''
  };
  const isFirstTime = location?.state?.notification === 'welcome';

  const onSubmit = (formValue: LoginFormValues, { setErrors }: FormikHelpers<LoginFormValues>) => {
    setIsSaving(true);

    axios.post(API_URL + '/auth/login', formValue)
      .then( function(response) {
        setIsSaving(false);

        dispatch(setUserLoggedIn(response.data));

        api.get(API_URL + '/users/me')
          .then(function (response) {
            dispatch(setCurrentUser(response.data));
            navigate('/account/dashboard');
          })
          .catch((error) => {
            parseApiError(error, notification, navigate);
          });
      })
      .catch((res) => {
        const response = res?.response?.data;
        const code = response?.code;

        if (response && code && code === STATUS_NOT_SUBSCRIBED) {
          setShowSubscriptionPlan(true);
          setIsSaving(false);
          setUserId(response.user_id);
          return;
        }

        parseApiError(res, notification, navigate, setErrors);
        setIsSaving(false);
      });
  };

  useEffect(() => {
    setCurrentStatus(location?.state?.status || '');
  }, [location?.state?.status]);

  return (
    <div className="view-wrapper">
      <Header/>

      <ViewLoader/>

      <div className="view view-content header-margin identification">
        <div className="section-inner">
          {!showSubscriptionPlan && <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .required(t('field.error.email.required')),
              password: Yup.string()
                .required(t('field.error.password.required')),
            })}
          >
            {({values, errors, touched, handleSubmit, handleChange, handleBlur}) => (
              <form onSubmit={handleSubmit}>
                <section className="login fade-content">
                  {!currentStatus && <div className="fade-content">
                    {isFirstTime && <div className="section-header">
                      <h1 className="dash dash-after dash-primary">You're all set!</h1>
                      <div>
                        <p>You're now ready to safely connect to your Husboro account.</p>
                      </div>
                    </div>}
                    {!isFirstTime && <div className="section-header">
                      <h1 className="dash dash-after dash-primary">
                        {t('identification.login.default.header.title')}
                      </h1>
                      <div>
                        <p>
                          <span>
                            {t('identification.login.default.header.text')}&nbsp;
                          </span>
                          <Link to="/signup">
                            {t('actions.signupFree')}
                          </Link>
                        </p>
                      </div>
                    </div>}
                    <div className="section-content form">
                      <ul className="form-fieldset">
                        <li>
                          <div>
                            <TextField
                              id="email"
                              label={t('field.label.email')}
                              type="text"
                              error={errors.email}
                              value={values.email}
                              touched={touched.email}
                              pattern="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div>
                            <TextField
                              id="password"
                              label={t('field.label.password')}
                              type="password"
                              error={errors.password}
                              value={values.password}
                              touched={touched.password}
                              pattern="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="align-right">
                              <Link className="txt-grey-lighter" to="/forgot-password">
                                <small>
                                  {t('actions.forgotPassword')}
                                </small>
                              </Link>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="section-action">
                      {isSaving && <Loader />}
                      {!isSaving &&<div>
                        <button type="submit"
                                className="button button-success"
                                onClick={() =>  handleSubmit()}
                        >
                          {t('actions.login')}
                        </button>
                      </div>}
                    </div>
                    <div className="section-content">
                      <p>
                        <span>
                          {t('identification.login.default.resend')}&nbsp;
                        </span>
                        <Link to="/resend-activation">
                          {t('actions.resendItNow')}
                        </Link>
                      </p>
                    </div>
                  </div>}
                  {currentStatus === 'pending' && <div className="fade-content">
                    <div className="section-header">
                      <h1 className="dash dash-after dash-primary">
                        {t('identification.login.pending.header.title')}
                      </h1>
                      <div>
                        <Trans i18nKey={'identification.login.pending.header.text'} />
                      </div>
                    </div>
                  </div>}
                  {currentStatus === 'approved' && <div className="fade-content">
                    <div className="section-header">
                      <h1 className="dash dash-after dash-primary">
                        {t('identification.login.approved.header.title')}
                      </h1>
                      <div>
                        <Trans i18nKey={'identification.login.approved.header.text'} values={{ email: values.email }} />
                      </div>
                      <p>
                        <span>
                           {t('identification.login.approved.resend')}&nbsp;
                        </span>
                        <Link to="/resend-activation">
                          {t('actions.resendItNow')}
                        </Link>
                      </p>
                    </div>
                  </div>}
                  {currentStatus === 'disabled' && <div className="fade-content">
                    <div className="section-header">
                      <h1 className="dash dash-after dash-primary">
                        {t('identification.login.disabled.header.title')}
                      </h1>
                      <div>
                        <Trans i18nKey={'identification.login.disabled.header.text'} />
                      </div>
                    </div>
                  </div>}
                </section>
              </form>
            )}
          </Formik>}


          {showSubscriptionPlan && <section className="signup fade-content">
            <div className="section-content form">
              <div className="form-fieldset">
                <div className="sr-root">
                  <div className="sr-main">
                    <h1>Collaboration plan</h1>
                    <div className="price-table-container">
                      <section>
                        <form action={`${SUBSCRIPTION_API_URL}/subscription/${userId}/checkout`} method="GET">
                          <div className="name">7 days Trial </div>
                          <div className="price">${SUBSCRIPRION_COST}</div>
                          <div className="duration">per year</div>
                          <button id="basic-plan-btn" className="button button-success">Checkout</button>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>}
        </div>
      </div>

      <Footer/>
    </div>
  );
}
