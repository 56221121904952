import { Trans } from "react-i18next";
import { default as React, useEffect } from "react";

const timeToClose = 3000;

export default function NotificationAlert({ isOpen, onClose, notification }: any) {
  const { autoDismiss, currentMessage, currentBg } = notification || {};

  useEffect(() => {
    if (autoDismiss) {
      const timeoutId = setTimeout(() => onClose(), timeToClose);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [autoDismiss]);

  // if(data.scrollTop === true) {
  //   $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
  // }

  return (
    <div className={`notification ${currentBg} ${isOpen ? 'show' : ''}`}>
      {currentMessage && <div>
        <span>
          <Trans i18nKey={currentMessage.translate} values={currentMessage.translateValues} />
        </span>
        {currentMessage.action && <span>
          <Trans i18nKey={currentMessage.translate.action} />
        </span>}
      </div>}
      <a className="notification-close" onClick={onClose}></a>
    </div>
  );
}
