import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import api from "../../../api";
import { API_URL } from "../../../constants";
import Avatar from "../../../components/Avatar";
import { parseApiError } from "../../../utils";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import Loader from "../../../components/Loader";

export default function MessagesPage() {
  const navigate = useNavigate();
  const notification = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<any[]>([]);

  useEffect(() => {
    setIsLoading(true);
    api.get(API_URL + '/messages')
      .then(function (res) {
        const data = res.data.data;
        setMessages(
          data
            .sort((a: any, b: any) => a.createdAt - b.createdAt ? 1 : -1)
            .map((m: any) => ({
              ...m,
              createdAt: moment(m.createdAt).format('YYYY-MM-DD hh:mm A')
            }))
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        parseApiError(error, notification, null);
      });
  }, []);

  const viewMessage = (offerId: string) => {
    navigate(`/account/offers/${offerId}/message`)
  };

  return (
    <div className="section-inner">
      <div>
        <section className="fade-content">
          <div className="section-header dash dash-after dash-primary">
            <h1 style={{paddingBottom: '0', color: '#444', textTransform: 'uppercase'}}>Messages</h1>
          </div>
          <div className="section-content">
            {isLoading && <Loader/>}
            {!messages.length && !isLoading && <div>
              <p style={{fontSize: '18px', lineHeight: '27px'}}>You don't have any messages yet!</p>
            </div>}
            {!!messages.length && !isLoading && <div>
              {messages.map((message: any) => (
                <div className="message-item" key={message.id} onClick={() => viewMessage(message.messageableId)}>
                  <div className="item-avatar">
                    {message.isUnread && <div className="unread-indicator"></div>}
                    <Avatar model={{
                      ...message.sender,
                      image: message.sender.avatar
                    }}/>
                  </div>
                  <div className="item-body">
                    <strong>
                      {message.sender.name}
                    </strong>
                    <p>{message.shortText}</p>
                  </div>
                  <div>
                    <span style={{color: '#aaa'}}>
                      {message.createdAt}
                    </span>
                  </div>
                </div>
              ))}
            </div>}
          </div>
        </section>
      </div>
    </div>
  );
}
