import { default as React, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import api from "../../../api";
import { API_URL } from "../../../constants";
import { parseApiError } from "../../../utils";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import Loader from "../../../components/Loader";

interface Brand {
  id: string;
  name: string;
}

export default function BrandsList() {
  const {t} = useTranslation();
  const notification = useNotification();

  const [brands, setBrands] = useState<Brand[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api.get(API_URL + '/companies/my')
      .then((response) => {
        setBrands(response.data.data.brands);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        parseApiError(error, notification, null);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const brandsCount = brands.length;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <section className="section-inner fade-content">
      <div className="section-header dash dash-after dash-primary">
        <h1>
          {t("account.brand.brands.header.title")}
        </h1>
        {!brandsCount &&
        <div>
          <Trans i18nKey={"account.brand.brands.empty.text"}/>
        </div>
        }
        {!!brandsCount &&
        <div>
          <Trans i18nKey={"account.brand.brands.header.text"}/>
        </div>
        }
      </div>
      {!brandsCount &&
      <div className="section-content">
        <Link to="new/profile" className="button button-primary">
          {t('actions.addItem', {context: 'brand'})}
        </Link>
      </div>
      }
      {!!brandsCount &&
      <div className="section-content brand-grid">
        <div>
          <Link to="new/profile" className="brand-card brand-new-card">
            <span className="icon-64-plus"></span>
            <strong>
              {t('actions.addItem', {context: 'brand'})}
            </strong>
          </Link>
        </div>
        {brands.map(brand => (
          <div key={brand.id}>
            <div className="brand-card">
              <div className="brand-card-body">
                <strong>
                  {brand.name}
                </strong>
              </div>
              <div className="brand-card-footer">
                <Link to={`${brand.id}/profile`} className="icon">
                  <span className="icon-24-edit"></span>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      }
    </section>
  );
}
