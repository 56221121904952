import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

interface Step { translate: string; status: string; }

export default function StepBar({ steps = {}, active }: { steps: {
  [key: string]: Step };
  active: string;
}) {
  const {t} = useTranslation();
  const [stepsList, setStepsList] = useState<Step[]>([]);

  useEffect(() => {
    let status = 'done';
    const list = [] as Step[];

    for(let key in steps) {
      if(key === active) {
        list.push({
          ...steps[key],
          status: 'active'
        });

        status = 'pending';
      } else {
        list.push({
          ...steps[key],
          status: status
        });
      }
    }

    setStepsList(list);
  }, [steps, active]);

  return (
    <div className="step-bar">
      {stepsList.map((step) => (
        <div key={step.translate} className={`${step.status === 'done' ? 'done' : ''} ${step.status === 'active' ? 'active': ''}`}>
          <strong>
            {t(step.translate)}
          </strong>
        </div>
      ))}
    </div>
  );
}
