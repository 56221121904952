import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ScrollReveal from 'scrollreveal';

import ViewLoader from "../../components/ViewLoader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { API_URL } from "../../constants";

import './index.css';
import api from "../../api";
import SuggestedProducts from "./SuggestedProducts";
import SpecialProducts from "./SpecialProducts";
import SuggestedBrands from "./SuggestedBrands";
import { parseApiError } from "../../utils";
import { useNotification } from "../../components/NotificationAlert/useNotification";
import Articles from "./Articles";
import BrandProfile from "./BrandProfile";

const anim = {
  easing: {
    easeOutQuad: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
    easeInOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1)'
  },
  mobile: true,
  reset: false
};

export default function Home() {
  const notification = useNotification();

  const [isPreviewFinished, setPreviewFinished] = useState(true);
  const [userBrands, setUserBrands] = useState<any>(null);
  const [userCompany, setUserCompany] = useState<any>(null);

  // @ts-ignore
  const user = useSelector((state) => state.user);
  const isUserConnected = user?.isConnected;
  const userType = user?.profile?.type?.id;
  const isBrand = userType === 1;

  const onPreviewFinished = () => {
    setPreviewFinished(true);
    ScrollReveal({reset: true});
    setTimeout(() => {
      ScrollReveal().reveal('.home h1, .home h2', {
        origin: 'bottom',
        distance: '10px',
        duration: 600,
        opacity: 1,
        scale: 1,
        easing: anim.easing.easeInOutCubic,
        mobile: anim.mobile,
        reset: anim.reset,
        useDelay: 'once'
      });

      ScrollReveal().reveal('.home-brands a.button', {
        origin: 'bottom',
        distance: '10px',
        duration: 600,
        delay: 300,
        scale: 1,
        easing: anim.easing.easeOutQuad,
        mobile: anim.mobile,
        reset: anim.reset,
        useDelay: 'once'
      });
    });
  };

  useEffect(() => {
    if (isUserConnected) {
      api.get(API_URL + '/companies/my')
        .then((response) => {
          const data = response.data.data?.brands;
          setUserBrands(data);
          setUserCompany(response.data.data);
        })
        .catch((error) => {
          parseApiError(error, notification, null);
        });
    } else {
      setUserBrands(null);
      setUserCompany(null);
    }
  }, [isUserConnected]);

  return (
    <div className="view-wrapper">
      <Header/>

      <ViewLoader onFinish={onPreviewFinished}/>

      <div className={`view header-margin home ${isPreviewFinished ? 'reveal' : ''}`}>
        <div className="view-inner">
          <h3>News</h3>
          <section className="home-page">
            <div className="column column-left">
              {userCompany &&
                <BrandProfile userBrands={userBrands} userCompany={userCompany} />
              }
              <SpecialProducts isUserConnected={isUserConnected} />
            </div>

            <div className="column column-middle">
              <Articles
                user={user}
                userCompany={userCompany}
                isUserConnected={isUserConnected}
              />
            </div>

            <div className="column column-right">
              <SuggestedBrands isBrand={isBrand} />
              <SuggestedProducts isBrand={isBrand} />
            </div>
          </section>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
