import { default as React, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { API_URL } from "../../../constants";
import Loader from "../../../components/Loader";
import api from "../../../api";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import TextField from "../../../components/Textfield";
import AddressForm from "../../Activation/AddressForm";
import { parseApiError } from "../../../utils";

interface WarehouseFormValues {
  name: string;
  address: {
    address: string;
    country: string;
    province?: string;
    zipCode: string;
    postalCode?: string;
    city: string;
  };
}

export default function AddWarehouse() {
  const {t} = useTranslation();
  const notification = useNotification();
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);
  const [countries, setCountries] = useState([]);
  const [initialValues] = useState<WarehouseFormValues>({
    name: '',
    address: {
      address: '',
      country: '',
      province: '',
      zipCode: '',
      postalCode: '',
      city: '',
    }
  });

  useEffect(() => {
    axios.get(API_URL + '/countries')
      .then((response) => {
        setCountries(response.data.data.map((c: any) => ({
          value: c.id,
          label: c.name
        })))
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const onSubmit = (formValue: WarehouseFormValues, {setErrors}: FormikHelpers<WarehouseFormValues>) => {
    const data = {
      name: formValue.name,
      address: {
        country_id: formValue.address.country,
        region_id: formValue.address.province,
        city: formValue.address.city,
        postal_code: formValue.address.postalCode || formValue.address.zipCode,
        address: formValue.address.address
      },
    };

    setIsSaving(true);

    api.post(API_URL + '/warehouses', data)
      .then(function () {
        notification.show({
          type: 'success',
          message: {
            translate: 'Company warehouse has been successfully created!'
          },
          autoDismiss: true
        });

        navigate('/account/company/warehouses')
      })
      .catch((error) => {
        console.log(error);
        parseApiError(error, notification, null, setErrors);
        setIsSaving(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255, t('field.error.name.maxlength'))
            .required(t('field.error.name.required')),
          address: Yup.object().shape({
            country: Yup.string()
              .required(t('field.error.country.required')),
            postalCode: Yup.string()
              .required(t('field.error.postalCode.required')),
            city: Yup.string()
              .max(255, t('field.error.city.maxlength'))
              .required(t('field.error.city.required')),
            address: Yup.string()
              .max(255, t('field.error.address.maxlength'))
              .required(t('field.error.address.required')),
          }),
        })}
      >
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
          <form onSubmit={handleSubmit}>
            <section className="fade-content">
              <div className="section-header dash dash-after dash-primary">
                <h1>
                  <Trans i18nKey={'account.company.warehouse.header.title'} context="new" />
                </h1>
                <div>
                  <Trans i18nKey={'account.company.warehouse.header.text'} context="new" />
                </div>
              </div>
              <div className="section-content form">
                <ul className="form-fieldset">
                  <li>
                    <TextField
                      id="name"
                      label={t('field.label.warehouseName')}
                      type="text"
                      error={errors.name}
                      value={values.name}
                      touched={touched.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </li>

                  <AddressForm
                    values={values.address || {}}
                    errors={errors.address || {}}
                    touched={touched.address as any}
                    countries={countries}
                    name="address"
                  />

                </ul>
              </div>
              <div className="section-action">
                {isSaving && <Loader/>}
                {!isSaving && <div>
                  <button type="submit" className="button button-success"
                          onClick={() => handleSubmit()}
                  >
                    {t('actions.add')}
                  </button>
                </div>}
              </div>
            </section>
          </form>
        )}
      </Formik>
    </>
  );
}
