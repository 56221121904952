import { default as React, useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useSelector } from "react-redux";

import { API_FILES_URL, API_URL, configConstant } from "../../../constants";
import PhoneField from "../../../components/PhoneField";
import ImageSelector from "../../../components/ImageSelector";
import ImageEditor from "../../../components/ImageEditor";
import Loader from "../../../components/Loader";
import TextField from "../../../components/Textfield";
import AddressForm from "../../Activation/AddressForm";
import api from "../../../api";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import { parseApiError } from "../../../utils";
import SocialMediaForm from "../../Activation/SocialMediaForm";

interface CompanyFormValues {
  name: string;
  address: {
    address: string;
    country: string;
    province?: string;
    zipCode: string;
    postalCode?: string;
    city: string;
  };
  shipAddress?: {
    address: string;
    country: string;
    province?: string;
    zipCode: string;
    postalCode?: string;
    city: string;
  }
  phone: string;
  phoneCountry?: string;
  website: string;
  description: string;
  warehouses?: {
    name: string;
    address: {
      address: string;
      country: string;
      province?: string;
      zipCode: string;
      postalCode?: string;
      city: string;
    }
  }[];
  socialNetworks: {
    name: string;
    url: string;
  }[];
}

export default function CompanyProfile() {
  const {t} = useTranslation();
  const notification = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [countries, setCountries] = useState([]);
  const [logoError, setLogoError] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [imageEditorOptions, setImageEditorOptions] = useState<any>(null);
  const [logo, setLogo] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<CompanyFormValues>({
    name: '',
    address: {
      address: '',
      country: '',
      province: '',
      zipCode: '',
      postalCode: '',
      city: '',
    },
    shipAddress: {
      address: '',
      country: '',
      province: '',
      zipCode: '',
      postalCode: '',
      city: '',
    },
    warehouses: [],
    phone: '',
    phoneCountry: '',
    website: '',
    description: '',
    socialNetworks: [],
  });

  // @ts-ignore
  const user = useSelector((state) => state.user);
  const userType = user?.profile?.type?.id;
  const isBayer = userType === 2;

  useEffect(() => {
    axios.get(API_URL + '/countries')
      .then((response) => {
        setCountries(response.data.data.map((c: any) => ({
          value: c.id,
          label: c.name
        })))
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  useEffect(() => {
    setIsLoading(true);
    api.get(API_URL + '/companies/my')
      .then((response) => {
        const data = response.data.data;
        setInitialValues({
          name: data.name,
          address: {
            address: data.address.address,
            country: data.address.country?.id,
            province: data.address.region?.id,
            zipCode: data.address.zipCode,
            postalCode: data.address.postalCode,
            city: data.address.city,
          },
          shipAddress: isBayer ? {
            address: data.shipToAddress.address,
            country: data.shipToAddress.country?.id,
            province: data.shipToAddress.region?.id,
            zipCode: data.shipToAddress.zipCode,
            postalCode: data.shipToAddress.postalCode,
            city: data.shipToAddress.city,
          } : undefined,
          warehouses: data.warehouses.map((warehouse: any) => ({
            name: warehouse.name,
            address: {
              address: warehouse.address.address,
              country: warehouse.address.country?.id,
              province: warehouse.address.region?.id,
              zipCode: warehouse.address.zipCode,
              postalCode: warehouse.address.postalCode,
              city: warehouse.address.city,
            }
          })),
          phone: data.businessPhoneNumber,
          website: data.website,
          description: data.description,
          socialNetworks: data.socialNetworks,
        });

        if (data.logo) {
          setLogo({
            previewUrl: API_FILES_URL + data.logo
          });
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        parseApiError(error, notification, null);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeLogo = useCallback((files: any) => {
    setIsOpen(true);
    setLogoError('');
    setImageEditorOptions({
      image: files[0],
      zoom: configConstant.zoom.logo,
      upload: configConstant.upload.logo,
      crop: {}, //...
      mode: configConstant.mode.logo
    });
  }, []);

  const onSaveImage = useCallback((result: any, filename: string) => {
    setLogo({
      ...result,
      previewUrl: result.logo.url,
      filename
    });
    setIsOpen(false);
    setImageEditorOptions(null);
  }, []);

  const onCloseImageEditor = useCallback(() => {
    setIsOpen(false);
    setImageEditorOptions(null);
  }, []);

  const onLoadLogoError = useCallback((files: any) => {
    const error = files?.[0].errors[0];
    setLogoError(`${error.code} ${error.message}`);
  }, []);

  const removeLogo = useCallback(() => {
    setLogo(null);
  }, []);

  const saveLogo = useCallback(() => {
    let data = null;

    if (!logo?.logo?.blob && logo?.previewUrl) {
      return Promise.resolve();
    }

    if (logo?.logo?.blob) {
      data = new FormData();
      data.append('logo', logo?.logo?.blob, logo?.filename);
    }

    return api.post(
      API_URL + '/companies/my/logo',
      data,
      {headers: {"Content-Type": "multipart/form-data"}});
  }, [logo]);

  const onSubmit = useCallback(async (formValue: CompanyFormValues, { setErrors }: FormikHelpers<CompanyFormValues>) => {
    const data = {
      name: formValue.name,
      address: {
        country_id: formValue.address.country,
        region_id: formValue.address.province,
        city: formValue.address.city,
        postal_code: formValue.address.postalCode || formValue.address.zipCode,
        address: formValue.address.address
      },
      business_phone_number: formValue.phone,
      website: formValue.website,
      description: formValue.description,
      socialNetworks: formValue.socialNetworks,
      ship_to_address: isBayer && formValue.shipAddress ? {
        country_id: formValue.shipAddress.country,
        region_id: formValue.shipAddress.province,
        city: formValue.shipAddress.city,
        postal_code: formValue.shipAddress.postalCode || formValue.shipAddress.zipCode,
        address: formValue.shipAddress.address
      } : undefined,
      warehouses: formValue.warehouses ? formValue.warehouses.map(warehouse => ({
        name: warehouse.name,
        address: {
          country_id: warehouse.address.country,
          region_id: warehouse.address.province,
          city: warehouse.address.city,
          postal_code: warehouse.address.postalCode || warehouse.address.zipCode,
          address: warehouse.address.address
        }
      })) : null
    };

    setIsSaving(true);

    try {
      await saveLogo();
    } catch (error) {
      parseApiError(error, notification, null, setErrors);
    }

    api.put(API_URL + '/companies/my', data)
      .then(function () {
        if (!logo?.logo) {
          setIsSaving(false);
          notification.show({
            type: 'success',
            message: {
              translate: 'Company profile has been successfully updated!'
            },
            autoDismiss: true
          });
          return;
        }

      })
      .catch((error) => {
        parseApiError(error, notification, null, setErrors);
        setIsSaving(false);
      });
  }, [isBayer, saveLogo, notification]);

  const US_ID = '2';
  const CA_ID = '1';
  const logoSelectorOptions = useMemo(() => configConstant.selectorOptions.logo, []);

  if (isLoading) {
    return <Loader className="full-width"/>;
  }

  return (
    <>
      {isOpen && <ImageEditor data={imageEditorOptions} onSave={onSaveImage} onCancel={onCloseImageEditor}/>}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255, t('field.error.name.maxlength'))
            .required(t('field.error.name.required')),
          address: Yup.object().shape({
            country: Yup.string()
              .required(t('field.error.country.required')),
            province: Yup.string()
              .test('required', t('field.error.province.required'), function (value) {
                return this.parent.country === US_ID ||  this.parent.country === CA_ID ? !!value : true
              }),
            postalCode: Yup.string()
              .test('required', t('field.error.postalCode.required'), function (value) {
                return this.parent.country === CA_ID ? !!value : true
              }),
            city: Yup.string()
              .max(255, t('field.error.city.maxlength'))
              .required(t('field.error.city.required')),
            address: Yup.string()
              .max(255, t('field.error.address.maxlength'))
              .required(t('field.error.address.required')),
          }),
          ...(isBayer ? {shipAddress: Yup.object().shape({
              country: Yup.string()
                .required(t('field.error.country.required')),
              province: Yup.string()
                .test('required', t('field.error.province.required'), function (value) {
                  return this.parent.country === US_ID || this.parent.country === CA_ID ? !!value : true
                }),
              zipCode: Yup.string()
                .test('required', t('field.error.postalCode.required'), function (value) {
                  return this.parent.country === US_ID ? !!value : true
                }),
              postalCode: Yup.string()
                .test('required', t('field.error.postalCode.required'), function (value) {
                  return this.parent.country === CA_ID ? !!value : true
                }),
              city: Yup.string()
                .max(255, t('field.error.city.maxlength'))
                .required(t('field.error.city.required')),
              address: Yup.string()
                .max(255, t('field.error.address.maxlength'))
                .required(t('field.error.address.required')),
            })} : {}),
          phone: Yup.string()
            .required(t('field.error.phone.required')),
        })}
      >
        {({values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue}) => (
          <form onSubmit={handleSubmit}>
            <section className="fade-content">
              <div className="section-header dash dash-after dash-primary">
                <h1>
                  {t('account.company.profile.header.title')}
                </h1>
                <div>
                  <Trans i18nKey={'account.company.profile.header.text'}/>
                </div>
              </div>
              <div className="section-content form">
                <ul className="form-fieldset">
                  <li>
                    <div>
                      <strong className="txt-primary">
                        {t('account.company.profile.info.title')}
                      </strong>
                      <div className="txt-grey">
                        <Trans i18nKey={'account.company.profile.info.text'}/>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <TextField
                        id="name"
                        label={t('field.label.businessName')}
                        type="text"
                        error={errors.name}
                        value={values.name}
                        touched={touched.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div></div>
                  </li>

                  <AddressForm
                    values={values.address}
                    errors={errors.address as any}
                    touched={touched.address as any}
                    countries={countries}
                    name="address"
                  />

                  <li>
                    <div>
                      <PhoneField
                        id="phone"
                        type="tel"
                        label={t('field.label.businessPhone')}
                        error={errors.phone}
                        value={values.phone}
                        touched={touched.phone}
                        country={values.phoneCountry}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={countries}
                        onCountryChange={(c: string) => {
                          setFieldValue('phoneCountry', c);
                          setFieldValue('phone', '');
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        id="website"
                        label={<Trans components={{span: <span></span>}} i18nKey={'field.label.websiteOptional'}/>}
                        type="text"
                        error={errors.website}
                        value={values.website}
                        touched={touched.website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </li>
                  <li>
                    <div>
                      <TextField
                        id="description"
                        label={<Trans components={{span: <span></span>}} i18nKey={'field.label.descriptionOptional'}/>}
                        type="text"
                        error={errors.description}
                        value={values.description}
                        touched={touched.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </li>

                </ul>

                <ul className="form-fieldset">
                  <li>
                    <div className="field" style={{ paddingBottom: 0 }}>
                      <strong className="txt-primary">
                        Social Media
                      </strong>
                      {/*<button className="txt-primary"*/}
                      {/*        style={{ border: 'none', background: 'transparent' , cursor: 'pointer' }}>*/}
                      {/*  Same as above*/}
                      {/*</button>*/}
                    </div>
                  </li>
                  <SocialMediaForm values={values.socialNetworks || {}} />
                </ul>

                {isBayer && <ul className="form-fieldset">
                   <li>
                    <div className="field" style={{paddingBottom: 0}}>
                      <strong className="txt-primary">
                        <Trans i18nKey={'field.label.shipTo'}/>
                      </strong>
                      {/*<button className="txt-primary"*/}
                      {/*        style={{ border: 'none', background: 'transparent' , cursor: 'pointer' }}>*/}
                      {/*  Same as above*/}
                      {/*</button>*/}
                    </div>
                  </li>

                  <AddressForm
                    values={values.shipAddress || {}}
                    errors={errors.shipAddress as any}
                    touched={touched.shipAddress as any}
                    countries={countries}
                    name="shipAddress"
                  />
                </ul>}

                <ul className="form-fieldset">
                  <li>
                    <div>
                      <strong className="txt-primary">
                        {t('account.company.profile.assets.title')}
                      </strong>
                      <div className="txt-grey">
                        <Trans i18nKey={'account.company.profile.assets.text'}/>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <ImageSelector
                        label={<Trans components={{span: <span></span>}} i18nKey={'field.label.logoOptional'}/>}
                        info={t('field.info.logo')}
                        error={logoError}
                        options={logoSelectorOptions}
                        onChange={onChangeLogo}
                        onError={onLoadLogoError}
                      >
                        {logo?.previewUrl && <div style={{paddingTop: '40px'}}>
                          <div style={{margin: '0 auto', width: '120px', position: 'relative'}}>
                            <div
                              style={{
                                position: 'absolute',
                                width: '24px',
                                height: '24px',
                                top: '-12px',
                                right: '-12px',
                                zIndex: 1,
                                backgroundColor: '#151515',
                                borderRadius: '50%',
                                color: 'white',
                                textAlign: 'center',
                                cursor: 'pointer'
                              }}
                              onClick={() => removeLogo()}>x
                            </div>
                            <img style={{display: 'block', width: '120px', height: 'auto'}} src={logo.previewUrl}
                                 alt="preview"/>
                          </div>
                        </div>}
                      </ImageSelector>
                    </div>
                    <div></div>
                  </li>
                </ul>
              </div>
              <div className="section-action">
                {isSaving && <Loader/>}
                {!isSaving && <div>
                  <button type="submit" className="button button-success"
                          onClick={() => handleSubmit()}
                  >
                    {t('actions.saveChanges')}
                  </button>
                </div>}
              </div>
            </section>
          </form>
        )}
      </Formik>
    </>
  );
}
