import * as React from "react";

import TextField from "../../../components/Textfield";
import Avatar from "../../../components/Avatar";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function OfferCard({
  offer,
  isNego,
  products,
  values,
  handleChange,
  handleBlur
}: {
  values: any;
  handleChange: any;
  handleBlur: any;
  offer: any;
  isNego: Boolean;
  products: any[]
}) {
  const [historyVisible, setHistoryVisible] = useState(false);

  const showHistory = () => {
    setHistoryVisible(s => !s)
  };

  return (
    <div className="offer-card">
      <div className="offer-card-header">
        <div>
          {offer.to.brand && <Avatar
            model={{
              name: offer.to.brand.name,
              image: offer.to.brand.mainPhoto
            }}
            className="avatar-small"
          />}
        </div>
        <button type="button" className="button-link" onClick={showHistory}>Show offer history</button>
        <Link to="message">View message</Link>
      </div>
      <div className="offer-card-body">
        <div className="row row-header">
          <div className="col col-product">Products</div>
          <div className="col col-qty">Inventory</div>
          <div className="col col-msrp">MSRP<br/>(Selling price)</div>
          <div className="col col-wholesale">Wholesale<br/>(Make your offer)</div>
        </div>
        {products.map((product: any, index: number) => (
          <div key={product.id}>
            <div className="row" style={{borderTop: '3px solid #ddd'}}>
              <div className="col col-product">
                <small>
                  {product.sku}
                </small>
                <strong>
                  {product.name}
                </strong>
              </div>
              <div className="cols">
                <div className="row">
                  <div className="col col-details"><strong>Product inventory</strong></div>
                  <div className="col col-qty">
                    <strong>
                      {product.inventory}
                    </strong>
                  </div>
                  <div className="col col-msrp">
                    <strong>
                      ${product.msrp}
                    </strong>
                  </div>
                  <div className="col col-wholesale">
                    <strong>
                      ${product.wholesale}
                    </strong>
                  </div>
                </div>
                {historyVisible && <div className="toggle-group show" style={{marginTop: '-1px'}}>
                  {product.versionHistory.map((version: any) => (
                    <div className="row row-previous" key={version.datetime}>
                      <div className="col col-details">
                        <Avatar model={version.user} className="avatar-xsmall" />
                        <div style={{paddingLeft: '10px'}}>
                          <small>
                            {version.datetime}
                          </small>
                        </div>
                      </div>
                      <div className="col col-qty">
                        {version.inventory}
                      </div>
                      <div className="col col-msrp">
                        ${version.msrp}
                      </div>
                      <div className="col col-wholesale">
                        ${version.wholesale}
                      </div>
                    </div>
                  ))}
                </div>}
                <div className="row txt-grey-dark">
                  {offer.status !== 'accepted' && offer.status !== 'rejected' && <div className="col col-details">
                    {offer.from.user && <Avatar
                      model={{
                        name: offer.from.user.name,
                        image: offer.from.company.logo
                      }}
                      className="avatar-xsmall"
                    />}
                    <div style={{paddingLeft: '10px'}}>
                      <strong>Current offer</strong>
                    </div>
                  </div>}
                  {offer.status === 'accepted' && <div className="col col-details">
                    {offer.from.user && <Avatar
                      model={{
                        name: offer.from.user.name,
                        image: offer.from.company.logo
                      }}
                      className="avatar-xsmall"
                    />}
                    <div style={{paddingLeft: '10px'}}>
                      <strong>Accepted offer</strong>
                    </div>
                  </div>}
                  {offer.status === 'rejected' && <div className="col col-details">
                    {offer.from.user && <Avatar
                      model={{
                        name: offer.from.user.name,
                        image: offer.from.company.logo
                      }}
                      className="avatar-xsmall"
                    />}
                    <div style={{paddingLeft: '10px'}}>
                      <strong>Rejected offer</strong>
                    </div>
                  </div>}
                  <div className="col col-qty">
                    <strong>
                      {product.currentVersion.inventory}
                    </strong>
                  </div>
                  <div className="col col-msrp">
                    <strong>
                      ${product.currentVersion.msrp}
                    </strong>
                  </div>
                  <div className="col col-wholesale">
                    <strong>
                      ${product.currentVersion.wholesale}
                    </strong>
                  </div>
                </div>
                {isNego &&
                <div className="row row-form txt-grey-dark">
                  <div className="col col-details"></div>
                  <div className="col col-qty">
                    <TextField
                      id={`products.${index}.quantity`}
                      label=""
                      type="number"
                      // error={errors.autoMsrp?.percentage}
                      value={values.products[index]?.quantity as any}
                      // touched={touched?.products?.[index]?.offer?.quantity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      min={0}
                      max={parseInt(product.inventory)}
                    />
                  </div>
                  <div className="col col-msrp">
                    <TextField
                      id={`products.${index}.msrp`}
                      label=""
                      type="number"
                      // error={errors.autoMsrp?.percentage}
                      value={values.products[index]?.msrp as any}
                      // touched={touched?.products?.[index]?.offer?.quantity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      min={0}
                    />
                  </div>
                  <div className="col col-wholesale">
                    <TextField
                      id={`products.${index}.wholesale`}
                      label=""
                      type="number"
                      // error={errors.autoMsrp?.percentage}
                      value={values.products[index]?.wholesale as any}
                      // touched={touched?.products?.[index]?.offer?.quantity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      min={0}
                    />
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
