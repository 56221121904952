import * as React from "react";
import { useTranslation } from "react-i18next";

export default function ReviewCounterOffer({ onClose, products, offer, values, confirmOffer }: {
  onClose: () => void;
  confirmOffer: () => void;
  products: any[]; offer: any; values: any }) {
  const {t} = useTranslation();

  return (
    <div className="modal modal-review-offer" style={{overflow: "scroll"}}>
      <div className="modal-window">
        <div className="modal-window-header">
          <strong>
            Review offer
          </strong>
          <a className="icon" onClick={onClose}>
            <i className="icon-modal-close"/>
          </a>
        </div>

        <div className="modal-window-body" style={{padding: '30px 0'}}>
          {/*<span translate="{{ $ctrl.data.body.text }}"></span>*/}

          <div className="column">
            <div className="column-left">
              <div className="section-content" style={{padding: '20px 0 0 0'}}>

                <div className="offer-card">
                  <div className="offer-card-body">
                    <div className="row row-header">
                      <div className="col col-product">Products</div>
                      <div className="col col-details"></div>
                      <div className="col col-qty">Inventory</div>
                      <div className="col col-msrp">MSRP<br/>(Selling price)</div>
                      <div className="col col-wholesale">Wholesale<br/>(Make your offer)</div>
                    </div>
                    {products.map((product: any, index: number) => (
                      <div key={product.id}>
                        <div className="row" style={{borderTop: '3px solid #ddd'}}>
                          <div className="col col-product">
                            <small>
                              {product.sku}
                            </small>
                            <strong>
                              {product.name}
                            </strong>
                          </div>
                          <div className="cols">
                            <div className="row">
                              <div className="col col-details"><strong>Product inventory</strong></div>
                              <div className="col col-qty">
                                <strong>
                                  {product.inventory}
                                </strong>
                              </div>
                              <div className="col col-msrp">
                                <strong>
                                  ${product.msrp}
                                </strong>
                              </div>
                              <div className="col col-wholesale">
                                <strong>
                                  ${product.wholesale}
                                </strong>
                              </div>
                            </div>
                            <div className="row txt-grey-dark">
                              <div className="col col-details">
                                <div style={{paddingLeft: '1px'}}>
                                  <strong>Your counter offer</strong>
                                </div>
                              </div>
                              <div className="col col-qty">
                                <strong>
                                  {values.products[index].quantity}
                                </strong>
                              </div>
                              <div className="col col-msrp">
                                <strong>
                                  ${values.products[index].msrp}
                                </strong>
                              </div>
                              <div className="col col-wholesale">
                                <strong>
                                  ${values.products[index].wholesale}
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

              </div>
            </div>
            <div className="column-right">
              <div>
                <div className="offer-summary" style={{borderColor: '#ddd'}}>
                  <ul className="offer-summary-body">
                    {/*<li ng-if="$ctrl.data.isHusboro">*/}
                    {/*  <span className="expand">On behalf</span>*/}
                    {/*  <strong ng-bind-html="$ctrl.data.offer.etailerName"></strong>*/}
                    {/*</li>*/}
                    <li>
                      <span className="expand">Offer type</span>
                      {offer.type === 'direct_po' &&<strong>Direct PO</strong>}
                      {offer.type === 'reserve' && <strong>Reserve Inventory</strong>}
                      {offer.type === 'consignment' &&<strong>Consignment</strong>}
                    </li>
                    {offer.type === 'reserve' && <li>
                      <span className="expand">Launch date</span>
                      <strong>
                        {offer.dateFrom}
                      </strong>
                    </li>}
                    {offer.type === 'reserve' && <li>
                      <span className="expand">End date</span>
                      <strong>
                        {offer.dateTo}
                      </strong>
                    </li>}
                    <li>
                      <span className="expand">Shipping lead time</span>
                      <strong>
                        {`${offer.shippingLeadTime} day(s)`}
                      </strong>
                    </li>
                    <li>
                      <span className="expand">Payment Terms</span>
                      <strong>
                        {`${offer.paymentTerms} day(s)`}
                      </strong>
                    </li>
                    <li style={{flexDirection: 'column'}}>
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span className="expand">
                          {t('offer.shipping')}
                        </span>
                        {offer.isShippingIncluded && <strong>Included</strong>}
                        {!offer.isShippingIncluded && <strong>Not included</strong>}
                      </div>
                      <div style={{textAlign: 'right', paddingTop: '8px'}}>
                        {offer.isShippingIncluded && <small style={{color: '#444'}}>Brand paying for
                          shipping
                          (excluded from Wholesale price).
                        </small>}
                        {!offer.isShippingIncluded && <small style={{color: '#444'}}>Buyer/Retailer paying
                          for
                          shipping (included in Wholesale price).
                        </small>}
                      </div>
                    </li>
                    <li>
                      <span className="expand">Ship From (Warehouse)</span>
                      <strong>
                        {offer.warehouse.name}
                      </strong>
                    </li>
                    <li ng-if="$ctrl.data.offer.subtotal">
                      <span className="expand">
                        {t('offer.subtotal')}
                      </span>
                      <strong>
                        ${offer.subtotal}
                      </strong>
                    </li>
                    {offer.commission && <li>
                      <span className="expand" translate-value-rate="10%">
                        {t('offer.commission')}
                      </span>
                      <strong>
                        ${offer.commission}
                      </strong>
                    </li>}
                    <li>
                      <strong className="expand">
                        {t('offer.total')}
                      </strong>
                      <strong>
                        ${offer.totalMsrp}
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-window-footer">
          <button type="button" className="button" onClick={onClose}>
            <span>
               {t('actions.cancel')}
            </span>
          </button>
          <button type="button" className="button button-primary" onClick={confirmOffer}>
            <span>
               {t('actions.sendCounterOffer')}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
