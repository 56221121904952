import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Tab {
  translate: string;
  url: string;
  section: string;
}

interface TabBarProps {
  active: string;
  tabs: Tab[];
}

export default function TabBar({ active, tabs }: TabBarProps) {
  const {t} = useTranslation();

  return (
    <div className="tab-bar">
      {tabs.map(tab => (
        <Link className={active == tab.section ? 'active' : ''} to={tab.url} key={tab.translate}>
          <span>{t(tab.translate)}</span>
        </Link>
      ))}
    </div>
  );
}
