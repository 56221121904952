import * as React from "react";
import randomColor from 'randomcolor';

import ImagePreloader from "../ImagePreloader";
import { API_FILES_URL } from "../../constants";

const getInitials = function (string: string) {
 let split = string.split(' '),
   initials;

 initials = split[0][0];

 if (split.length > 1) {
  initials += split[split.length - 1][0];
 }

 return initials;
};

export const strToHex = function(string: string) {
 return randomColor({
  luminosity: 'dark',
  seed: string
 });
};

interface AvatarProps {
 model: {
  name: string;
  image?: string;
  hex?: string;
 };
 className?: string;
 apiFilesUrl?: string;
}

export default function Avatar({ model, className = '', apiFilesUrl = API_FILES_URL }: AvatarProps) {
 const initials = model?.name ? getInitials(model.name) : '';
 const hex = strToHex(initials);

 return (
   <div className={`avatar ${className}`}>
    {model?.image && <ImagePreloader url={apiFilesUrl + model.image} alt={model.name} />}
    {!model?.image && <span style={{ backgroundColor: hex }}>
     {initials}
    </span>}
   </div>
 );
}
