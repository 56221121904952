import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import api from "../../../api";
import { API_FILES_URL, API_URL } from "../../../constants";
import { useEffect, useMemo } from "react";
import { Link, useMatch } from "react-router-dom";
import { useState } from "react";
import OfferCard from "./OfferCard";
import ReviewCounterOffer from "./ReviewCounterOffer";
import TabBar from "../../../components/TabBar";
import { Formik } from "formik";
import * as Yup from "yup";
import Loader from "../../../components/Loader";
import ConfirmModal from "./ConfirmModal";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import { parseApiError } from "src/utils";
import { useSelector } from "react-redux";
import moment from "moment";

import './index.css';
import FileSelector from "../../../components/FileSelector";
import CheckboxField from "../../../components/CheckboxField";

export default function Offer() {
  const {t} = useTranslation();
  const match = useMatch('/account/offers/:id');
  const match2 = useMatch('/account/offers/:id/:section');
  const id = match?.params.id;
  const currentSection = match2?.params.section || 'offer';
  const notification = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isNego, setIsNego] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [offer, setOffer] = useState<any>(null);
  const [products, setProducts] = useState([]);
  const [isConfirmAcceptModalOpen, setIsConfirmAcceptModalOpen] = useState(false);
  const [isConfirmRejectModalOpen, setIsConfirmRejectModalOpen] = useState(false);

  const loadOffer = () => {
    setIsLoading(true);
    api.get(API_URL + '/offers/' + id)
      .then(function (res) {
        const data = res.data.data;

        setOffer({
          ...data,
          dateFrom: moment(data.dateFrom).format('YYYY-MM-DD'),
          dateTo: moment(data.dateTo).format('YYYY-MM-DD'),
        });
        setProducts(data.products.map((p: any) => ({
          ...p,
          versionHistory: p.versionHistory?.map(( version: any) => ({
            ...version,
            datetime: moment(version.datetime).format('YYYY-MM-DD hh:mm')
          }))
        })));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        parseApiError(error, notification, null);
      });
  };

  useEffect(() => {
    loadOffer();
  }, [id]);

  const status = offer?.status;

  // @ts-ignore
  const user = useSelector((state) => state.user);
  const currentUserId = user?.profile?.id;
  const userType = user?.profile?.type?.id;
  const isAdmin = user?.profile?.isAdmin;
  const isBuyer = userType === 2;

  const hasAction = useMemo(() => {
    if (!offer?.isEditable) {
      return false;
    }

    if (offer?.from?.user?.id === currentUserId && offer?.status !== 'negotiating') {
      return false;
    }

    if (
      offer?.status === 'accepted'
      || offer?.status === 'rejected'
      || offer?.status === 'signed'
      || offer?.status === 'shipped'
      || offer?.status === 'paid'
    ) {
      return false;
    } else {
      return true;
    }
  }, [offer]);

  const statusClass = useMemo(() => {
    if (status === 'rejected') {
      return 'txt-danger';
    }
    if (status === 'accepted') {
      return 'txt-success';
    }
    if (status === 'new' || status === 'negotiating') {
      return 'txt-primary';
    }
    return '';
  }, [status]);


  const rejectOffer = () => {
    setIsConfirmRejectModalOpen(false);
    const data = {
      products: offer.products.map((product: any) => ({
        productId: product.id,
        inventory: product.currentVersion.quantity,
        msrp: product.currentVersion.msrp,
        wholesale: product.currentVersion.wholesale
      }))
    };

    api.post(API_URL + '/offers/' + offer.id + '/reject', data)
      .then(function () {
        notification.show({
          type: 'success',
          message: {
            translate: 'Offer was rejected successfully'
          },
          autoDismiss: true
        });
        loadOffer();
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  const acceptOffer = () => {
    setIsConfirmAcceptModalOpen(false);
    const data = {
      products: offer.products.map((product: any) => ({
        productId: product.id,
        inventory: product.currentVersion.quantity,
        msrp: product.currentVersion.msrp,
        wholesale: product.currentVersion.wholesale
      }))
    };

    api.post(API_URL + '/offers/' + offer.id + '/accept', data)
      .then(function () {
        notification.show({
          type: 'success',
          message: {
            translate: 'Offer was accepted successfully'
          },
          autoDismiss: true
        });
        loadOffer();
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  const handleAcceptOffer = () => {
    setIsConfirmAcceptModalOpen(true);
  };

  const closeAcceptConfirmModal = () => {
    setIsConfirmAcceptModalOpen(false);
  };

  const handleRejectOffer = () => {
    setIsConfirmRejectModalOpen(true);
  };

  const closeRejectConfirmModal = () => {
    setIsConfirmRejectModalOpen(false);
  };

  const toggleNego = () => {
    setIsNego(!isNego)
  };

  const onCloseModal = () => {
    setIsReviewModalOpen(false);
  };

  const negociateOffer = () => {
    setIsReviewModalOpen(true);
  };

  const tabs = [
    {
      section: 'offer',
      translate: 'account.offers.tabs.offer',
      url: `/account/offers/${id}`
    },
    {
      section: 'message',
      translate: 'account.offers.tabs.message',
      url: `/account/offers/${id}/message`
    }
  ];

  const initialValues = {
    products: offer?.products ? offer.products.map((p: any) => (
      {
        id: p.id,
        quantity: 0,
        msrp: 0,
        wholesale: 0
      }
    )) : []
  };

  const onSubmit = (values: any) => {
    onCloseModal();

    const data = {
        products: values.products.map((p: any) => ({
          productId: p.id,
          inventory: p.quantity,
          msrp: p.msrp,
          wholesale: p.wholesale
        }))
    };

    api.put(API_URL + '/offers/' + offer.id, data)
      .then(function () {
        notification.show({
          type: 'success',
          message: {
            translate: 'Offer was updated successfully'
          },
          autoDismiss: true
        });
        setIsNego(!isNego);
        loadOffer();
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  const removeFile = (e: any) => {
    e.stopPropagation();

    api.post(API_URL + '/offers/' + offer.id + '/shipping-file')
      .then(function () {
        notification.show({
          type: 'success',
          message: {
            translate: 'File was uploaded successfully'
          },
          autoDismiss: true
        });
        loadOffer();
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  const onUploadFile = (files: any) => {
    const data = new FormData();

    const file = files[0];

    if (!file) {
      return;
    }

    data.append('shipping_file', file, file.filename);

    api.post(API_URL + '/offers/' + offer.id + '/shipping-file', data)
      .then(function () {
        notification.show({
          type: 'success',
          message: {
            translate: 'File was uploaded successfully'
          },
          autoDismiss: true
        });
        loadOffer();
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  const toggleIsPaid = (e: any) => {
    const isPaid = e.target.checked;

    const url = isPaid ? API_URL + '/offers/' + offer.id + '/paid' : API_URL + '/offers/' + offer.id + '/unpaid'


    api.post(url)
      .then(function () {
        notification.show({
          type: 'success',
          message: {
            translate: 'Offer was updated successfully'
          },
          autoDismiss: true
        });
        loadOffer();
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  return (
    <div>
      <TabBar tabs={tabs} active={currentSection}/>
      {isConfirmAcceptModalOpen && <ConfirmModal
        title="Accept offer"
        text="Please confirm that you accept this offer? This action cannot be undone."
        onClose={closeAcceptConfirmModal}
        confirm={acceptOffer}
        confirmBtnText="Accept"
      />}
      {isConfirmRejectModalOpen && <ConfirmModal
        title="Reject offer"
        text="Are you sure you want to reject this offer? This action cannot be undone."
        onClose={closeRejectConfirmModal}
        confirm={rejectOffer}
        confirmBtnText="Reject"
      />}
      <form name="$ctrl.form">
        {isLoading && <Loader/>}
        {!isLoading && offer && <section className="fade-content">
          <div className="section-header dash dash-after">
            <h1>
              {t('account.offers.offer.header.title', {offer: offer.id})}
            </h1>
            <div>
              <Trans i18nKey={'account.offers.offer.header.text'}/>
            </div>
          </div>
          <div className="section-content">
            {status === 'rejected' && <div
              style={{
                padding: '20px',
                borderRadius: '4px',
                backgroundColor: 'rgba(175,131,57,0.3)',
                border: '1px solid rgba(175,131,57,0.1)'
              }}>
              <h3>This offer has been rejected!</h3>
              <p style={{paddingTop: '10px', fontSize: '16px', lineHeight: '24px', color: 'rgba(0, 0, 0, 0.4)'}}>Lorem
                ipsum dolor sit amet, consectetur adipiscing elit...</p>
            </div>}
            {status === 'accepted' && <div style={{
                                             padding: '20px',
                                             borderRadius: '4px',
                                             backgroundColor: 'rgba(175,131,57,0.3)',
                                             border: '1px solid rgba(175,131,57,0.1)'
                                           }}>
              <h3>This offer has been accepted!</h3>
              <p
                style={{
                  paddingTop: '10px',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: 'rgba(0, 0, 0, 0.4)'
                }}>Congratulation!
                We are now preparing the contract and will send it soon.</p>
            </div>}
            {status === 'signed' && <div
              style={{
                padding: '20px',
                borderRadius: '4px',
                backgroundColor: 'rgba(175,131,57,0.3)',
                border: '1px solid rgba(175,131,57,0.1)'
              }}>
              <h3>The contract has been signed!</h3>
              <p style={{paddingTop: '10px', fontSize: '16px', lineHeight: '24px', color: 'rgba(0, 0, 0, 0.4)'}}>Lorem
                ipsum dolor sit amet, consectetur adipiscing elit...</p>
            </div>}
            {status === 'shipped' && <div ng-switch-when="shipped"
                                          style={{
                                            padding: '20px',
                                            borderRadius: '4px',
                                            backgroundColor: 'rgba(175,131,57,0.3)',
                                            border: '1px solid rgba(175,131,57,0.1)'
                                          }}>
              <h3>The products has been shipped!</h3>
              <p style={{paddingTop: '10px', fontSize: '16px', lineHeight: '24px', color: 'rgba(0, 0, 0, 0.4)'}}>Lorem
                ipsum dolor sit amet, consectetur adipiscing elit...</p>
            </div>}
            {status === 'paid' && <div
              style={{
                padding: '20px',
                borderRadius: '4px',
                backgroundColor: 'rgba(175,131,57,0.3)',
                border: '1px solid rgba(175,131,57,0.1)'
              }}>
              <h3>This offer has been paid!</h3>
              <p style={{paddingTop: '10px', fontSize: '16px', lineHeight: '24px', color: 'rgba(0, 0, 0, 0.4)'}}>Lorem
                ipsum dolor sit amet, consectetur adipiscing elit...</p>
            </div>}
          </div>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({})}
          >
            {({values, errors, touched, handleSubmit, handleChange, handleBlur}) => (
              <div className="column">
                {isReviewModalOpen && <ReviewCounterOffer
                  onClose={onCloseModal}
                  products={products}
                  values={values}
                  offer={offer}
                  confirmOffer={handleSubmit}
                />}
                <div className="column-left">
                  <div className="section-content" style={{padding: '20px 0 0 0'}}>
                    <OfferCard
                      offer={offer}
                      isNego={isNego}
                      products={products}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="column-right">
                  <div>
                    <div className="offer-summary" style={{borderColor: '#ddd'}}>
                      <ul className="offer-summary-body">
                        {/*<li ng-if="$ctrl.data.isHusboro">*/}
                        {/*  <span className="expand">On behalf</span>*/}
                        {/*  <strong ng-bind-html="$ctrl.data.offer.from.company.name"></strong>*/}
                        {/*</li>*/}
                        <li>
                          <span className="expand">Offer status</span>
                          <span style={{fontWeight: '600'}} className={statusClass}>
                      {status}
                    </span>
                        </li>
                        <li>
                          <span className="expand">Offer type</span>
                          {offer.type === 'direct_po' && <strong>Direct PO</strong>}
                          {offer.type === 'reserve' && <strong>Reserve Inventory</strong>}
                          {offer.type === 'consignment' && <strong>Consignment</strong>}
                        </li>
                        {offer.type === 'reserve' && <li>
                          <span className="expand">Launch date</span>
                          <strong>
                            {offer.dateFrom}
                          </strong>
                        </li>}
                        {offer.type === 'reserve' && <li>
                          <span className="expand">End date</span>
                          <strong>
                            {offer.dateTo}
                          </strong>
                        </li>}

                        <li>
                          <span className="expand">Shipping lead time</span>
                          <strong>
                            {`${offer.shippingLeadTime}  day(s)`}
                          </strong>
                        </li>
                        <li>
                          <span className="expand">Payment Terms</span>
                          <strong>
                            {`${offer.paymentTerms}  day(s)`}
                          </strong>
                        </li>
                        <li style={{flexDirection: 'column'}}>
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span className="expand">
                              {t('offer.shipping')}
                            </span>
                            {offer.isShippingIncluded && <strong>Included</strong>}
                            {!offer.isShippingIncluded && <strong>Not included</strong>}
                          </div>
                          <div style={{textAlign: 'right', paddingTop: '8px'}}>
                            {offer.isShippingIncluded && <small style={{color: '#444'}}>Brand
                              paying for shipping (excluded from Wholesale price).
                            </small>}
                            {!offer.isShippingIncluded && <small
                              style={{color: '#444'}}>Buyer/Retailer paying for shipping (included in Wholesale
                              price).
                            </small>}
                          </div>
                        </li>
                        {offer.warehouse && <li>
                          <span className="expand">Ship From (Warehouse)</span>
                          <strong>
                            {offer.warehouse.name}
                          </strong>
                        </li>}
                        {offer.subtotal && <li>
                          <span className="expand">
                            {t('offer.subtotal')}
                          </span>
                          <strong>
                            ${offer.subtotal}
                          </strong>
                        </li>}
                        {offer.commission && <li>
                          <span className="expand" translate-value-rate="10%">
                            {t('offer.commission')}
                          </span>
                          <strong>
                            ${offer.commission}
                          </strong>
                        </li>}
                        <li>
                          <strong className="expand">
                            {t('offer.total')}
                          </strong>
                          <strong>
                            ${offer.totalMsrp}
                          </strong>
                        </li>
                        {!isBuyer && <li>
                          <div>
                            <span className="expand" style={{ marginBottom: '16px', display: 'inline-block' }}>
                             Shipping/Tracking
                            </span>
                            <FileSelector
                              options={{}}
                              onChange={onUploadFile}
                            >
                              {offer.shippingFile && <div style={{ paddingTop: '40px', textAlign: 'center' }}>
                                <div
                                  style={{
                                    display: 'inline-block',
                                    padding: '16px',
                                    backgroundColor: '#fff',
                                    borderRadius: '4px',
                                    border: '1px solid #ddd',
                                    position: 'relative'
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'absolute',
                                      width: '24px',
                                      height: '24px',
                                      top: '-12px',
                                      right: '-12px',
                                      zIndex: '1',
                                      backgroundColor: '#151515',
                                      borderRadius: '50%',
                                      color: 'white',
                                      textAlign: 'center',
                                      cursor: 'pointer'
                                    }}
                                    onClick={removeFile}
                                  >x</div>
                                  <img src={API_FILES_URL + offer.shippingFile} width={100} alt=""/>
                                </div>
                              </div>}
                            </FileSelector>
                          </div>
                        </li>}
                        {isAdmin && !isBuyer && <li>
                          <div>
                            <div className="expand" style={{ marginBottom: '16px' }}>
                              Payment status
                            </div>
                            <div style={{ display: 'flex' }}>
                              <CheckboxField
                                name="offer.includeShipping"
                                onChange={toggleIsPaid}
                                value={offer.isPaid}
                              />
                              <span style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: 'left',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                color: '#888',
                                paddingLeft: '10px'
                              }}>
                              <span>Paid</span>
                            </span>
                            </div>
                          </div>
                        </li>}
                      </ul>
                      <div className="offer-summary-footer">
                        {isSaving && <Loader />}
                        {isNego && !isSaving && hasAction && <div>
                          <button type="button" className="button button-block button-primary" onClick={negociateOffer}>
                            {t('actions.reviewOffer')}
                          </button>
                          <button type="button" className="button button-block" onClick={toggleNego}>
                            {t('actions.cancel')}
                          </button>
                        </div>}
                        {!isNego && !isSaving && <div>
                          <Link to="message" className="button button-block">
                            {t('actions.sendMessage')}
                          </Link>
                          {hasAction && <>
                            <button type="button" className="button button-block" onClick={toggleNego}>
                              {t('actions.counterOffer')}
                            </button>
                            <button type="button" className="button button-block button-danger" onClick={handleRejectOffer}>
                              {t('actions.rejectOffer')}
                            </button>
                            <button type="button" className="button button-block button-primary" onClick={handleAcceptOffer}>
                              {t('actions.acceptOffer')}
                            </button>
                          </>}
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </section>}
      </form>
    </div>
  );
}
