import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

const axiosApiInstance = applyCaseMiddleware(axios.create());

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async config => {
    config.headers = {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      accept: 'application/json',
    };
    return config;
  });

export default axiosApiInstance;
