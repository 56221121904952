import { Trans, useTranslation } from "react-i18next";
import { default as React, useCallback, useEffect, useMemo, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import TextField from "../../../components/Textfield";
import ImageEditor from "../../../components/ImageEditor";
import ImageSelector from "../../../components/ImageSelector";
import { API_FILES_URL, API_URL, configConstant } from "../../../constants";
import api from "../../../api";
import { parseApiError } from "../../../utils";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import Loader from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import SocialMediaForm from "../../Activation/SocialMediaForm";

interface BrandFormValues {
  name: string;
  website: string;
  description: string;
  socialNetworks: {
    name: string;
    url: string;
  }[];
}

export default function BrandProfile() {
  const {t} = useTranslation();
  const notification = useNotification();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [initialValues, setInitialValues] = useState<BrandFormValues>({
    name: '',
    website: '',
    description: '',
    socialNetworks: []
  });
  const params = useParams();

  const id = params.id;
  const isNew = id === 'new';

  // image editor
  const [isOpen, setIsOpen] = useState(false);
  const [imageEditorOptions, setImageEditorOptions] = useState<any>(null);

  const [logo, setLogo] = useState<any>(null);
  const [logoError, setLogoError] = useState('');
  const [cover, setCover] = useState<any>(null);
  const [coverError, setCoverError] = useState('');
  const [imageError, setImageError] = useState({
    image1: '',
    image2: '',
    image3: ''
  });
  const [image1, setImage1] = useState<any>(null);
  const [image2, setImage2] = useState<any>(null);
  const [image3, setImage3] = useState<any>(null);

  const onCloseImageEditor = () => {
    setIsOpen(false);
    setImageEditorOptions(null);
  };

  const onChangeLogo = useCallback((files: any) => {
    setIsOpen(true);
    setLogoError('');
    setImageEditorOptions({
      image: files[0],
      zoom: configConstant.zoom.logo,
      upload: configConstant.upload.logo,
      crop: {}, //...
      mode: configConstant.mode.logo
    });
  }, []);

  const onSaveImage = (result: any, filename: string) => {
    if (result.logo) {
      setLogo({
        ...result,
        previewUrl: result.logo.url,
        filename
      });
    } else if (result.cover) {
      setCover({
        ...result,
        previewUrl: result.cover.url,
        filename
      });
    } else if (imageEditorOptions.imageItem === 'image1') {
      setImage1({
        ...result,
        previewUrl: result.image.url,
        filename
      });
    } else if (imageEditorOptions.imageItem === 'image2') {
      setImage2({
        ...result,
        previewUrl: result.image.url,
        filename
      });
    } else if (imageEditorOptions.imageItem === 'image3') {
      setImage3({
        ...result,
        previewUrl: result.image.url,
        filename
      });
    }
    setIsOpen(false);
    setImageEditorOptions(null);
  };

  const onLoadLogoError = useCallback((files: any) => {
    const error = files?.[0].errors[0];
    setLogoError(`${error.code} ${error.message}`);
  }, []);

  const removeLogo = (event: any) => {
    setLogo(null);
    event?.stopPropagation();
  };

  const onChangeCover = useCallback((files: any) => {
    setIsOpen(true);
    setCoverError('');
    setImageEditorOptions({
      image: files[0],
      zoom: configConstant.zoom.cover,
      upload: configConstant.upload.cover,
      crop: {}, //...
      mode: configConstant.mode.cover
    });
  }, []);

  const onLoadCoverError = useCallback((files: any) => {
    const error = files?.[0].errors[0];
    setCoverError(`${error.code} ${error.message}`);
  }, []);

  const removeCover = (event: any) => {
    setCover(null);

    event?.stopPropagation();
  };

  const removeProductImage = (imageItem: string, event: any) => {
    if (imageItem === 'image1') {
      setImage1(null);
    } else if (imageItem === 'image2') {
      setImage2(null);
    } else if (imageItem === 'image3') {
      setImage3(null);
    }

    event?.stopPropagation();
  };

  const onChangeImage = useCallback((files: any, field: string) => {
    setIsOpen(true);
    setImageError(state => ({
      ...state,
      [field]: ''
    }));
    setImageEditorOptions({
      image: files[0],
      zoom: configConstant.zoom.image,
      upload: configConstant.upload.image,
      crop: {}, //...
      mode: configConstant.mode.image,
      imageItem: field
    });
  }, []);

  const onLoadImageError = useCallback((files: any, field: string) => {
    const error = files?.[0].errors[0];
    setImageError(state => ({
      ...state,
      [field]: `${error.code} ${error.message}`
    }));
  }, []);

  const onChangeImage1 = useCallback((files: any) => onChangeImage(files,'image1'), [onChangeImage]);
  const onChangeImage2 = useCallback((files: any) => onChangeImage(files,'image2'), [onChangeImage]);
  const onChangeImage3 = useCallback((files: any) => onChangeImage(files,'image3'), [onChangeImage]);

  const onLoadImageErrorImage1 = useCallback((files: any) => onLoadImageError(files,'image1'), [onLoadImageError]);
  const onLoadImageErrorImage2 = useCallback((files: any) => onLoadImageError(files,'image2'), [onLoadImageError]);
  const onLoadImageErrorImage3 = useCallback((files: any) => onLoadImageError(files,'image3'), [onLoadImageError]);

  const saveLogo = useCallback((id: string) => {
    if (!logo?.logo) {
      return;
    }
    const data = new FormData();

    data.append('main_photo', logo?.logo?.blob, logo?.filename);

    api.post(API_URL + `/brands/${id}/main-photo`, data, {headers: {"Content-Type": "multipart/form-data"}})
      .then(() => {})
      .catch((error) => {
        console.error(error)
      });
  }, [logo]);

  const saveCover = useCallback((id: string) => {
    if (!cover?.cover) {
      return;
    }
    const data = new FormData();

    data.append('profile_cover', cover?.cover?.blob, cover?.filename);

    api.post(API_URL + `/brands/${id}/profile-cover`, data, {headers: {"Content-Type": "multipart/form-data"}})
      .then(() => {})
      .catch((error) => {
        console.error(error)
      });
  }, [cover]);

  const saveAdditionalPhotos = useCallback((id: string) => {
    const data = new FormData();

    if (image1?.image) data.append('additional_photos[]', image1?.image?.blob, image1?.filename);
    if (image2?.image) data.append('additional_photos[]', image2?.image?.blob, image2?.filename);
    if (image3?.image) data.append('additional_photos[]', image3?.image?.blob, image3?.filename);

    api.post(API_URL + `/brands/${id}/additional-photos`, data, {headers: {"Content-Type": "multipart/form-data"}})
      .then(() => {})
      .catch((error) => {
        console.error(error)
      });
  }, [image1, image2, image3]);

  useEffect(() => {
    if (!isNew) {
      api.get(API_URL + '/brands/' + id)
        .then(function (res) {
          const data = res.data.data;

          setInitialValues({
            name: data.name,
            website: data.website,
            description: data.description || '',
            socialNetworks: data.socialNetworks,
          });

          if (data.mainPhoto) {
            setLogo({
              previewUrl: API_FILES_URL + data.mainPhoto
            });
          }
          if (data.profileCover) {
            setCover({
              previewUrl: API_FILES_URL + data.profileCover
            });
          }
          if (data.additionalPhotos[0]) {
            setImage1({
              previewUrl: API_FILES_URL + data.additionalPhotos[0]
            });
          }
          if (data.additionalPhotos[1]) {
            setImage2({
              previewUrl: API_FILES_URL + data.additionalPhotos[1]
            });
          }
          if (data.additionalPhotos[2]) {
            setImage3({
              previewUrl: API_FILES_URL + data.additionalPhotos[2]
            });
          }
        })
        .catch((error) => {
          console.log(error);
          parseApiError(error, notification, null);
        });
    }
  }, [id]);

  const onSubmit = useCallback((formValue: BrandFormValues, { setErrors }: FormikHelpers<BrandFormValues>) => {
    const data = {
      name: formValue.name,
      website: formValue.website,
      description: formValue.description
    };

    setIsSaving(true);

    if (isNew) {
      api.post(API_URL + '/brands', data)
        .then(function (res) {
          setIsSaving(false);
          notification.show({
            type: 'success',
            message: {
              translate: 'Brand has been successfully created!'
            },
            autoDismiss: true
          });

          saveLogo(res.data.data.id);
          saveCover(res.data.data.id);
          saveAdditionalPhotos(res.data.data.id);

          navigate('/account/brands');
        })
        .catch((error) => {
          console.log(error);
          parseApiError(error, notification, null, setErrors);
          setIsSaving(false);
        });
    } else {
      api.put(API_URL + '/brands/' + id, data)
        .then(function () {
          setIsSaving(false);
          notification.show({
            type: 'success',
            message: {
              translate: 'Brand has been successfully upd!'
            },
            autoDismiss: true
          });

          saveLogo(id || '');
          saveCover(id || '');
          saveAdditionalPhotos(id || '');
          navigate('/account/brands');
        })
        .catch((error) => {
          console.log(error);
          parseApiError(error, notification, null, setErrors);
          setIsSaving(false);
        });
    }
  }, [id, isNew, notification, saveLogo, saveCover, saveAdditionalPhotos]);

  const deleteBrand = () => {
    api.delete(API_URL + '/brands/' + id)
      .then(function () {
        notification.show({
          type: 'success',
          message: {
            translate: 'Brand has been successfully deleted!'
          },
          autoDismiss: true
        });

        navigate('/account/brands')
      })
      .catch((error) => {
        console.log(error);
        parseApiError(error, notification, null);
        setIsSaving(false);
      });
  };

  const logoSelectorOptions = useMemo(() => configConstant.selectorOptions.logo, []);
  const coverSelectorOptions = useMemo(() => configConstant.selectorOptions.cover, []);
  const imageSelectorOptions = useMemo(() => configConstant.selectorOptions.image, []);

  return (
    <section className="fade-content">
      {isOpen && <ImageEditor data={imageEditorOptions} onSave={onSaveImage} onCancel={onCloseImageEditor}/>}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255, t('field.error.name.maxlength'))
            .required(t('field.error.name.required')),
          website: Yup.string()
            .max(255, t('field.error.website.maxlength'))
            .required(t('field.error.website.required')),
        })}
      >
        {({values, errors, touched, handleSubmit, handleChange, handleBlur}) => (
          <form onSubmit={handleSubmit}>
            <div className="section-header dash dash-after dash-primary">
              <h1>
                <Trans i18nKey={'account.brand.profile.header.title'} context={isNew ? "new": "other"} />
              </h1>
              <div>
                <Trans i18nKey={'account.brand.profile.header.text'} context={isNew ? "new": "other"} />
              </div>
            </div>
            <div className="section-content form">
              <ul className="form-fieldset">
                <li>
                  <div>
                    <strong className="txt-primary">
                      {t("account.brand.profile.info.title")}
                    </strong>
                    <div className="txt-grey">
                      <Trans i18nKey={'account.brand.profile.info.text'} />
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <TextField
                      id="name"
                      label={t('field.label.brandName')}
                      type="text"
                      error={errors.name}
                      value={values.name}
                      touched={touched.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div>
                    <TextField
                      id="website"
                      label={t('field.label.website')}
                      type="text"
                      error={errors.website}
                      value={values.website}
                      touched={touched.website}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // validate-pattern="url"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <TextField
                      id="description"
                      label={<Trans components={{span: <span></span>}} i18nKey={'field.label.descriptionOptional'}/>}
                      type="text"
                      error={errors.description}
                      value={values.description}
                      touched={touched.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </li>
              </ul>
              <ul className="form-fieldset">
                <li>
                  <div className="field" style={{ paddingBottom: 0 }}>
                    <strong className="txt-primary">
                      Social Media
                    </strong>
                    {/*<button className="txt-primary"*/}
                    {/*        style={{ border: 'none', background: 'transparent' , cursor: 'pointer' }}>*/}
                    {/*  Same as above*/}
                    {/*</button>*/}
                  </div>
                </li>
                <SocialMediaForm values={values.socialNetworks || {}} />
              </ul>
              <ul className="form-fieldset">
                <li>
                  <div>
                    <strong className="txt-primary">
                      {t("account.brand.profile.assets.title")}
                    </strong>
                    <div className="txt-grey">
                      <Trans i18nKey={'account.brand.profile.assets.text'} />
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <ImageSelector
                      label={<Trans components={{span: <span></span>}} i18nKey={'field.label.logoOptional'}/>}
                      info={t('field.info.logo')}
                      error={logoError}
                      options={logoSelectorOptions}
                      onChange={onChangeLogo}
                      onError={onLoadLogoError}
                    >
                      {logo?.previewUrl && <div style={{ paddingTop: '40px' }}>
                        <div style={{margin: '0 auto', width: '120px', position: 'relative'}}>
                          <div
                            style={{
                              position: 'absolute',
                              width: '24px',
                              height: '24px',
                              top: '-12px',
                              right: '-12px',
                              zIndex: 1,
                              backgroundColor: '#151515',
                              borderRadius: '50%',
                              color: 'white',
                              textAlign: 'center',
                              cursor: 'pointer'
                            }}
                            onClick={removeLogo}>x
                          </div>
                          <img style={{display: 'block', width: '120px', height: 'auto'}} src={logo.previewUrl}
                               alt="preview"/>
                        </div>
                      </div>}
                    </ImageSelector>
                  </div>
                  <div>
                    <ImageSelector
                      label={<Trans components={{span: <span></span>}} i18nKey={'field.label.coverOptional'}/>}
                      info={t('field.info.cover')}
                      error={coverError}
                      options={coverSelectorOptions}
                      onChange={onChangeCover}
                      onError={onLoadCoverError}
                    >
                      {cover?.previewUrl && <div style={{ paddingTop: '40px' }}>
                        <div style={{margin: '0 auto', width: '120px', position: 'relative'}}>
                          <div
                            style={{
                              position: 'absolute',
                              width: '24px',
                              height: '24px',
                              top: '-12px',
                              right: '-12px',
                              zIndex: 1,
                              backgroundColor: '#151515',
                              borderRadius: '50%',
                              color: 'white',
                              textAlign: 'center',
                              cursor: 'pointer'
                            }}
                            onClick={removeCover}>x
                          </div>
                          <img style={{display: 'block', width: '120px', height: 'auto'}} src={cover.previewUrl}
                               alt="preview"/>
                        </div>
                      </div>}
                    </ImageSelector>
                  </div>
                </li>
                <li>
                  <div>
                    <ImageSelector
                      label={<Trans components={{span: <span></span>}} i18nKey={'field.label.productImageOptional'}/>}
                      info={t('field.info.image')}
                      error={imageError.image1}
                      options={imageSelectorOptions}
                      onChange={onChangeImage1}
                      onError={onLoadImageErrorImage1}
                    >
                      {image1?.previewUrl && <div style={{ paddingTop: '40px' }}>
                        <div style={{margin: '0 auto', width: '120px', position: 'relative'}}>
                          <div
                            style={{
                              position: 'absolute',
                              width: '24px',
                              height: '24px',
                              top: '-12px',
                              right: '-12px',
                              zIndex: 1,
                              backgroundColor: '#151515',
                              borderRadius: '50%',
                              color: 'white',
                              textAlign: 'center',
                              cursor: 'pointer'
                            }}
                            onClick={(event) => removeProductImage('image1', event)}>x
                          </div>
                          <img style={{display: 'block', width: '120px', height: 'auto'}} src={image1.previewUrl}
                               alt="preview"/>
                        </div>
                      </div>}
                    </ImageSelector>
                  </div>
                  <div>
                    <ImageSelector
                      label={<Trans components={{span: <span></span>}} i18nKey={'field.label.productImageOptional'}/>}
                      info={t('field.info.image')}
                      error={imageError.image1}
                      options={imageSelectorOptions}
                      onChange={onChangeImage2}
                      onError={onLoadImageErrorImage2}
                    >
                      {image2?.previewUrl && <div style={{ paddingTop: '40px' }}>
                        <div style={{margin: '0 auto', width: '120px', position: 'relative'}}>
                          <div
                            style={{
                              position: 'absolute',
                              width: '24px',
                              height: '24px',
                              top: '-12px',
                              right: '-12px',
                              zIndex: 1,
                              backgroundColor: '#151515',
                              borderRadius: '50%',
                              color: 'white',
                              textAlign: 'center',
                              cursor: 'pointer'
                            }}
                            onClick={(event) => removeProductImage('image2', event)}>x
                          </div>
                          <img style={{display: 'block', width: '120px', height: 'auto'}} src={image2.previewUrl}
                               alt="preview"/>
                        </div>
                      </div>}
                    </ImageSelector>
                  </div>
                  <div>
                    <ImageSelector
                      label={<Trans components={{ span: <span></span> }} i18nKey={'field.label.productImageOptional'}/>}
                      info={t('field.info.image')}
                      error={imageError.image1}
                      options={imageSelectorOptions}
                      onChange={onChangeImage3}
                      onError={onLoadImageErrorImage3}
                    >
                      {image3?.previewUrl && <div style={{ paddingTop: '40px' }}>
                        <div style={{margin: '0 auto', width: '120px', position: 'relative'}}>
                          <div
                            style={{
                              position: 'absolute',
                              width: '24px',
                              height: '24px',
                              top: '-12px',
                              right: '-12px',
                              zIndex: 1,
                              backgroundColor: '#151515',
                              borderRadius: '50%',
                              color: 'white',
                              textAlign: 'center',
                              cursor: 'pointer'
                            }}
                            onClick={(event) => removeProductImage('image3', event)}>x
                          </div>
                          <img style={{display: 'block', width: '120px', height: 'auto'}} src={image3.previewUrl}
                               alt="preview"/>
                        </div>
                      </div>}
                    </ImageSelector>
                  </div>
                </li>
              </ul>
            </div>
            <div className="section-action">
              {isSaving && <Loader/>}
              {!isSaving && <div>
                {!isNew && <button type="button" className="button button-danger" onClick={deleteBrand}>
                  {t('actions.delete')}
                </button>}
                <button type="submit" className="button button-success">
                  {isNew ? t("actions.add") : t('actions.saveChanges')}
                </button>
              </div>}
            </div>
          </form>
        )}
      </Formik>
    </section>
  );
}
