import { default as React, useEffect, useState } from "react";
import { Link, useMatch, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { API_URL } from "../../../constants";
import CreateOfferForm from "./CreateOfferForm";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import api from "../../../api";
import { parseApiError } from "../../../utils";
import Loader from "../../../components/Loader";
import productPlaceholder from '../../../assets/images/product-placeholder.jpeg';
import { useTranslation } from "react-i18next";

export default function BrandView() {
  const notification = useNotification();
  const params = useParams();
  const id = params.id;
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [brand, setBrand] = useState<any>(null);
  const [warehouses, setWarehouses] = useState<any>([]);
  const [file, setFile] = useState<any>('');

  const match = useMatch('/brand-view/:id/:section');
  const currentSection = match?.params.section || 'about';

  useEffect(() => {
    setIsLoading(true);
    api.get(API_URL + '/brands/' + id)
      .then((response) => {
        const data = response.data.data;
        setBrand(data);

        const companyId = data.company?.id;

        api.get(API_URL + '/companies/' + companyId + '/warehouses')
          .then((response) => {
            const data = response.data.data;
            setWarehouses(data.map((w: any) => ({
              id: w.id,
              value: w.id,
              name: w.name,
              label: w.name,
            })));
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            parseApiError(error, notification, null);
          });


        api.get(API_URL + '/brands/' + id + '/products-spreadsheet', {
            responseType: 'arraybuffer'
          })
          .then((response: any) => {
            const data = response.data;

            var bb = new Blob([data], { type: 'text/plain' });
            var file = window.URL.createObjectURL(bb);

            setFile(file);
          })
          .catch((error) => {
            console.log(error);
            parseApiError(error, notification, null);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        parseApiError(error, notification, null);
      });
  }, [id]);

  const selectProduct = (product: any) => {
    navigate(`/brand-view/${id}/products/${product.id}`);
  };

  return (
    <div>
      {isLoading && <Loader/>}

      {!isLoading && brand && currentSection === 'about' && <section className="fade-content">
        <div className="section-inner">
          <div className="section-header">
            <p>{brand.description}</p>
          </div>
          <div className="grid">
            <div className="grid-list">

              {brand.products.map((product: any) => (
                <div key={product.id}>
                  <div className="product-card" onClick={() => selectProduct(product)}>
                    <div className="slide-container">
                      {!product.images && <div className="each-slide no-image">
                        <img src={productPlaceholder} alt=""/>
                      </div>}
                      {product.images &&
                        <div className="each-slide" >
                          <img src={product.images[0]} alt=""/>
                        </div>
                      }
                    </div>
                    <div className="product-card-footer">
                      <Link to={`products/${product.id}`}>
                        {product.name}
                      </Link>
                      <div>
                        <small>
                          ${product.msrp}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>}

      {!isLoading && brand && currentSection === 'products' && <div>
        <section className="fade-content">
          <div className="view-inner">
            <div style={{textAlign: 'center'}}><strong>For more information:</strong></div>
            <div className="download-links">
              <a
                className="icon"
                style={{
                  justifyContent: 'center',
                  margin: '0 auto',
                  textAlign: 'center'
                }}
                href={file}
                target="_blank"
                download="test.xlsx"
              >

              {/*<a ng-if="$ctrl.data.brand.inventory.fileUrl" className="icon" target="_self"*/}
              {/*   href="{{ $ctrl.data.brand.inventory.fileUrl }}">*/}
                <span className="icon-link-file"></span>
                <span>
                  {t('actions.downloadSpreadsheet')}
                </span>
              </a>
              {/*/!*   <a ng-if="$ctrl.data.brand.material.url" className="icon" target="_blank"*!/*/}
              {/*/!*      href="{{ $ctrl.data.brand.material.url }}">*!/*/}
              {/*/!*    <span className="icon-link-external"></span>*!/*/}
              {/*/!*    <span>*!/*/}
              {/*/!* {t('actions.getMarketingMaterials')}*!/*/}
              {/*/!*</span>*!/*/}
              {/*</a>*/}
            </div>
            <CreateOfferForm brandId={brand.id} products={brand.products} warehouses={warehouses}/>
          </div>
        </section>
      </div>}
    </div>
  );
}
