import { useEffect, useRef, useState } from "react";

interface SelectedHandle {
  element: HTMLElement;
  name:  string;
  x: number;
  left: number;
  width: number;
}

interface SliderProps {
  skin?: string;
  lock?: string;
  integerOnly?: boolean;
  limitValues?: {
    min: number;
    max: number;
  };
  value: {
    min: number;
    max: number;
  },
  onValueChange: (value: {
    min: number;
    max: number;
  }) => void
}

export default function Slider({
 skin = '',
 lock,
 value,
 limitValues = { min: 0, max: 0 },
 integerOnly = false,
 onValueChange
}: SliderProps) {
  const sliderRef = useRef<HTMLDivElement>(null);
  const minRef = useRef<HTMLSpanElement>(null);
  const maxRef = useRef<HTMLSpanElement>(null);
  const joinRef = useRef<HTMLDivElement>(null);

  const [selectedHandle, setSelectedHandle] = useState<SelectedHandle | null>(null);

  const onDown = (e: any) => {
    e.target.style.zIndex = 5;

    setSelectedHandle({
      element: e.target,
      name: e.target.className,
      x: typeof e.touches !== 'undefined' ? e.touches[0].pageX : e.clientX,
      left: e.target.offsetLeft,
      width: e.target.offsetWidth
    });
  };

  const formatValue = function(value: number) {
    if(integerOnly) {
      return Math.round(value);
    } else if(typeof value === 'number') {
      return Math.round(value * 100) / 100;
    } else {
      return value;
    }
  };

  const updateHandle = function(resize?: boolean) {
    // if(!resize) { return false; }

    if(value && limitValues && sliderRef.current && maxRef.current && minRef.current && joinRef.current) {
      var min = Math.round(((value.min - limitValues.min) / (limitValues.max - limitValues.min)) * (sliderRef.current.clientWidth - maxRef.current.clientWidth)),
        max = Math.round(((value.max - limitValues.min) / (limitValues.max - limitValues.min)) * (sliderRef.current.clientWidth - maxRef.current.clientWidth));

      minRef.current.style.left = min + 'px';
      maxRef.current.style.left = max + 'px';

      joinRef.current.style.left = min + 'px';
      joinRef.current.style.width = '480px'//(max - min + self.maxHandle.clientWidth) + 'px';
    }
  };

  const onMove = (e: any) => {
    if(selectedHandle && sliderRef.current &&  typeof selectedHandle === 'object') {
      let x = typeof e.touches !== 'undefined' ? e.touches[0].pageX : e.clientX;

      let pos = x - selectedHandle.x + selectedHandle.left,
        limit = sliderRef.current.clientWidth - selectedHandle.width;

      if(pos < 0) {
        pos = 0;
      } else if(pos > limit) {
        pos = limit;
      }

      setTimeout(function() {
        if(selectedHandle.name === 'min' && maxRef.current) {
          if(pos >= maxRef.current.offsetLeft) {
            pos = maxRef.current.offsetLeft;
          }

          onValueChange({
            ...value,
            min: formatValue(limitValues.min + ((limitValues.max - limitValues.min) * (pos / limit)))
          });
        }

        if(selectedHandle.name === 'max' && minRef.current) {
          if(pos <= minRef.current.offsetLeft) {
            pos = minRef.current.offsetLeft;
          }

          onValueChange({
            ...value,
            max: formatValue(limitValues.min + ((limitValues.max - limitValues.min) * (pos / limit)))
          });
        }
      });
    }
  };

  const onUp = () => {
    if(minRef.current && sliderRef.current && maxRef.current &&  minRef.current.offsetLeft <= (sliderRef.current.clientWidth - maxRef.current.clientWidth) * 0.5) {
      minRef.current.style.zIndex = '3';
      maxRef.current.style.zIndex = '4';
    } else if (minRef.current && maxRef.current ) {
      minRef.current.style.zIndex = '4';
      maxRef.current.style.zIndex = '3';
    }

    setSelectedHandle(null);
  };

  useEffect(() => {
    updateHandle();
  }, [value]);

  useEffect(() => {
    switch(lock) {
      case('min'):
        minRef.current && (minRef.current.style.display = 'none');
        maxRef.current && (maxRef.current.style.display = 'block');
        break;
      case('max'):
        minRef.current && (minRef.current.style.display = 'block');
        maxRef.current && (maxRef.current.style.display = 'none');
        break;
      default:
        minRef.current && (minRef.current.style.display = 'block');
        maxRef.current && (maxRef.current.style.display = 'block');
        break;
    }

    const onMouseDown = function(e: any) {
      e.preventDefault();

      onDown(e);
    };

    const onMouseMove = function(e: any) {
      if(typeof selectedHandle === 'object' && (selectedHandle?.name === 'min' || selectedHandle?.name === 'max')) {
        onMove(e);
      }
    };

    const onMouseUp = function() {
      if(selectedHandle && (selectedHandle.name === 'min' || selectedHandle.name === 'max')) {
        onUp();
      }
    };

    const onResize = function() {
      updateHandle(true);
    };

    if (minRef.current) {
      minRef.current.addEventListener('mousedown', onMouseDown);
    }

    if (maxRef.current) {
      maxRef.current.addEventListener('mousedown', onMouseDown);
    }

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    window.addEventListener('resize', onResize);

    return () => {
      if (minRef.current) {
        minRef.current.removeEventListener('mousedown', onMouseDown);
      }
      if (maxRef.current) {
        maxRef.current.removeEventListener('mousedown', onMouseDown);
      }

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('resize', onResize);
    }
  }, [selectedHandle]);

  return (
    <div ref={sliderRef} className={`slider-wrapper ${skin}`}>
      <span ref={minRef} className="min"></span>
      <span ref={maxRef} className="max"></span>
      <div ref={joinRef}></div>
    </div>
  );
}
