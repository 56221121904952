import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Field, FieldProps } from "formik";
import { Trans, useTranslation } from "react-i18next";
import axios from "axios";

import { API_URL } from "../../constants";
import Dropdown from "../../components/Dropdown";
import TextField from "../../components/Textfield";

interface AddressFormProps {
  countries: {
    value: string;
    label: string;
  }[];
  values: {
    [key: string]: string
  },
  errors: {
    [key: string]: string
  },
  touched?: {
    [key: string]: string
  },
  name: string;
}

export default function AddressForm({ name, countries, touched, errors = {}, values }: AddressFormProps) {
  const {t} = useTranslation();

  const [regions, setRegions] = useState([]);

  useEffect(() => {
    setRegions([]);
    if (values.country) {
      axios.get(API_URL + `/countries/${values.country}/regions`)
        .then((response) => {
          setRegions(response.data.data.map((c: any) => ({
            value: c.id,
            label: c.name
          })));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [values.country]);

  const CA_ID = '1';

  return (
    <ul className="form-fieldset " style={{ padding: 0, margin: 0, border: 'none' }}>
      <li>
        <div>
          <Field name={`${name}.country`}>
            {({field}: FieldProps<any>) => (
              <Dropdown
                id={field.name}
                label={t('field.label.country')}
                options={countries}
                error={errors.country}
                touched={!!touched?.country}
                {...field}
              />
            )}
          </Field>
        </div>
        <div>
          {!!regions.length && <Field name={`${name}.province`}>
            {({field}: FieldProps<any>) => (
              <Dropdown
                id={field.name}
                label={values.country === CA_ID ? t('field.label.province') : 'State'}
                options={regions}
                error={errors.province}
                touched={!!touched?.province}
                {...field}
              />
            )}
          </Field>}
        </div>
      </li>
      <li>
        <div>
          <Field name={`${name}.city`}>
            {({field}: FieldProps<any>) => (
              <TextField
                id={field.name}
                label={t('field.label.city')}
                type="text"
                {...field}
                error={errors.city}
                touched={!!touched?.city}
              />
            )}
          </Field>
        </div>
        <div>
          <Field name={`${name}.postalCode`}>
            {({field}: FieldProps<any>) => (
              <TextField
                id={field.name}
                label={<Trans components={{span: <span></span>}} i18nKey={'field.label.postalCode'}/>}
                type="text"
                error={errors.zipCode || errors.postalCode}
                touched={!!touched?.zipCode || !!touched?.postalCode}
                {...field}
              />
            )}
          </Field>
        </div>
      </li>
      <li>
        <div>
          <Field name={`${name}.address`}>
            {({field}: FieldProps<any>) => (
              <TextField
                id={field.name}
                label={t('field.label.address')}
                type="text"
                error={errors.address}
                touched={!!touched?.address}
                {...field}
              />
            )}
          </Field>
        </div>
        <div>
        </div>
      </li>
    </ul>
  );
}
