import * as React from "react";
import { useCallback, useMemo, useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import axios from "axios";
import applyCaseMiddleware from 'axios-case-converter';

import Loader from "../../components/Loader";
import ImageSelector from "../../components/ImageSelector";
import { API_URL, configConstant } from "../../constants";
import ImageEditor from "../../components/ImageEditor";
import TextField from "../../components/Textfield";
import PhoneField from "../../components/PhoneField";
import AddressForm from "./AddressForm";
import WarehousesForm from "./WarehousesForm";
import SocialMediaForm from "./SocialMediaForm";
import { parseApiError } from "../../utils";
import { useNotification } from "../../components/NotificationAlert/useNotification";

interface CompanyFormValues {
  name: string;
  address: {
    address: string;
    country: string;
    province?: string;
    zipCode: string;
    postalCode?: string;
    city: string;
  };
  shipAddress?: {
    address: string;
    country: string;
    province?: string;
    zipCode: string;
    postalCode?: string;
    city: string;
  }
  businessPhoneNumber: string;
  phoneCountry: string;
  website: string;
  description: string;
  socialNetworks: {
    name: string;
    url: string;
  }[];
  warehouses?: {
    name: string;
    address: {
      address: string;
      country: string;
      province?: string;
      zipCode: string;
      postalCode?: string;
      city: string;
    }
  }[];
}

export default function CompanyForm({ onNext, user }: { onNext: Function, user: { type: { id: number } } }) {
  const { t } = useTranslation();
  const notification = useNotification();
  const [search] = useSearchParams();
  const userType = user?.type?.id;
  const isBayer = userType === 2;

  const [countries, setCountries] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [logoError, setLogoError] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [imageEditorOptions, setImageEditorOptions] = useState<any>(null);
  const [logo, setLogo] = useState<any>(null);

  const code = search.get('code');

  const initialValues: CompanyFormValues = {
    name: '',
    address: {
      address: '',
      country: '',
      province: '',
      zipCode: '',
      postalCode: '',
      city: '',
    },
    shipAddress: isBayer ? {
      address: '',
      country: '',
      province: '',
      zipCode: '',
      postalCode: '',
      city: '',
    } : undefined,
    warehouses: !isBayer ? [{
        name: '',
        address: {
          address: '',
          country: '',
          province: '',
          zipCode: '',
          postalCode: '',
          city: '',
        }
    }] : undefined,
    businessPhoneNumber: '',
    phoneCountry: '',
    website: '',
    description: '',
    socialNetworks: [],
  };

  const onChangeLogo = useCallback((files: any) => {
    setIsOpen(true);
    setLogoError('');
    setImageEditorOptions({
      image: files[0],
      zoom: configConstant.zoom.logo,
      upload: configConstant.upload.logo,
      crop: {}, //...
      mode: configConstant.mode.logo
    });
  }, []);

  const onSaveImage = (result: any, filename: string) => {
    setLogo({
      ...result,
      previewUrl: result.logo.url,
      filename
    });
    setIsOpen(false);
    setImageEditorOptions(null);
  };

  const onCloseImageEditor = () => {
    setIsOpen(false);
    setImageEditorOptions(null);
  };

  const onLoadLogoError = useCallback((files: any) => {
    const error = files?.[0].errors[0];
    setLogoError(`${error.code} ${error.message}`);
  }, []);

  const removeLogo = () => {
    setLogo(null);
  };

  useEffect(() => {
    axios.get(API_URL + '/countries')
      .then((response) => {
        setCountries(response.data.data.map((c: any) => ({
          value: c.id,
          label: c.name
        })))
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const onSubmit = (formValue: CompanyFormValues, { setErrors }: FormikHelpers<CompanyFormValues>) => {
    const data = {
      code: code,
      name: formValue.name,
      address: {
        country_id: formValue.address.country,
        region_id: formValue.address.province,
        city: formValue.address.city,
        postal_code: formValue.address.postalCode || formValue.address.zipCode,
        address: formValue.address.address
      },
      business_phone_number: formValue.businessPhoneNumber,
      website: formValue.website,
      description: formValue.description,
      social_networks: formValue.socialNetworks || undefined,
      ship_to_address: isBayer && formValue.shipAddress ? {
        country_id: formValue.shipAddress.country,
        region_id: formValue.shipAddress.province,
        city: formValue.shipAddress.city,
        postal_code: formValue.shipAddress.postalCode || formValue.shipAddress.zipCode,
        address: formValue.shipAddress.address
      } : undefined,
      warehouses: !isBayer && formValue.warehouses ? formValue.warehouses.map(warehouse => ({
        name: warehouse.name,
        address: {
          country_id: warehouse.address.country,
          region_id: warehouse.address.province,
          city: warehouse.address.city,
          postal_code: warehouse.address.postalCode || warehouse.address.zipCode,
          address: warehouse.address.address
        }
      })) : undefined
    };

    setIsSaving(true);

    const options = {
      caseFunctions: {
        camel: (input: any) => {
          return (input.charAt(0).toLowerCase() + input.slice(1)).replace(
            /[-_](.)/g,
            (match: any, group1: any) => group1.toUpperCase()
          );
        }
      }
    };

    const client = applyCaseMiddleware(axios.create(), options);

    client.post(API_URL + '/companies', data)
      .then(function () {
        if (!logo?.logo) {
          setIsSaving(false);
          onNext && onNext();
          return;
        }

        const data = new FormData();

        data.append('code', code || '');
        data.append('logo', logo?.logo?.blob, logo?.filename);

        axios.post(API_URL + '/companies/logo', data, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            setIsSaving(false);
            onNext && onNext();
          })
          .catch((error) => {
            parseApiError(error, notification, null);
            setIsSaving(false);
          });
      })
      .catch((error) => {
        parseApiError(error, notification, null, setErrors);
        setIsSaving(false);
      });
  };

  const logoSelectorOptions = useMemo(() => configConstant.selectorOptions.logo, []);
  const logoLabel = useMemo(() =>
    <Trans components={{span: <span></span>}} i18nKey={'field.label.logoOptional'} />, []);

  const US_ID = '2';
  const CA_ID = '1';

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(255, t('field.error.name.maxlength'))
          .required(t('field.error.name.required')),
        address: Yup.object().shape({
          country: Yup.string()
            .required(t('field.error.country.required')),
          province: Yup.string()
            .test('required', t('field.error.province.required'), function (value) {
              return this.parent.country === US_ID || this.parent.country === CA_ID ? !!value : true
            }),
          postalCode: Yup.string()
            .required(t('field.error.postalCode.required')),
          city: Yup.string()
            .max(255, t('field.error.city.maxlength'))
            .required(t('field.error.city.required')),
          address: Yup.string()
            .max(255, t('field.error.address.maxlength'))
            .required(t('field.error.address.required')),
        }),
        ...(isBayer ? {
          shipAddress: Yup.object().shape({
            country: Yup.string()
              .required(t('field.error.country.required')),
            province: Yup.string()
              .test('required', t('field.error.province.required'), function (value) {
                return this.parent.country === US_ID || this.parent.country === CA_ID ? !!value : true
              }),
            postalCode: Yup.string()
              .required(t('field.error.postalCode.required')),
            city: Yup.string()
              .max(255, t('field.error.city.maxlength'))
              .required(t('field.error.city.required')),
            address: Yup.string()
              .max(255, t('field.error.address.maxlength'))
              .required(t('field.error.address.required')),
          })
        } : {}),
        ...(!isBayer ? {
          warehouses: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string()
                  .max(255, t('field.error.name.maxlength'))
                  .required(t('field.error.name.required')),
                address:  Yup.object().shape({
                  country: Yup.string()
                    .required(t('field.error.country.required')),
                  province: Yup.string()
                    .test('required', t('field.error.province.required'), function (value) {
                      return this.parent.country === US_ID || this.parent.country === CA_ID ? !!value : true
                    }),
                  postalCode: Yup.string()
                    .required(t('field.error.postalCode.required')),
                  city: Yup.string()
                    .max(255, t('field.error.city.maxlength'))
                    .required(t('field.error.city.required')),
                  address: Yup.string()
                    .max(255, t('field.error.address.maxlength'))
                    .required(t('field.error.address.required')),
                })
              })
            )
            .min(1, t('required-field')),
        }: {}),
        businessPhoneNumber: Yup.string()
          .required(t('field.error.phone.required')),
        website: Yup.string()
          .required(t('field.error.website.required')),
        description: Yup.string()
          .required(t('field.error.description.required')),
      })}
    >
      {({values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
        <form onSubmit={handleSubmit}>
          {isOpen && <ImageEditor data={imageEditorOptions} onSave={onSaveImage} onCancel={onCloseImageEditor}/>}
          <div className="section-header">
            <h1>
              {t('activation.company.header.title')}
            </h1>
            <div>
              {t('activation.company.header.text')}
            </div>
          </div>
          <div className="section-content form">
            <ul className="form-fieldset">
              <li>
                <div>
                  <ImageSelector
                    label={logoLabel}
                    info={t('field.info.logo')}
                    error={logoError}
                    options={logoSelectorOptions}
                    onChange={onChangeLogo}
                    onError={onLoadLogoError}
                  >
                    {logo?.previewUrl && <div style={{ paddingTop: '40px' }}>
                      <div style={{ margin: '0 auto', width: '120px', position: 'relative' }}>
                        <div
                          style={{
                            position: 'absolute',
                            width: '24px',
                            height: '24px',
                            top: '-12px',
                            right: '-12px',
                            zIndex: 1,
                            backgroundColor: '#151515',
                            borderRadius: '50%',
                            color: 'white',
                            textAlign: 'center',
                            cursor: 'pointer'
                          }}
                          onClick={() => removeLogo()}>x
                        </div>
                        <img style={{display: 'block', width: '120px', height: 'auto'}} src={logo.previewUrl}
                             alt="preview"/>
                      </div>
                    </div>}
                  </ImageSelector>
                </div>
                <div></div>
              </li>
              <li>
                <div>
                  <TextField
                    id="name"
                    label={t('field.label.businessName')}
                    type="text"
                    error={errors.name}
                    value={values.name}
                    touched={touched.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div></div>
              </li>

              <AddressForm
                values={values.address}
                errors={errors.address as any}
                touched={touched.address as any}
                countries={countries}
                name="address"
              />

              <li>
                <div>
                  <PhoneField
                    id="businessPhoneNumber"
                    type="tel"
                    label={t('field.label.businessPhone')}
                    error={errors.businessPhoneNumber}
                    value={values.businessPhoneNumber}
                    touched={touched.businessPhoneNumber}
                    country={values.phoneCountry}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={countries}
                  />
                </div>
                <div>
                  <TextField
                    id="website"
                    label={<Trans components={{span: <span></span>}} i18nKey={'field.label.website'} />}
                    type="text"
                    error={errors.website}
                    value={values.website}
                    touched={touched.website}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </li>
              <li>
                <div>
                  <TextField
                    id="description"
                    label={<Trans components={{span: <span></span>}} i18nKey={'field.label.description'} />}
                    type="text"
                    error={errors.description}
                    value={values.description}
                    touched={touched.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </li>
            </ul>
            <ul className="form-fieldset">
              <li>
                <div className="field" style={{ paddingBottom: 0 }}>
                  <strong className="txt-primary">
                    Social Media
                  </strong>
                  {/*<button className="txt-primary"*/}
                  {/*        style={{ border: 'none', background: 'transparent' , cursor: 'pointer' }}>*/}
                  {/*  Same as above*/}
                  {/*</button>*/}
                </div>
              </li>
              <SocialMediaForm values={values.socialNetworks} />
            </ul>
            {isBayer && <ul className="form-fieldset">
              <li>
                <div className="field" style={{ paddingBottom: 0 }}>
                  <strong className="txt-primary">
                    <Trans i18nKey={'field.label.shipTo'}/>
                  </strong>
                  {/*<button className="txt-primary"*/}
                  {/*        style={{ border: 'none', background: 'transparent' , cursor: 'pointer' }}>*/}
                  {/*  Same as above*/}
                  {/*</button>*/}
                </div>
              </li>

              <AddressForm
                values={values.shipAddress || {}}
                errors={errors.shipAddress as any}
                touched={touched.shipAddress as any}
                countries={countries}
                name="shipAddress"
              />
            </ul>}
            {!isBayer && <ul className="form-fieldset">
              <li>
                <div className="field" style={{ paddingBottom: 0 }}>
                  <strong className="txt-primary">
                    Warehouse(s)
                  </strong>
                  {/*<button className="txt-primary"*/}
                  {/*        style={{ border: 'none', background: 'transparent' , cursor: 'pointer' }}>*/}
                  {/*  Same as above*/}
                  {/*</button>*/}
                </div>
              </li>
              <WarehousesForm
                values={values.warehouses || {}}
                errors={errors.warehouses as any}
                touched={touched.warehouses as any}
                countries={countries}
              />
            </ul>}
          </div>
          <div className="section-action">
            {isSaving && <Loader/>}
            {!isSaving && <div>
              <button type="submit" className="button button-success" onClick={() => handleSubmit()}>
                {t('button.label.activateMyAccount')}
              </button>
            </div>}
          </div>
        </form>
      )}
    </Formik>
  )
}
