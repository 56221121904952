import * as React from "react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import axios from "axios";
import * as Yup from "yup";

import Header from "../../components/Header";
import ViewLoader from "../../components/ViewLoader";
import Footer from "../../components/Footer";
import TextField from "../../components/Textfield";
import Loader from "../../components/Loader";
import { API_URL } from "../../constants";
import { parseApiError } from "../../utils";
import { useNotification } from "../../components/NotificationAlert/useNotification";

interface ResendActivationFormValues {
  email: string;
}

export default function ResendActivation() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const notification = useNotification();

  const [currentStatus, setCurrentStatus] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const initialValues = {
    email: '',
  };

  const onSubmit = (formValue: ResendActivationFormValues, { setErrors }: FormikHelpers<ResendActivationFormValues>) => {
    setIsSaving(true);

    axios.post(API_URL + '/activation/resend', formValue)
      .then( function() {
        setIsSaving(false);
        setCurrentStatus('sent');
      })
      .catch((error) => {
        setIsSaving(false);
        parseApiError(error, notification, navigate, setErrors);
      });
  };

  return (
    <div className="view-wrapper">
      <Header/>

      <ViewLoader/>

      <div className="view view-content header-margin identification">
        <div className="section-inner">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .required(t('field.error.email.required')),
            })}
          >
            {({values, errors, touched, handleSubmit, handleChange, handleBlur}) => (
              <form onSubmit={handleSubmit}>
                <section className="resend fade-content">
                  {!currentStatus && <div className="fade-content">
                    <div className="section-header">
                      <h1 className="dash dash-after dash-primary">
                        {t('identification.resend.default.header.title')}
                      </h1>
                      <div>
                        {t('identification.resend.default.header.text')}
                      </div>
                    </div>
                    <div className="section-content form">
                      <ul className="form-fieldset">
                        <li>
                          <div>
                            <TextField
                              id="email"
                              label={t('field.label.email')}
                              type="text"
                              error={errors.email}
                              value={values.email}
                              touched={touched.email}
                              pattern="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="section-action">
                      {isSaving && <Loader/>}
                      {!isSaving && <div>
                        <button type="submit" className="button button-success" onClick={() => handleSubmit()}>
                          {t('actions.resendActivationEmail')}
                        </button>
                      </div>}
                    </div>
                  </div>}
                  {currentStatus === 'sent' && <div className="fade-content">
                    <div className="section-header">
                      <h1 className="dash dash-after dash-primary">
                        {t('identification.resend.sent.header.title')}
                      </h1>
                      <div>
                        <Trans i18nKey={'identification.resend.sent.header.text'} values={{ email: values.email }} />
                      </div>
                    </div>
                  </div>}
                </section>
              </form>
            )}
          </Formik>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
