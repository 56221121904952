import { useCallback, useMemo, useState } from "react";
import { createPortal } from "react-dom";

import NotificationAlert from "./index";
import { NotificationContext } from "./NotificactionContext";

export const NotificationProvider = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [notification, setNotification] = useState(null as any);

  const show = useCallback((content: any) => {
    setIsOpen(true);
    setNotification({
      autoDismiss: content,
      currentMessage: content?.message || '',
      currentBg: 'bg-' + content.type,
    });
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
    setNotification(null);
  }, []);

  const contextValue = useMemo(() => ({ show }), [show]);

  return (
    <NotificationContext.Provider value={contextValue}>
      {props.children}

      {createPortal(
        <NotificationAlert isOpen={isOpen} notification={notification} onClose={close} />,
        document.body
      )}
    </NotificationContext.Provider>
  )
};
