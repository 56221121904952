import * as React from "react";
import { useState, useEffect } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import axios from "axios";
import { Trans, useTranslation } from "react-i18next";

import Loader from "../../../components/Loader";
import TextField from "../../../components/Textfield";
import PhoneField from "../../../components/PhoneField";
import ImageSelector from "../../../components/ImageSelector";
import { API_FILES_URL, API_URL, configConstant } from "../../../constants";
import ImageEditor from "../../../components/ImageEditor";
import api from "../../../api";
import { parseApiError } from "../../../utils";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import Select from "../../../components/Select";
import { useSelector } from "react-redux";

interface CategoryEntity {
  id: string;
  name: string;
}

interface CategoryOption {
  value: string;
  label: string;
  name: string;
}

interface ProfileFormValues {
  name: string;
  title: string;
  phoneNumber: string;
  emergencyPhoneNumber: string;
  linkedin: string;
  articleCategories: string[];
}

export default function Profile() {
  const { t } = useTranslation();
  const notification = useNotification();

  const [categories, setCategories] = useState<CategoryOption[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [avatar, setAvatar] = useState<any>(null);
  const [avatarError, setAvatarError] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [imageEditorOptions, setImageEditorOptions] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<ProfileFormValues>({
    name: '',
    title: '',
    linkedin: '',
    phoneNumber: '',
    emergencyPhoneNumber: '',
    articleCategories: []
  });

  // @ts-ignore
  const user = useSelector((state) => state.user);


  useEffect(() => {
    axios.get(API_URL + '/news/categories')
      .then((response) => {
        const categories = (response.data?.data || []).map((c: CategoryEntity) => ({
          value: c.id,
          label: c.name,
          name: c.name
        }));

        setCategories(categories);
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeAvatar = useCallback((files: any) => {
    setIsOpen(true);
    setAvatarError('');
    setImageEditorOptions({
      image: files[0],
      zoom: configConstant.zoom.avatar,
      upload: configConstant.upload.avatar,
      crop: {}, //...
      mode: configConstant.mode.avatar
    });
  }, []);

  const onLoadAvatarError = useCallback((files: any) => {
    const error = files?.[0].errors[0];
    setAvatarError(`${error.code} ${error.message}`);
  }, []);

  const removeAvatar = useCallback(() => {
    setAvatar(null);
  }, []);

  const onCloseImageEditor = useCallback(() => {
    setIsOpen(false);
    setImageEditorOptions(null);
  }, []);

  const onSaveImage = useCallback((result: any, filename: string) => {
    setAvatar({
      ...result,
      previewUrl: result.avatar.url,
      filename
    });

    setIsOpen(false);
    setImageEditorOptions(null);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    api.get(API_URL + '/accounts/my')
      .then(function (response) {
        const data = response.data?.data;

        setInitialValues({
          name: data.name,
          title: data.title || '',
          linkedin: data.linkedin || '',
          phoneNumber: data.phoneNumber,
          emergencyPhoneNumber: data.emergencyPhoneNumber,
          articleCategories: data.categories?.map(({ id }: CategoryEntity) => id) || []
        });

        if (data.avatar) {
          setAvatar({
            previewUrl: API_FILES_URL + data.avatar
          });
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        parseApiError(error, notification, null);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAvatar = useCallback(() => {
    let data = null;

    if (!avatar?.avatar?.blob && avatar?.previewUrl) {
      return Promise.resolve();
    }

    if (avatar?.avatar?.blob) {
      data = new FormData();
      data.append('avatar', avatar?.avatar?.blob, avatar?.filename);
    }

    return api.post(
      API_URL + '/accounts/my/avatar',
      data,
      {headers: {"Content-Type": "multipart/form-data"}});
  }, [avatar]);

  const onSubmit = useCallback(async (formValue: ProfileFormValues, { setErrors }: FormikHelpers<ProfileFormValues>) => {
    const data = {
      name: formValue.name,
      title: formValue.title,
      linkedin: formValue.linkedin,
      phoneNumber: formValue.phoneNumber,
      emergencyPhoneNumber: formValue.emergencyPhoneNumber,
      categories: formValue.articleCategories
    };

    setIsSaving(true);

    try {
      await saveAvatar();
    } catch (error) {
      parseApiError(error, notification, null, setErrors);
    }

    api.put(API_URL + '/accounts/my', data)
      .then(() => {
        setIsSaving(false);
        notification.show({
          type: 'success',
          message: {
            translate: 'Account profile has been successfully updated!'
          },
          autoDismiss: true
        });
      })
      .catch((error) => {
        setIsSaving(false);
        parseApiError(error, notification, null, setErrors);
      });
  }, [notification, saveAvatar]);

  const handleCancelSubscription = () => {
    api.post(API_URL + '/users/my/subscription/cancel')
      .then(() => {
        notification.show({
          type: 'success',
          message: {
            translate: 'Subscription has been successfully canceled!'
          },
          autoDismiss: true
        });
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  const avatarSelectorOptions = useMemo(() => configConstant.selectorOptions.avatar, []);

  if (isLoading) {
    return <Loader className="full-width"/>;
  }

  return (
    <>
      {isOpen && <ImageEditor data={imageEditorOptions} onSave={onSaveImage} onCancel={onCloseImageEditor}/>}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255, t('field.error.name.maxlength'))
            .required(t('field.error.name.required')),
          title: Yup.string()
            .max(255, t('field.error.title.maxlength')),
          phoneNumber: Yup.string()
            .required(t('field.error.phone.required')),
          emergencyPhoneNumber: Yup.string()
            .required(t('field.error.emergencyPhone.required')),
          linkedin: Yup.string()
            .max(255, t('field.error.linkedIn.maxlength')),
        })}
      >
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <section className="fade-content">
              <div className="section-header dash dash-after dash-primary">
                <h1>
                  {t('account.user.profile.header.title')}
                </h1>
                <div>
                  <Trans i18nKey={'account.user.profile.header.text'}/>
                </div>
              </div>
              <div className="section-content form">
                <ul className="form-fieldset">
                  <li>
                    <div>
                      <ImageSelector
                        label={<Trans components={{span: <span></span>}} i18nKey={'field.label.avatarOptional'}/>}
                        info={t('field.info.avatar')}
                        error={avatarError}
                        options={avatarSelectorOptions}
                        onChange={onChangeAvatar}
                        onError={onLoadAvatarError}
                      >
                        {avatar?.previewUrl && <div className="preview-selected-image-wrap">
                          <div className="preview-selected-image">
                            <div className="preview-selected-image-close" onClick={() => removeAvatar()}>x</div>
                            <img src={avatar.previewUrl} alt="preview"/>
                          </div>
                        </div>}
                      </ImageSelector>
                    </div>
                    <div style={{ paddingTop: '40px', textAlign: 'center' }}>
                      {user.subscription && <button type="submit" className="button" onClick={() => handleCancelSubscription()}>
                        Cancel subscription
                      </button>}
                    </div>
                  </li>
                  <li>
                    <div>
                      <TextField
                        id="name"
                        label={t('field.label.name')}
                        type="text"
                        error={errors.name}
                        value={values.name}
                        touched={touched.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div>
                      <TextField
                        id="title"
                        label={<Trans components={{span: <span></span>}} i18nKey={'field.label.titleOptional'}/>}
                        type="text"
                        error={errors.title}
                        value={values.title}
                        touched={touched.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </li>
                  <li>
                    <div>
                      <PhoneField
                        id="phoneNumber"
                        type="tel"
                        label={t('field.label.phone')}
                        error={errors.phoneNumber}
                        value={values.phoneNumber}
                        touched={touched.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div>
                      <PhoneField
                        id="emergencyPhoneNumber"
                        type="tel"
                        label={t('field.label.emergencyPhone')}
                        error={errors.emergencyPhoneNumber}
                        value={values.emergencyPhoneNumber}
                        touched={touched.emergencyPhoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </li>
                  <li>
                    <div>
                      <TextField
                        id="linkedin"
                        label={<Trans components={{span: <span></span>}} i18nKey={'field.label.linkedInOptional'}/>}
                        type="text"
                        error={errors.linkedin}
                        value={values.linkedin}
                        touched={touched.linkedin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div>
                      <Select
                        id="articleCategories"
                        name="articleCategories"
                        label={t('field.label.articleCategories')}
                        error={errors.articleCategories as string}
                        value={values.articleCategories}
                        touched={touched.articleCategories}
                        options={categories}
                        multiple
                        onChange={handleChange}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="section-action">
                {isSaving && <Loader />}
                {!isSaving && <div>
                  <button type="submit" className="button button-success"
                    onClick={() => handleSubmit()}
                  >
                    {t('actions.saveChanges')}
                  </button>
                </div>}
              </div>
            </section>
          </form>
        )}
      </Formik>
    </>
  );
}
