import * as React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Loader from "../../components/Loader";
import ImagePreloader from "../../components/ImagePreloader";
import { API_URL } from "../../constants";
import productPlaceholder from '../../assets/images/product-placeholder.jpeg';
import { parseApiError } from "../../utils";
import { useNotification } from "../../components/NotificationAlert/useNotification";

export default function SuggestedProducts({ isBrand }: { isBrand: boolean }) {
  const navigate = useNavigate();
  const notification = useNotification();

  const [products, setProducts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getProducts = () => {
    setIsLoading(true);
    axios.get(API_URL + '/products/home')
      .then((response) => {
        setProducts(response.data.data.slice(0, 4));
        setIsLoading(false);
      })
      .catch((error) => {
        parseApiError(error, notification, null);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProducts();
  }, []);

  const selectProduct = (product: any) => {
    navigate(`/brand-view/${product.brand.id}/products/${product.id}`);
  };

  return (
    <div className="block">
      <h4>{isBrand ? 'Similar' : 'Suggested'} Products</h4>

      {isLoading && <Loader className="full-width"/>}

      {!isLoading && <div className="product-grid">
        {products.map((product: any) => (
          <div key={'suggested-' + product.id}>
            <div className="product" onClick={() => selectProduct(product)}>
              <div className="product-header">
                <ImagePreloader
                  url={
                    product.images?.length ? product.images?.[0] : productPlaceholder
                  }
                />
              </div>
              <div className="product-body">
                <div className="product-name">
                  {product.name}
                </div>
                <small className="price">${product.msrp}</small>
              </div>
            </div>
          </div>
        ))}
      </div>}

      {!isLoading && <Link to="/products/suggested" className="show-more-btn">
        Show more <span className="arrow-forward-icon"></span>
      </Link>}
    </div>
  );
}
