import Header from "../../components/Header";
import ViewLoader from "../../components/ViewLoader";
import * as React from "react";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SubscriptionCancel() {
  const { t } = useTranslation();

  return (
    <div className="view-wrapper">
      <Header/>

      <ViewLoader/>

      <div className="view view-content header-margin identification">
        <div className="section-inner">

          <section className="signup fade-content">
            <div className="section-content form">
              <div className="form-fieldset">
                <div className="sr-root">
                  <div className="sr-main">
                    <h1>Welcome back</h1>
                    <div className="price-table-container">
                      <Link className="button button-success" to="/login">
                        {t('actions.login')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>

      <Footer/>
    </div>
  );
}
