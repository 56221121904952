import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";

import api from "../../../api";
import { API_FILES_URL, API_URL } from "../../../constants";
import Avatar from "../../../components/Avatar";
import { useSelector } from "react-redux";
import { parseApiError } from "../../../utils";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import Loader from "../../../components/Loader";

export default function Offers() {
  const {t} = useTranslation();
  const notification = useNotification();

  const [offers, setOffers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getOffers = () => {
    setIsLoading(true);
    api.get(API_URL + '/offers')
      .then(function (res) {
        const data = res.data.data
          .map((o: any) => ({
            ...o,
            id: o.id,
            from: o.from,
            to: o.to,
            status: o.status,
            createdAt: moment(o.createdAt).format('YYYY-MM-DD hh:mm A')
          }))
          .sort((a: any, b: any) => a.createdAt - b.createdAt ? -1 : 1);

        setOffers(data);
        setIsLoading(false);
      })
      .catch((error) => {
        parseApiError(error, notification, null);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getOffers();
  }, []);

  // @ts-ignore
  const user = useSelector((state) => state.user);
  const userType = user?.profile?.type?.id;
  const isBayer = userType === 2;

  return (
    <div>
      <section className="fade-content">
        <div className="section-header dash dash-after">
          <h1>Your offers</h1>
        </div>
        <div className="section-content no-max-width">

          {isLoading &&  <Loader />}

          <div className="data-table v-align-middle">
            {!offers.length && !isLoading && <div className="data-table-empty">
              <div>
                <span>No offers yet!</span>
              </div>
            </div>}
            {!!offers.length && !isLoading && <div className="data-table-header">
              <div style={{ backgroundColor: '#252525', color: '#fff' }}> Offers</div>
              <div className="expand" style={{ width: '50%', backgroundColor: '#252525', color: '#fff' }}>
                {!isBayer && <span>From</span>}
                {isBayer && <span>To</span>}
              </div>
              <div className="expand" style={{width: '50%', backgroundColor: '#252525', color: '#fff'}}>
                Brand
              </div>
              <div style={{ backgroundColor: '#252525', color: '#fff' }}>Date</div>
              <div style={{ backgroundColor: '#252525', color: '#fff' }}>Status</div>
              <div style={{ backgroundColor: '#252525', color: '#fff' }}>
              </div>
              <div style={{ backgroundColor: '#252525', color: '#fff' }}>
                Shipping / tracking
              </div>
              <div style={{ backgroundColor: '#252525', color: '#fff' }}>
                Payment status
              </div>
            </div>}
            {!!offers.length && offers.map((offer: any) => (<div key={offer.id}>
              <div style={{whiteSpace: 'nowrap', textAlign: 'center'}}>
                <div>
                  <span>#</span>
                  <span>
                    {offer.id}
                  </span>
                </div>
              </div>
              <div className="expand">
                {!isBayer && <div style={{display: 'flex', textAlign: 'center', justifyContent: 'flex-start'}}>
                  <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                     ng-click="$ctrl.viewEtailer(offer.from.company.id)">
                    <Avatar
                      model={{
                        name: offer.from.company.name,
                        image: offer.from.company.logo
                      }}
                      className="avatar-small"
                    />
                    <span style={{ marginLeft: '10px' }}>
                      {offer.from.company.name}
                    </span>
                  </a>
                </div>}
                {isBayer && <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                  <Avatar
                    model={{
                      name: offer.to.company.name,
                      image: offer.to.company.logo
                    }}
                    className="avatar-small"
                  />
                  <span style={{ marginLeft: '10px' }}>
                    {offer.to.company.name}
                  </span>
                </div>}
              </div>
              <div className="expand" style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                <Avatar
                  model={{
                    name: offer.to.brand.name,
                    image: offer.to.brand.mainPhoto
                  }}
                  className="avatar-small"
                />
                <span style={{ marginLeft: '10px' }}>
                  {offer.to.brand.name}
                </span>
              </div>
              <div style={{whiteSpace: 'nowrap', textAlign: 'center'}}>
                <span>
                  {offer.createdAt}
                </span>
              </div>
              <div style={{textAlign: 'center'}}>
                      <span className="badge"
                            style={{
                              padding: '8px 16px',
                              borderRadius: '16px',
                              backgroundColor: '#aaa',
                              fontSize: '12px'
                            }}>
                        {offer.status}
                      </span>
              </div>
              <div className="no-wrap">
                {offer.status === 'pending' && <Link
                  to={`${offer.id}`}
                  className="button button-outline button-small button-primary"
                  // ui-sref="brands({ selection: offer.brand, section: \'products\' })"
                >
                  {t('actions.viewOffer')}
                </Link>}
                {offer.status !== 'pending' && <Link
                  to={`${offer.id}`}
                  className="button button-outline button-small button-primary"
                >
                  {t('actions.viewOffer')}
                </Link>}
              </div>
              <div>
                {offer.shippingFile ? <a
                  className="icon"
                  style={{
                    justifyContent: 'center',
                    margin: '0 auto',
                    textAlign: 'center'
                  }}
                  href={API_FILES_URL + offer.shippingFile}
                  target="_blank"
                  download
                >
                  <span className="icon-link-file"></span>
                  <span>
                   Link
                  </span>
                </a> : 'No file'}
              </div>
              <div>
                {offer.isPaid ? 'Yes' : 'No'}
              </div>
            </div>))}
          </div>
        </div>
      </section>
    </div>
  );
}
