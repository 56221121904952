interface DropdownFieldProps {
  id: string;
  label: string;
  error?: string;
  info?: string;
  disabled?: boolean;
  touched?: boolean;
  value: string | string[];
  onChange?: Function;
  onBlur?: Function;
  onFocus?: Function;
  options: { label: string; value: string; }[];
}

export default function Dropdown({
  id,
  value,
  error,
  label,
  info,
  disabled,
  touched,
  options = [],
  onFocus,
  onChange,
  onBlur,
}: DropdownFieldProps) {
  const selection = options.find(o => o.value?.toString() === value?.toString());

  return (
    <div className={`field dropdown ${error && touched ? 'has-error' : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="dropdown-inner">
        <select
          id={id}
          value={value}
          onFocus={onFocus as any}
          onChange={onChange as any}
          onBlur={onBlur as any}
          disabled={disabled}
        >
          <option key="default" value={undefined}>
          </option>
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
        <div>{selection?.label}</div>
      </div>
      {info && !error && <small className="field-info">{info}</small>}
      {error && touched && <small className="field-error">{error}</small>}
    </div>
  );
}
