// const STATUS_INVALID_CREDENTIALS = 0;
export const STATUS_NOT_ACTIVATED = 1;
export const STATUS_DISABLED = 2;
export const STATUS_NOT_SUBSCRIBED = 3;

export const parseApiError = (res: any, notification: { show: Function }, navigate: any, setErrors?: Function) => {
  const response = res?.response?.data;
  const code = response?.code;

  console.error(res?.response);

  if (response && code && navigate) {
    switch (code) {
      case 'pendingAccount':
        navigate('/login', {state: {status: 'pending', replace: true}});
        break;
      case STATUS_NOT_ACTIVATED:
        navigate('/login', {state: {status: 'approved', replace: true}});
        break;
      case STATUS_DISABLED:
        navigate('/login', {state: {status: 'disabled', replace: true}});
        break;
      default:
        notification.show({
          type: 'danger',
          message: {
            translate: 'notification.' + code
          },
          autoDismiss: true
        });
        break;
    }
  } else if (response.message) {
    notification.show({
      type: 'danger',
      message: {
        translate: code ? 'notification.' + code : response.message
      },
      autoDismiss: true
    });
  } else {
    notification.show({
      type: 'danger',
      message: {
        translate: 'Something went wrong'
      },
      autoDismiss: true
    });
  }

  if (res?.response?.status === 401 || response.status === 401) {
    localStorage.clear();
    window.location.href = '/login';
    return;
  }

  if (response.errors && setErrors) {
    console.log(response.errors);
    setErrors(response.errors);
  }
};

// const errors = Object.entries(response.errors).map(([errKey, errors]) => {
//   let tempObject = {};
//   let container: any = tempObject;
//
//   errKey.split('.').map((k: any, i: any, values: any) => {
//     container[k] = i === values.length - 1 ? errors : ({});
//     container = container[k];
//   });
//
//   return tempObject
// }).forEach((e: any) => {
//   setErrors(e);
// });
