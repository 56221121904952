import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ImagePreloader from "../../components/ImagePreloader";
import { API_FILES_URL, API_URL } from "../../constants";
import productPlaceholder from "../../assets/images/product-placeholder.jpeg";
import api from "../../api";
import { parseApiError } from "../../utils";
import { useNotification } from "../../components/NotificationAlert/useNotification";
import Loader from "../../components/Loader";
import { Slide } from "react-slideshow-image";

export default function BrandProfile({ userBrands, userCompany }: { userBrands: any[]; userCompany: any }) {
  const {t} = useTranslation();
  const notification = useNotification();

  const [brandCounts, setBrandCounts] = useState<any>(null);
  const [brandsImages, setBrandsImages] = useState<any>(null);
  const [isLoadingBrand, setIsLoadingBrand] = useState(false);

  const loadBrandProfile = (brandId: number) => {
    return api.get(API_URL + '/brands/' + brandId).then((response: any) => response.data.data);
  };

  const loadBrandsImages = () => {
    const promises: any = [];
    userBrands.forEach((brand: any) => {
      promises.push(loadBrandProfile(brand.id));
    });

    Promise.all(promises).then((res: any) => {
      setBrandsImages(res.reduce((acc: any, next: any) => {
        return {
          ...acc,
          [next.id]: next.mainPhoto
        }
      }, {}));
    })
  };

  const loadOffersCounts = () => {
    setIsLoadingBrand(true);
    api.get(API_URL + '/offers/counts')
      .then((response) => {
        const data = response.data.data;
        setBrandCounts(data);
        setIsLoadingBrand(false);
      })
      .catch((error) => {
        parseApiError(error, notification, null);
        setIsLoadingBrand(false);
      });
  };

  useEffect(() => {
    if (userCompany?.id) {
      loadOffersCounts();
    }
  }, [userCompany]);

  useEffect(() => {
    if (userBrands.length) {
      loadBrandsImages();
    }
  }, [userBrands]);

  return (
    <div className="block">
      <h4>Profile</h4>

      {isLoadingBrand && <Loader className="full-width"/>}

      {!!userBrands.length &&
        <Slide infinite={false} duration={60000}>
          {userBrands.map((brand: any) => (
            <>
              <div className="brand" key={brand.id}>
                <div className="brand-header">
                  <ImagePreloader url={
                    brandsImages && brandsImages[brand.id] ? API_FILES_URL + '/' + brandsImages[brand.id] : productPlaceholder
                  }/>
                </div>
                <div className="brand-body">
                  <div className="brand-name">
                    {brand.name}
                  </div>
                  {userCompany && <div>
                    <small>
                      {t('general.distributedBy')}
                    </small>
                    <div className="brand-company-name">
                      {userCompany.name}
                    </div>
                  </div>}
                </div>
                <div className="brand-footer">
                  <small>
                    {brand.productsCount} Products
                  </small>
                </div>
              </div>
              <Link to="/account/brands" className="show-more-btn">
                Show more <span className="arrow-forward-icon"></span>
              </Link>
              <div className="brand-offers-counters">
                <h4>Offers</h4>
                <div className="brand-offers-count">
                  <span className="brand-offers-count-title">New:</span>
                  <span>{brand.offerCounts?.new}</span>
                </div>
                <div className="brand-offers-count">
                  <span className="brand-offers-count-title">Negotiating:</span>
                  <span>{brand.offerCounts?.negotiating}</span>
                </div>
                <div className="brand-offers-count">
                  <span className="brand-offers-count-title">Accepted:</span>
                  <span>{brand.offerCounts?.accepted}</span>
                </div>
                <div className="brand-offers-count">
                  <span className="brand-offers-count-title">Rejected:</span>
                  <span>{brand.offerCounts?.rejected}</span>
                </div>
                <div className="brand-offers-count">
                  <span className="brand-offers-count-title">Views count:</span>
                  <span>{brand.viewsCount}</span>
                </div>
              </div>
            </>
          ))}
        </Slide>
      }

      {!isLoadingBrand && userCompany && !userBrands.length && <>
        <div className="brand">
          <div className={`brand-header ${userCompany.logo ? '' : 'no-logo'}`}>
            {userCompany.logo && <ImagePreloader url={API_FILES_URL + '/' + userCompany.logo} />}
            {!userCompany.logo && <div className="logo-placeholder"></div>}
          </div>
          <div className="brand-body">
            <div className="brand-name">
              {userCompany.name}
            </div>
            <div className="brand-company-name">
              Admin
            </div>
          </div>
          <div className="brand-footer">
          </div>
        </div>
        {brandCounts &&
        <div className="brand-offers-counters">
          <h4>Offers</h4>
          <div className="brand-offers-count">
            <span className="brand-offers-count-title">New:</span>
            <span>{brandCounts?.new}</span>
          </div>
          <div className="brand-offers-count">
            <span className="brand-offers-count-title">Negotiating:</span>
            <span>{brandCounts?.negotiating}</span>
          </div>
          <div className="brand-offers-count">
            <span className="brand-offers-count-title">Accepted:</span>
            <span>{brandCounts?.accepted}</span>
          </div>
          <div className="brand-offers-count">
            <span className="brand-offers-count-title">Rejected:</span>
            <span>{brandCounts?.rejected}</span>
          </div>
          {userBrands?.[0]?.id && <div className="brand-offers-count">
            <span className="brand-offers-count-title">Views count:</span>
            <span>{userBrands?.[0]?.viewsCount}</span>
          </div>}
        </div>
        }
      </>}

    </div>
  );
}
