import * as React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import LinksImagesForm from "./LinksImagesForm";
import Select from "../../../components/Select";
import TextboxField from "../../../components/TextboxField";
import Avatar from "../../../components/Avatar";
import { API_FILES_URL } from "../../../constants";
import { useNotification } from "../../../components/NotificationAlert/useNotification";

const bytesToMegaBytes = (bytes: number) => bytes / (1024*1024);
const MAX_FILE_SIZE = 3; // Mb

export default function CreateArticleForm({
  user,
  onSubmit,
  categories,
  editArticle
}: { user: any; onSubmit: any, categories: any[]; editArticle: any }) {
  const {t} = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>({message: '', articleCategories: [], links: []});
  const notification = useNotification();

  useEffect(() => {
    if (editArticle) {
      setShowForm(true);
      setInitialValues({
        message: editArticle.text,
        articleCategories: editArticle.categories.map((a: any) => a.id),
        links: editArticle.links
      });

      if (editArticle.images) {
        const images = editArticle.images.map((img: string) => ({
          preview: API_FILES_URL + img,
        }));

        setImages(images);
      }
    }
  }, [editArticle]);

  const handleChangeImage = (e: any) => {
    if (e.target.files.length) {
      let files = Array.from(e.target.files);
      const errors: any = [];

      files = files.filter((file: any) => {
        const isValid = bytesToMegaBytes(file.size) < MAX_FILE_SIZE;

        if (!isValid) {
          errors.push('File size is more then ' + MAX_FILE_SIZE + 'Mb')
        }

        return isValid;
      });

      if (errors.length) {
        notification.show({
          type: 'danger',
          message: {
            translate: errors[0]
          },
          autoDismiss: true
        });
      }

      const newItems = files.map((file: any) => ({
        preview: URL.createObjectURL(file),
        raw: file
      }));

      setImages(state => [...state, ...newItems]);
    }
  };

  const removeImage = (index: number) => {
    if (!isNaN(index)) {
      setImages(state => {
        state.splice(index, 1);
        return [...state];
      });
    }
  };

  const onClose = () => {
    setShowForm(false);
  };

  const onShow = () => {
    setShowForm(true);
  };

  const handleSubmit = (values: any, {resetForm}: any) => {
    const allCategories = categories.map(c => c.value).filter(c => c !== 'all');

    onSubmit(
      {
        ...values,
        articleCategories: values.articleCategories.includes('all') ? allCategories : values.articleCategories
      },
      images,
      () => {
        resetForm();
        setInitialValues({message: '', articleCategories: [], links: []});
        setImages([]);
        onClose();
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({})}
    >
      {({values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue}) => (
        <div className="create-article-form">
          {showForm && <div className="article-create-header">
            <strong>Create new article</strong>
            <a className="icon" onClick={onClose}>
              Cancel
            </a>
          </div>}

          <div className="create-article-form-content">
            <Avatar className="avatar-small" model={user.profile}/>

            <ul className="form-fieldset">
              {!showForm &&
              <input className="placeholder-input" type="text" placeholder="Tap to create news" onClick={onShow}/>
              }
              {showForm && <>
                <TextboxField
                  id="message"
                  label=""
                  placeholder="Tap to create news"
                  // type="text"
                  rows={3}
                  error={errors.message as string}
                  value={values.message}
                  touched={touched.message as boolean}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <LinksImagesForm
                  images={images}
                  values={values.links}
                  handleChangeImage={handleChangeImage}
                  removeImage={removeImage}
                />

                <li>
                  <div className="article-categories">
                    <Select
                      className="article-categories-dropdown"
                      id="articleCategories"
                      name="articleCategories"
                      label={t('field.label.articleCategories')}
                      error={errors.articleCategories as string}
                      value={values.articleCategories}
                      touched={!!touched.articleCategories}
                      options={categories}
                      multiple
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </li>
                <li>
                  <div className="submit-form">
                    <button
                      type="submit"
                      className="button button-success"
                      onClick={() => handleSubmit()}
                      disabled={!values.message}
                    >
                      {t('actions.send')}
                    </button>
                  </div>
                </li>
              </>}
            </ul>
          </div>
        </div>
      )}
    </Formik>
  );
}
