import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer() {
  const {t} = useTranslation();

  const links = {
    company: [
      {
        label: 'about',
        url: '/about'
      },
      {
        label: 'contact',
        url: '/contact'
      }
    ],
      features: [
      {
        label: 'brandFeatures',
        url: '/features/brands'
      },
      {
        label: 'distributorFeatures',
        url: '/features/distributors'
      },
      {
        label: 'etailerFeatures',
        url: '/features/etailers'
      }
    ],
      support: [
      {
        label: 'terms',
        url: '/terms-of-service'
      },
      {
        label: 'privacy',
        url: '/privacy-policy'
      }
    ],
  };

  return (
    <div className="footer">
      <div className="inner">
        <div className="footer-details">
          <Link className="logo" to="/">{t('husboro.name')}</Link>
          <a className="email" href="mailto:info@husboro.com">{t('husboro.email')}</a>
          <div>
            <a className="icon icon-linkedin" href="https://www.linkedin.com/company/husboro-inc" target="_blank" rel="noreferrer">
              {t('socials.linkedin')}
            </a>
            <a className="icon icon-instagram" href="https://www.instagram.com/husboro" target="_blank" rel="noreferrer">
              {t('socials.instagram')}
            </a>
          </div>
        </div>
        <div className="footer-links">
          {Object.entries(links).map(([key, links]) => (
            <div key={key}>
              <strong>{t('footer.groups.' + key )}</strong>
              {links.map(link => (
                <Link key={link.label} className="link" to={link.url}>
                  {t('footer.links.' + link.label)}
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>
      <small>&#xa9; 2018 Husboro. All rights reserved.</small>
    </div>
  );
}
