import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isConnected: false,
    company: null,
    contact: null,
    notification: null,
    profile: null,
  },
  reducers: {
    setUserLoggedIn: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.isConnected = true;

      const data = action.payload?.data;

      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('token_expiration', moment().add(data.expires_in, 's').unix().toString());
      localStorage.setItem('refresh_token', data.refresh_token);
    },
    setCurrentUser: (state, action) => {
      const data = action.payload;

      state.isConnected = true;
      state.company = data.company;
      state.contact = data.contact;
      state.notification = data.notification;
      state.profile = data.data;
    },
    logout: (state) => {
      localStorage.clear();

      state.isConnected = false;
      state.company = null;
      state.contact = null;
      state.notification = null;
      state.profile = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserLoggedIn, setCurrentUser, logout } = userSlice.actions;

export default userSlice.reducer
