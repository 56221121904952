import { default as ReactSelect } from 'react-select'

interface Option { label: string; value: string; }

interface DropdownFieldProps {
  className?: string;
  id: string;
  name: string;
  label: string;
  error?: string;
  info?: string;
  disabled?: boolean;
  touched?: boolean;
  multiple?: boolean;
  value: string | string[];
  onChange?: Function;
  onBlur?: Function;
  onFocus?: Function;
  setFieldValue?: Function;
  options: { label: string; value: string; }[];
}

const customStyles: any = {
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: 10,
    // width: state.selectProps.width,
    // borderBottom: '1px dotted pink',
    // color: state.selectProps.menuColor,
    // padding: 20,
  }),

  control: (provided: any, { selectProps: { width }}: any) => ({
    ...provided,
    // height: '46px',
    '&:hover': {
      border: '1px solid #ddd'
    }
  }),

  // singleValue: (provided: any, state: any) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';
  //
  //   return { ...provided, opacity, transition };
  // }
};

export default function Select({
  id,
  name,
  value,
  error,
  label,
  info,
  disabled,
  touched,
  multiple,
  options = [],
  onFocus,
  setFieldValue,
  onBlur,
  className
}: DropdownFieldProps) {
  const onChange = (option: Option | Option[]) => {
    setFieldValue && setFieldValue(
      name,
      multiple
        ? (option as Option[]).map((item: Option) => item.value)
        : (option as Option).value
    );
  };

  const getValue = () => {
    if (options) {
      return multiple
        ? options.filter(option => value.indexOf(option.value) >= 0)
        : options.find(option => option.value === value);
    } else {
      return multiple ? [] : ("" as any);
    }
  };

  return (
    <div className={`field dropdown ${error && touched ? 'has-error' : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <ReactSelect
        styles={customStyles}
        id={id}
        name={name}
        isMulti={multiple}
        value={getValue()}
        options={options}
        onFocus={onFocus as any}
        onChange={onChange as any}
        onBlur={onBlur as any}
        isDisabled={disabled}
        tabIndex={0}
        className={className}
      />
      {info && !error && <small className="field-info">{info}</small>}
      {error && touched && <small className="field-error">{error}</small>}
    </div>
  );
}
