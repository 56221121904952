import useFormProperty from "./useFormProperty";
import * as React from "react";
import AddressForm from "./AddressForm";
import { Field, FieldProps } from "formik";
import TextField from "../../components/Textfield";
import { useTranslation } from "react-i18next";

interface WarehousesFormProps {
  countries: {
    value: string;
    label: string;
  }[];
  values: any,
  errors?: {
    [key: string]: any
  },
  touched?: {
    [key: string]: any
  },
}

export default function WarehousesForm({ countries, values, errors, touched }: WarehousesFormProps) {
  const { properties, addNewProperty, removeProperty } = useFormProperty('warehouses');
  const { t } = useTranslation();

  return (
    <ul className="form-fieldset form-fieldset-warehouses" style={{ padding: 0, margin: 0, border: 'none' }}>
      {properties && properties.map((warehouse: any, index) => (
        <li key={index}>
          <div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <button
                className="button button-primary button-outline"
                onClick={() => removeProperty(index)}
                style={{ padding: 0, border: 'none' }}
              >
                Remove
              </button>
            </div>
            <Field name={`warehouses[${index}].name`}>
              {({ field }: FieldProps<any>) => (
                <TextField
                  id={field.name}
                  label={t('field.label.warehouseName')}
                  type="text"
                  {...field}
                  error={errors?.[index]?.name as any}
                  touched={touched?.[index]?.name as any}
                />
              )}
            </Field>
            <AddressForm
              values={values[index].address || {}}
              errors={errors?.[index]?.address as any}
              touched={touched?.[index]?.address as any}
              countries={countries}
              name={`warehouses[${index}].address`}
            />
          </div>
        </li>
      ))}

      <li>
        <div>
          <button className="button button-primary button-outline"
                  onClick={addNewProperty}
                  style={{ padding: 0, border: 'none' }}>
            Add Warehouse
          </button>
        </div>
      </li>
    </ul>
  )
}
