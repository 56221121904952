import { default as React, memo, ReactNode, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

export interface FileError { code: string; message: string; }

interface ImageSelectorProps {
  label?: string | ReactNode;
  info?: string;
  error?: string;
  children?: any;
  onChange?: (data: File[]) => void;
  onError?: (errors: FileError[]) => void;
  options: {
    accept?: string;
    multiple?: boolean;
    maxFiles?: number;
    validate?: any;
  }
}

const imgTypes = ['.jpeg', '.jpg', '.png'];

function ImageSelector({ label, info, error, options, onChange, onError, children }: ImageSelectorProps) {
  const { getRootProps, getInputProps, acceptedFiles, isDragActive, fileRejections } = useDropzone({
    accept: {
      'image/*': options.accept ? options.accept.split(',').filter(a => imgTypes.includes(a)) : []
    },
    multiple: options.multiple,
    maxFiles: options.maxFiles,
  });

  useEffect(() => {
    if (typeof onChange === 'function' && acceptedFiles.length) {
      onChange && onChange(acceptedFiles);
    }
  }, [acceptedFiles, onChange]);

  useEffect(() => {
    if (typeof onError === 'function' && fileRejections.length) {
      const fileErrors = fileRejections.flatMap(f => f.errors);
      onError && onError(fileErrors);
    }
  }, [fileRejections, onError]);

  return (
    <div className={`field image-selector ${error ? 'has-error' : ''}`}>
      {label && <label>{label}</label>}

      <div {...getRootProps({ className: `image-selector-inner ${isDragActive ? 'drag-over' : ''}` })}>
        <input className="input-zone" {...getInputProps()} />
        <div className="image-selector-preview">
          {children}
        </div>
        <div className="image-selector-trigger">
          <div className="dropzone-content">
            <Trans i18nKey={'general.dropImage'}
                   components={{
                     link1: <Link to="/terms-of-service"/>,
                     sp: <span className="txt-primary"></span>
                   }}
                   values={{ count: 1 }}
            />
          </div>
        </div>
      </div>
      {info && !error && <small className="field-info">{info}</small>}
      {error && <small className="field-error">{error}</small>}
    </div>
  );
}

export default memo(ImageSelector);
