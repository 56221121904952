import { useTranslation } from "react-i18next";
import TextField from "../../../../components/Textfield";
import { default as React } from "react";

export default function ReviewOfferModal({ values, onClose, confirmOffer = () => {} }: any) {
  const {t} = useTranslation();

  // todo selected
  const products = values.products.filter((p: any) => p.selected);
  const offer = values.offer;

  return (
    <div className="modal modal-review-offer" style={{overflow: "scroll"}}>
      <div className="modal-window">
        <div className="modal-window-header">
          <strong>
            Review offer
          </strong>
          <a className="icon" onClick={onClose}>
            <i className="icon-modal-close"/>
          </a>
        </div>

        {/*<div ng-if="$ctrl.hasNotification"*/}
        {/*     className="modal-notification bg-{{ $ctrl.data.notification.type }}"*/}
        {/*     // translate="{{ $ctrl.data.notification.translate }}"*/}
        {/*     translate-values="{{ $ctrl.data.notification.translateValues }}">*/}
        {/*</div>*/}

        <div className="modal-window-body" style={{padding: '30px 0'}}>
          <span>
            Please review your offer before sending it.
          </span>
          <div className="column">
            <div className="column-left">
              <div className="section-content" style={{padding: 'padding: 20px 0 0 0'}}>
                <div className="offer-card">
                  <div className="offer-card-body">
                    <div className="row row-header">
                      <div className="col col-product">Products</div>
                      <div className="col col-qty">Inventory</div>
                      <div className="col col-msrp">MSRP<br/>(Selling price)</div>
                      <div className="col col-wholesale">Wholesale<br/>(Make your offer)</div>
                    </div>
                    {products.map((product: any) => (
                      <div key={product.id}>
                        <div className="row" style={{borderTop: '3px solid #ddd'}}>
                        <div className="col col-product">
                          <small>
                            {product.sku}
                          </small>
                          <strong>
                            {product.name}
                          </strong>
                        </div>
                        <div className="cols">
                          <div className="row">
                            <div className="col col-details"><strong>Product inventory</strong></div>
                            <div className="col col-qty">
                              <strong>
                                {product.inventory}
                              </strong>
                            </div>
                            <div className="col col-msrp">
                              <strong>
                                ${product.msrp}
                              </strong>
                            </div>
                            <div className="col col-wholesale">
                              <strong>
                                ${product.wholesale}
                              </strong>
                            </div>
                          </div>
                          <div className="row txt-grey-dark">
                            <div className="col col-details">
                              <div style={{paddingLeft: '10px'}}>
                                <strong>Your offer</strong>
                              </div>
                            </div>
                            <div className="col col-qty">
                              <strong>
                                {product.offer?.quantity}
                              </strong>
                            </div>
                            <div className="col col-msrp">
                              <strong>
                                ${product.offer?.msrp}
                              </strong>
                            </div>
                            <div className="col col-wholesale">
                              <strong>
                                ${product.offer?.wholesale}
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="column-right">
              <div>
                <div className="offer-summary" style={{borderColor: '#ddd'}}>
                  <ul className="offer-summary-body">
                    {/* todo check if its needed  */}
                    {/*<li ng-if="$ctrl.data.isHusboro">*/}
                    {/*  <span className="expand">On behalf</span>*/}
                    {/*  <strong ng-bind-html="$ctrl.data.offer.etailerName"></strong>*/}
                    {/*</li>*/}
                    <li>
                      <span className="expand">Offer type</span>
                      {offer.type === 'direct_po' && <strong>Direct PO</strong>}
                      {offer.type === 'reserve' && <strong>Reserve Inventory</strong>}
                      {offer.type === 'consignment' && <strong>Consignment</strong>}
                    </li>
                    {offer.type === 'reserve' && <li>
                      <span className="expand">Launch date</span>
                      <strong>
                        {offer.launchDate}
                      </strong>
                    </li>}
                    {offer.type === 'reserve' && <li>
                      <span className="expand">End date</span>
                      <strong>
                        {offer.endDate}
                      </strong>
                    </li>}
                    <li>
                      <span className="expand">Shipping lead time</span>
                      <strong>
                        {`${offer.lead || ''} day(s)`}
                      </strong>
                    </li>
                    <li>
                      <span className="expand">Payment Terms</span>
                      <strong>
                        {`${offer.paymentTerms || ''} day(s)`}
                      </strong>
                    </li>
                    <li style={{flexDirection: 'column'}}>
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span className="expand">
                          {t('offer.shipping')}
                        </span>
                        {offer.includeShipping && <strong>Included</strong>}
                        {!offer.includeShipping && <strong>Not included</strong>}
                      </div>
                      <div style={{textAlign: 'right', paddingTop: '8px'}}>
                        {offer.includeShipping && <small style={{color: '#444'}}>
                          Brand paying for shipping (excluded from Wholesale price).
                        </small>}
                        {!offer.includeShipping && <small style={{color: '#444'}}>
                          Buyer/Retailer paying for shipping (included in Wholesale price).
                        </small>}
                      </div>
                    </li>
                    {offer.shipFrom && <li>
                      <span className="expand">Ship From (Warehouse)</span>
                      <strong>
                        {offer.shipFrom}
                      </strong>
                    </li>}
                    <li>
                      <strong className="expand">
                        {t('offer.total')}
                      </strong>
                      <strong>
                        ${offer.total}
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div style={{
            padding: '8px',
            borderTop: '1px solid #eee',
            borderBottom: '1px solid #eee',
            display: 'flex',
            justifyContent: 'center'
          }}>
            <div style={{margin: '8px', width: '100%', maxWidth: '480px', textAlign: 'left'}}>
              {/*<textfield*/}
              {/*data-name="message"*/}
              {/*ui-id="message"*/}
              {/*ui-type="text"*/}
              {/*ui-label="Message"*/}
              {/*ng-model="$ctrl.data.message"*/}
              {/*ng-model-options="$ctrl.data.modelOptions.default">*/}
              {/*</textfield>*/}

              <TextField
                id="offer.message"
                label="Message"
                type="text"
                // error={errors.offer.endDate}
                value={values.offer.message}
                // touched={touched.offer.endDate}
                // onChange={handleChange}
                // onBlur={handleBlur}
              />
            </div>
          </div>
        </div>
        <div className="modal-window-footer">
          <button type="button" className="button" onClick={onClose}>
            <span>
               {t('actions.cancel')}
            </span>
          </button>
          <button type="button" className="button button-primary" onClick={confirmOffer}>
            <span>
                {t('actions.sendOffer')}
            </span>
          </button>
        </div>

      </div>
    </div>
  );
}
