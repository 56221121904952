import * as React from "react";
import { Trans, useTranslation } from "react-i18next";

export default function EtailersFeature() {
  const {t} = useTranslation();

  const keypoints = [
    'profile',
    'find',
    'business'
  ];

  return (
    <div className="features-details">
      <div className="section-inner">
        <div className="section-header">
          <h1 className="dash dash-after">
            {t('features.etailers.title')}
          </h1>
          <div>
            <Trans i18nKey={'features.etailers.text'} />
          </div>
        </div>
        <div className="section-content">
          <div className="keypoints">
            {keypoints.map(keypoint => (
              <div key={keypoint}>
                <span className={`icon icon-keypoints-${keypoint}`}></span>
                <strong>
                  {t('features.etailers.keypoints.' + keypoint + '.title')}
                </strong>
                <span>
                  {t('features.etailers.keypoints.' + keypoint + '.text')}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
