import { default as React, useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import axios from "axios";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import Header from "../../components/Header";
import ViewLoader from "../../components/ViewLoader";
import Footer from "../../components/Footer";
import StepBar from "../../components/StepBar";
import ProfileForm from "./ProfileForm";
import CompanyForm from "./CompanyForm";
import { API_URL } from "../../constants";
import Loader from "../../components/Loader";

interface Steps {
  [key: string]: Step
}

interface Step {
  translate: string;
  status: string;
}

interface User {
  name: string;
  type: {
    id: number;
  }
}

export default function Activation() {
  const {t} = useTranslation();
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const [currentSection, setCurrentSection] = useState('approved');
  const [isFirstMember, setIsFirstMember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasProfileAccount, setHasProfileAccount] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [codeError, setCodeError] = useState('');
  const [user, setUser] = useState<User | null>(null);
  const code = search.get('code');

  useEffect(() => {
    setCodeError('');
    setIsLoading(true);
    axios.get(API_URL + `/activation/check?code=${code}`)
      .then((response) => {
        const userData = response.data.data;

        setUser(userData);
        setIsFirstMember(userData.is_first_member);
        setHasProfileAccount(!!userData.account);
        setIsActivated(!!userData.activated_at);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);

        if (error.response.data.errors.code) {
          setCodeError(error.response.data.errors.code);
        }
      });
  }, []);

  const goToNextStep = useCallback((step: string) => {
    setCurrentSection(step);
  }, []);

  useEffect(() => {
    if (hasProfileAccount) {
      goToNextStep('company');
    }
  }, [hasProfileAccount, goToNextStep]);

  const steps = useMemo(() => {
    const steps: Steps  = isActivated ? {} : {
      approved: {
        translate: 'activation.steps.approved',
        status: 'pending'
      },
      profile: {
        translate: 'activation.steps.profile',
        status: 'pending'
      }
    };

    if (!isActivated && isFirstMember) {
      steps.company = {
        translate: 'activation.steps.company',
          status: 'pending'
      }
    }

    return steps;
  }, [isFirstMember, isActivated]);

  const finishActivation = () => {
    navigate('/account/dashboard');
  };

  return (
    <div className="view-wrapper">
      <Header />

      <ViewLoader/>

      <div className="view view-content header-margin activation">
        {isLoading && <Loader className="full-width"/>}
        {!isLoading && codeError && <h1 style={{ textAlign: 'center', padding: '80px' }}>
          {codeError}
        </h1>}
        {!isLoading && !codeError && !isActivated && <StepBar steps={steps} active={currentSection}/>}
        {!isLoading && !codeError && isActivated && <div className="section-inner">
          <div className="section-header">
            <h1>
              {t('activation.activated.header.title', {name: user?.name})}
            </h1>
            <div>
              <Trans i18nKey={'activation.activated.header.text'}/>
            </div>
          </div>
          <div className="section-content">
            <Link className="button gold" to="/login">
              {t('actions.login')}
            </Link>
          </div>
        </div>}
        {!isLoading && !codeError && !isActivated && <div className="section-inner">
          {currentSection === 'approved' && <section className="fade-content">
            <div className="section-header">
              <h1>
                {t('activation.approved.header.title', {name: user?.name})}
              </h1>
              <div>
                <Trans i18nKey={'activation.approved.header.text'}/>
              </div>
            </div>
            <div className="section-content">
              <button className="button button-success" onClick={() => goToNextStep('profile')}>
                {t('button.label.nextStep')}
              </button>
            </div>
          </section>}
          {currentSection === 'profile' && user && <section className="fade-content">
            <ProfileForm userData={user} onNext={() => isFirstMember ? goToNextStep('company') : finishActivation()}/>
          </section>}
          {currentSection === 'company' && user && <section className="fade-content">
            <CompanyForm user={user} onNext={() => finishActivation()} />
          </section>}
        </div>}
      </div>

      <Footer/>
    </div>
  );
}
