import { Trans, useTranslation } from "react-i18next";
import { default as React, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Avatar from "../../../components/Avatar";
import api from "../../../api";
import { API_URL } from "../../../constants";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import { parseApiError } from "../../../utils";

import './members.css';

export default function CompanyMembers() {
  const {t} = useTranslation();
  const notification = useNotification();

  const [companeMembers, setCompanyMembers] = useState<any[]>([]);
  const [invitedMembers, setInvitedMembers] = useState<any[]>([]);
  const [requestedMembers, setRequestedMembers] = useState<any[]>([]);

  const loadMembers = () => {
    api.get(API_URL + '/users/my/members')
      .then((response) => {
        const data = response.data.data;

        setCompanyMembers(data.map((member: any) => ({
          name: member.name,
          email: member.email,
          id: member.id,
        })));
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  const loadInvitations = () => {
    api.get(API_URL + '/invitations/invited')
      .then((response) => {
        const data = response.data.data;

        setInvitedMembers(data.map((member: any) => ({
          name: member.name,
          email: member.email,
          id: member.id,
        })));
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  const getInviteRequests = () => {
    api.get(API_URL + '/invitations/requesting')
      .then((response) => {
        const data = response.data.data;

        setRequestedMembers(data.map((member: any) => ({
          name: member.name,
          email: member.email,
          id: member.id,
        })));
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  useEffect(() => {
    loadMembers();
    loadInvitations();
    getInviteRequests();
  }, []);

  const resendInvite = (id: string) => {
    api.post(API_URL + `/invitations/${id}/resend`)
      .then(() => {
        notification.show({
          type: 'success',
          message: {
            translate: 'Invite has been successfully resend!'
          },
          autoDismiss: true
        });
        loadInvitations();
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  const deleteInvite = (id: string) => {
    api.delete(API_URL + `/invitations/${id}`)
      .then(() => {
        notification.show({
          type: 'success',
          message: {
            translate: 'Invite has been successfully deleted!'
          },
          autoDismiss: true
        });
        loadInvitations();
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  const approveRequest = (id: string) => {
    api.post(API_URL + `/invitations/${id}/approve`)
      .then(() => {
        notification.show({
          type: 'success',
          message: {
            translate: 'Request has been approved!'
          },
          autoDismiss: true
        });

        getInviteRequests();
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  const declineRequest = (id: string) => {
    api.post(API_URL + `/invitations/${id}/decline`)
      .then(() => {
        notification.show({
          type: 'success',
          message: {
            translate: 'Request has been declined!'
          },
          autoDismiss: true
        });
        getInviteRequests();
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  const deleteUser = (id: string) => {
    api.delete(API_URL + `/users/${id}`)
      .then(() => {
        notification.show({
          type: 'success',
          message: {
            translate: 'User has been deleted!'
          },
          autoDismiss: true
        });
        loadMembers();
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  return (
    <section className="fade-content account-company">
      <div className="section-header dash dash-after dash-primary">
        <h1>
          {t('account.company.members.header.title')}
        </h1>
        <div>
          <Trans i18nKey={'account.company.members.header.text'}/>
        </div>
      </div>

      <div className="section-header">
        <h2>
          Company Members
        </h2>
      </div>

      <div className="section-content member-grid">
        {companeMembers.map(member => (
          <div key={member.name} className="member-card">
            <div className="member-card-body">
              <Avatar model={member} className="avatar-large" />
              <strong>{member.name}</strong>
              <span>{member.position}</span>
              <a className="ellipsis" href={`mailto:${member.email}`}>
                {member.email}
              </a>
              <div className="buttons-block">
                <button className="button button-danger" onClick={() => deleteUser(member.id)}>
                  Delete user
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>


      <div className="section-header">
        <h2>
          Invited Members
        </h2>
      </div>

      {!invitedMembers.length && <div className="section-content">
        <Link to="invite" className="button button-primary">
          Invite
        </Link>
      </div>}

      <div className="section-content member-grid">
        {!!invitedMembers.length && <div>
          <Link to="invite" className="warehouse-card warehouse-new-card">
            <span className="icon-64-plus"></span>
            <strong>
              Invite
            </strong>
          </Link>
        </div>}

        {invitedMembers.map(member => (
          <div key={member.name} className="member-card">
            <div className="member-card-body">
              <Avatar model={member} className="avatar-large" />
              <strong>{member.name}</strong>
              <span>{member.position}</span>
              <a className="ellipsis" href={`mailto:${member.email}`}>
                {member.email}
              </a>
              <div className="buttons-block">
                <button className="button button-danger" onClick={() => deleteInvite(member.id)}>
                  Delete invite
                </button>
                <button className="button button-primary button-outline" onClick={() => resendInvite(member.id)}>
                  Resend invite
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="section-header">
        <h2>
          Requested Members
        </h2>
      </div>

      <div className="section-content member-grid">
        {requestedMembers.map(member => (
          <div key={member.name} className="member-card">
            <div className="member-card-body">
              <Avatar model={member} className="avatar-large" />
              <strong>{member.name}</strong>
              <span>{member.position}</span>
              <a className="ellipsis" href={`mailto:${member.email}`}>
                {member.email}
              </a>
              <div className="buttons-block">
                <button className="button button-danger" onClick={() => declineRequest(member.id)}>
                  Decline request
                </button>
                <button className="button button-primary button-outline" onClick={() => approveRequest(member.id)}>
                  Approve request
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

    </section>
  );
}
