import { default as React, useEffect, useState } from "react";
import { Outlet, useMatch, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "../../../components/Header";
import ViewLoader from "../../../components/ViewLoader";
import Footer from "../../../components/Footer";

import './index.css';
import Avatar from "../../../components/Avatar";
import ImagePreloader from "../../../components/ImagePreloader";
import { API_FILES_URL, API_URL } from "../../../constants";
import TabBar from "../../../components/TabBar";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import api from "../../../api";
import { parseApiError } from "../../../utils";
import Loader from "../../../components/Loader";
import { useSelector } from "react-redux";

export default function BrandPage() {
  const {t} = useTranslation();
  const notification = useNotification();
  const params = useParams();
  const id = params.id;

  const [isLoading, setIsLoading] = useState(false);
  const [brand, setBrand] = useState<any>(null);

  const match = useMatch('/brand-view/:id/:section');
  const currentSection = match?.params.section || 'about';
  const user = useSelector((state: any) => state.user);
  const userType = user?.profile?.type?.id;
  const isBuyer = userType === 2;

  useEffect(() => {
    setIsLoading(true);
    api.get(API_URL + '/brands/' + id)
      .then((response) => {
        const data = response.data.data;
        setBrand(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        parseApiError(error, notification, null);
      });
  }, [id]);

  const tabs = [
    {
      translate: 'profile.brand.tabs.products',
      url: '',
      section: 'about'
    },
    ...(isBuyer ? [{
      translate: 'Make Offer',
      url: 'products',
      section: 'products'
    }] : [])
  ];

  return (
    <div className="view-wrapper">
      <Header/>
      <ViewLoader/>

      <div className="view view-content header-margin brands">
        {!brand || isLoading && <Loader/>}

        {brand && !isLoading && <section className={`hero ${brand?.profileCover ? 'hero-cover' : ''}`}>
          <div className={`hero-overlay ${brand?.additionalPhotos.length ? 'hero-overlay-expand' : ''}`}>
            <div className="brand">
              <div className="brand-info">
                {brand?.mainPhoto &&
                <Avatar model={{image: brand.mainPhoto, name: brand.name}} className="avatar-xlarge"/>}
                <div>
                  <h1>
                    {brand.name}
                  </h1>
                  {brand.company && <div className="brand-distributor">
                    <small>
                      {t('general.distributedBy')}
                    </small>
                    <span>
                      {brand.company.name}
                    </span>
                  </div>}
                </div>
              </div>
              <div className="brand-actions">
                {brand.website &&
                <a target="_blank" className="button button-small button-grey-lighter" href={brand.website}>
          <span>
           {t('button.label.visitWebsite')}
          </span>
                </a>}
              </div>
            </div>
            {!!brand?.additionalPhotos.length && <div className="brand-images">
              <div className="swiper-container">
                <div className="swiper-wrapper">
                  {brand.additionalPhotos.map((image: string) => (
                    <div key={image} className="swiper-slide">
                      <ImagePreloader url={API_FILES_URL + image}/>
                    </div>
                  ))}
                </div>
              </div>
            </div>}
          </div>
          {brand?.profileCover &&
            <div className="section-bg loaded"
                 style={{backgroundImage: `url(${API_FILES_URL + brand.profileCover})`}}
                 bg-preload
            ></div>
          }
        </section>}

        <TabBar tabs={tabs} active={currentSection}/>

        <Outlet/>

      </div>

      <Footer/>
    </div>
  );
}
