import * as React from "react";
import { useEffect, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";
import axios from "axios";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import ViewLoader from "../../components/../components/ViewLoader";
import TextField from "../../components/Textfield";
import ToggleField from "../../components/ToggleField";
import { parseApiError } from "../../utils";
import { useNotification } from "../../components/NotificationAlert/useNotification";
import { API_URL, SUBSCRIPTION_API_URL } from "../../constants";

import './index.css';

const passwordPattern = /^[A-Za-z\d@$!%*#?&\._]{6,}$/;

interface UserType {
  id: number;
  name: string;
}

interface SignUpFormValues {
  type: string;
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  cardId?: string;
}

const BUYER_TYPE_ID = '2';

export default function SignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notification = useNotification();
  const [search] = useSearchParams();

  const [types, setTypes] = useState([]);
  const [currentStatus, setCurrentStatus] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvited, setIsInvited] = useState(false);
  const [codeError, setCodeError] = useState('');
  const [initialValues, setInitialValues] = useState<SignUpFormValues>({
    type: '',
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [showSubscriptionPlan, setShowSubscriptionPlan] = useState(false);
  const [userId, setUserId] = useState('');

  const invitationCode = search.get('code');

  useEffect(() => {
    axios.get(API_URL + '/users/types')
      .then((response) => {
        setTypes(response.data.data.map((type: UserType) => ({
          value: type.id,
          label: type.name
        })));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (invitationCode) {
      setCodeError('');
      setIsLoading(true);

      axios.get(API_URL + `/invitations/check?code=${invitationCode}`)
        .then((response) => {
          const user = response.data.data;

          setInitialValues(state => ({
            ...state,
              type: user?.type?.id || '',
              name: user?.name || '',
              email: user?.email || '',
          }));
          setIsInvited(user.status === "invited");
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);

          if (error.response.data.errors.code) {
            setCodeError(error.response.data.errors.code);
          }
        });
    }
  }, [invitationCode]);

  const onSubmit = async (formValue: SignUpFormValues, { setErrors }: FormikHelpers<SignUpFormValues>) => {
    let data = {
      code: invitationCode || undefined,
      type_id: formValue.type,
      name: formValue.name,
      email: formValue.email,
      password: formValue.password,
      password_confirmation: formValue.confirmPassword
    };

    setIsSaving(true);

    axios.post(API_URL + '/users', data)
      .then(function (response) {
        setCurrentStatus(response?.data?.status || 'pending');
        setIsSaving(false);

        if (formValue.type.toString() === BUYER_TYPE_ID && response?.data?.data?.is_first_member) {
          setUserId(response?.data?.data?.id);
          setShowSubscriptionPlan(true);
        }
      })
      .catch((error) => {
        parseApiError(error, notification, navigate, setErrors);
        setIsSaving(false);
      });
  };

  const SUBSCRIPRION_COST = 599;

  return (
    <div className="view-wrapper">
      <Header/>

      <ViewLoader/>

      <div className="view view-content header-margin identification">
        <div className="section-inner">

          {isLoading && <Loader className="full-width"/>}

          {!isLoading && codeError && <h1 style={{ textAlign: 'center', padding: '80px' }}>
            {codeError}
          </h1>}

          {!isLoading && !codeError && !showSubscriptionPlan && <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              type: Yup.string()
                .required(t('field.error.companyType.required')),
              name: Yup.string()
                .max(255, t('field.error.name.maxlength'))
                .required(t('field.error.name.required')),
              email: Yup.string()
                .email(t('field.error.email.pattern'))
                .required(t('field.error.email.required')),
              password: Yup.string()
                .matches(passwordPattern, t('field.error.password.pattern'))
                .required(t('field.error.password.required')),
              confirmPassword: Yup.string()
                .test('passwords-match', t('field.error.confirmPassword.match'), function (value) {
                  return this.parent.password === value
                })
                .required(t('field.error.confirmPassword.required'))
            })}
          >
            {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
              <form onSubmit={handleSubmit}>
                <section className="signup fade-content">
                  {!currentStatus && <div className="fade-content">
                    <div className="section-header">
                      <h1 className="dash dash-after dash-primary">
                        {t('identification.signup.default.header.title')}
                      </h1>
                      <div>
                        <p>
                        <span>
                          {t('identification.signup.default.header.text')}&nbsp;
                        </span>
                          <Link to="/login">
                            {t('actions.login')}
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="section-content form">
                      <ul className="form-fieldset">
                        {!isInvited && <li>
                          <div>
                            <ToggleField
                              id="type"
                              label={t('field.label.iAm')}
                              info={
                                <Trans shouldUnescape
                                       t={t}
                                       components={{
                                         link1: <Link to="/features"/>,
                                       }}
                                       i18nKey={'field.info.companyType'}
                                />
                              }
                              options={types}
                              error={errors.type}
                              value={values.type}
                              touched={touched.type}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>}
                        <li>
                          <div>
                            <TextField
                              id="name"
                              label={t('field.label.yourName')}
                              type="text"
                              error={errors.name}
                              value={values.name}
                              touched={touched.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div>
                            <TextField
                              id="email"
                              label={t('field.label.email')}
                              type="text"
                              error={errors.email}
                              value={values.email}
                              touched={touched.email}
                              pattern="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div>
                            <TextField
                              id="password"
                              label={t('field.label.password')}
                              type="password"
                              error={errors.password}
                              value={values.password}
                              touched={touched.password}
                              pattern="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                        <li>
                          <div>
                            <TextField
                              id="confirmPassword"
                              label={t('field.label.confirmNewPassword')}
                              type="password"
                              error={errors.confirmPassword}
                              value={values.confirmPassword}
                              touched={touched.confirmPassword}
                              pattern="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="section-content">
                      <p>
                        <Trans shouldUnescape
                               t={t}
                               components={{
                                 link1: <Link to="/terms-of-service"/>,
                                 link2: <Link to="/privacy-policy"/>
                               }}
                               i18nKey={'identification.signup.default.agreement'}
                        />
                      </p>
                    </div>
                    <div className="section-action">
                      {isSaving && <Loader/>}
                      {!isSaving && <div>
                        <button type="submit"
                                className="button button-success"
                                onClick={() => handleSubmit()}
                        >
                          {t('actions.signup')}
                        </button>
                      </div>}
                    </div>
                  </div>}
                  {currentStatus === 'pending' && <div className="fade-content">
                    <div className="section-header">
                      <h1>
                        {t('identification.signup.pending.header.title')}
                      </h1>
                      <div>
                        <Trans values={{ email: values.email }} i18nKey={'identification.signup.pending.header.text'} />
                      </div>
                    </div>
                  </div>}
                  {currentStatus === 'approved' && <div className="fade-content">
                    <div className="section-header">
                      <h1>
                        {t('identification.signup.approved.header.title')}
                      </h1>
                      <div>
                        <Trans values={{ email: values.email }} i18nKey={'identification.signup.approved.header.text'} />
                      </div>
                    </div>
                  </div>}
                </section>
              </form>
            )}
          </Formik>}

          {showSubscriptionPlan && <section className="signup fade-content">
            <div className="section-content form">
              <div className="form-fieldset">
                <div className="sr-root">
                  <div className="sr-main">
                    <h1>Collaboration plan</h1>
                    <div className="price-table-container">
                      <section>
                        <form action={`${SUBSCRIPTION_API_URL}/subscription/${userId}/checkout`} method="GET">
                          <div className="name">7 days Trial </div>
                          <div className="price">${SUBSCRIPRION_COST}</div>
                          <div className="duration">per year</div>
                          <button id="basic-plan-btn" className="button button-success">Checkout</button>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>}

        </div>
      </div>

      <Footer/>
    </div>
  );
}
