import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import api from "../../api";
import { API_URL } from "../../constants";
import { useSearchParams } from "react-router-dom";

interface CategoryEntity {
  id: string;
  name: string;
}

interface CategoryOption {
  value: string;
  label: string;
  name: string;
  id: string;
}

export default function Filters() {
  const {t} = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [categoryOpen, setCategoryOpen] = useState(true);
  const [subCategoryOpen, setSubCategoryOpen] = useState(false);
  const [subCategoryExpand, setSubCategoryExpand] = useState(false);
  const [categories, setCategories] = useState<CategoryOption[]>([]);
  const [subCategories, setSubCategories] = useState<CategoryOption[]>([]);
  const [selection, setSelection] = useState<{ category?: CategoryOption | null; subCategory?: string[]; }>({});

  useEffect(() => {
    api.get(API_URL + '/products/categories')
      .then((response) => {
        const categories = (response.data?.data || []).map((c: CategoryEntity) => ({
          value: c.id,
          id: c.id,
          label: c.name,
          name: c.name
        }));

        setCategories(categories);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (selection.category) {
      api.get(API_URL + '/products/categories/' + selection.category?.id + '/subcategories')
        .then((response) => {
          const subcategories = (response.data?.data || []).map((c: CategoryEntity) => ({
            value: c.id,
            id: c.id,
            label: c.name,
            name: c.name
          }));

          setSubCategories(subcategories);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selection?.category?.id]);

  const handleClear = () => {
    setSelection({
      category: null
    });
    setSearchParams({});
  };

  const handleClearSubcategory = () => {
    setSelection({
      category: null
    });

    setSearchParams({
      category: searchParams.get('category') as string
    });
  };

  const onCategoryClick = (category: CategoryOption) => {
    setSelection({
      category: category
    });
    setSearchParams({
      category: category.id
    });
  };

  const onSubCategoryClick = (id: string) => {
    const ids = selection?.subCategory?.includes(id) ? selection.subCategory?.filter(s => s !== id)
      : selection.subCategory ? [...selection.subCategory, id] : [id];

    setSelection({
      ...selection,
      subCategory: ids
    });

    setSearchParams({
      category: searchParams.get('category') as string,
      subCategory: ids.join(',')
    });
  };

  const toggleCategoryOpen = () => {
    setCategoryOpen(state => !state);
  };

  const toggleSubCategoryOpen = () => {
    setSubCategoryOpen(state => !state);
  };

  const toggleExpand = () => {
    setSubCategoryExpand(state => !state);
  };

  const subCategoriesList = subCategoryExpand ? subCategories : subCategories.slice(5);

  return (
    <div className="filters">
      <div className="group">
        <div className="group-header">
          <strong>
            {t('filters.title')}
          </strong>
          <button className="link-btn" onClick={handleClear}>
            {t('filters.action.clearAll')}
          </button>
        </div>
      </div>
      <div className="group">
        <div className="group-header">
          <strong className={`toggle ${categoryOpen ? '' : 'close'}`} onClick={toggleCategoryOpen}>
            {t('filters.group.category')}
          </strong>
        </div>
        {categoryOpen && <div className="filters-body">
          <ul className="filters-links">
            {categories.map(category => (
              <li key={category.id}>
                <button
                  className={`link-btn ${selection?.category?.id === category.id ? 'active' : ''}`}
                  onClick={() => onCategoryClick(category)}
                >
                  {category.name}
                </button>
              </li>
            ))}
          </ul>
        </div>}
      </div>
      {subCategories.length > 1 && <div className="group">
        <div className="group-header">
          <strong className={`toggle ${subCategoryOpen ? '' : 'close'}`} onClick={toggleSubCategoryOpen}>
            {selection?.category?.name || ''}&nbsp;{t('filters.group.subcategory')}
          </strong>
          <button className="link-btn" onClick={handleClearSubcategory}>
            {t('filters.action.clear')}
          </button>
        </div>
        {subCategoryOpen && <div className="filters-body">
          <ul className="filters-links" ng-class="{ \'minimize\': !$ctrl.data.section.subcategory.expand }">
            {subCategoriesList.map(subCategory => (
              <li key={subCategory.id}>
                <button
                  className={`link-btn checkbox ${selection?.subCategory?.includes(subCategory.id) ? 'active' : ''}`}
                  onClick={() => onSubCategoryClick(subCategory.id)}
                >
                  {subCategory.name}
                </button>
              </li>
            ))}
          </ul>
          {subCategories.length > 5 &&  <button className="link-btn" onClick={toggleExpand}>
            {!subCategoryExpand && <span>
                {t('filters.action.showMore')}
            </span>}
            {subCategoryExpand && <span>
                {t('filters.action.showLess')}
            </span>}
          </button>}
        </div>}
      </div>}
    </div>
  );
}
