export const round = function(type: any, value: any, exp: any) {
  if(typeof exp === 'undefined' || +exp === 0) {
    // @ts-ignore
    return Math[type](value);
  }

  value = +value;
  exp = +exp;

  if(isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }

  value = value.toString().split('e');
  // @ts-ignore
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  value = value.toString().split('e');

  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
};
