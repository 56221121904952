import { useState } from "react";

interface PhoneFieldProps {
  id: string;
  type: string;
  label: string;
  error?: string;
  info?: string;
  placeholder?: string;
  disabled?: boolean;
  touched?: boolean;
  value: string;
  country?: string;
  onChange?: Function;
  onBlur?: Function;
  onFocus?: Function;
  onCountryChange?: Function;
  options?: { label: string; value: string; }[];
}

export default function PhoneField({
 id,
 error,
 label,
 info,
 type,
 placeholder,
 value,
 disabled,
 touched = false,
 onFocus,
 onChange,
 onBlur,
 options = [],
 onCountryChange
}: PhoneFieldProps) {
  const [countrySelection, setCountrySelection] = useState();
  const [countryIcon, setCountryIcon] = useState();

  const onSelectCountry = function(e: any) {
    if(disabled) { return; }

    const countryValue = e.target.value;

    setCountrySelection(countryValue);

    if(countryValue) {
      setCountryIcon(countryValue.toLowerCase());
      // self.placeholder = intlTelInputUtils.getExampleNumber(self.countrySelection.value.toLowerCase(), false, 0);
    }

    if(typeof onCountryChange === 'function') {
      onCountryChange(countryValue);
    }
  };

  return (
    <div className={`field phonefield ${error && touched ? 'has-error' : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <div style={{display: 'flex'}}>
        {/*<div className="phonefield-dropdown-inner" style={{width: '120px'}}>*/}
        {/*  <select*/}
        {/*    value={countrySelection}*/}
        {/*    onChange={onSelectCountry}*/}
        {/*    tabIndex={0}>*/}
        {/*    {options.map(option => (*/}
        {/*      <option key={option.value} value={option.value}>{option.label}</option>*/}
        {/*    ))}*/}
        {/*  </select>*/}
        {/*  <div className={`country icon icon-intlpn-flag ${countryIcon}`}></div>*/}
        {/*</div>*/}
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          value={value}
          onFocus={onFocus as any}
          onChange={onChange as any}
          onBlur={onBlur as any}
          disabled={disabled}
          tabIndex={0}
        />
      </div>
      {info && !error && <small className="field-info">{info}</small>}
      {error && touched && <small className="field-error">{error}</small>}
    </div>
  );
}
