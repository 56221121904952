import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ReactElement } from "react";

import useAuth from "../../hooks/useAuth";

export default function RequireAuth({ children, isAllowed = true }: { children: ReactElement, isAllowed?: boolean }): ReactElement {
  const { authed } = useAuth();
  const location = useLocation();

  return authed && isAllowed ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}
