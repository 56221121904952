import * as React from "react";
import { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";

import TextField from "../../../components/Textfield";
import Loader from "../../../components/Loader";
import { API_URL } from "../../../constants";
import api from "src/api";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import { parseApiError } from "../../../utils";

interface ChangePasswordFormValues {
  oldPassword: string;
  newPassword: string;
  passwordConfirmation: string;
}

const passwordPattern = /^(?=.*\d)[0-9a-zA-Z]{8,}$/;

export default function ChangePassword() {
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const notification = useNotification();

  const initialValues: ChangePasswordFormValues = {
    oldPassword: '',
    newPassword: '',
    passwordConfirmation: '',
  };

  const onSubmit = async (formValue: ChangePasswordFormValues, {setErrors}: FormikHelpers<ChangePasswordFormValues>) => {
    let data = {
      oldPassword: formValue.oldPassword,
      password: formValue.newPassword,
      passwordConfirmation: formValue.passwordConfirmation,
    };

    setIsSaving(true);

    api.post(API_URL + '/users/my/password', data)
      .then( () => {
        setIsSaving(false);
        notification.show({
          type: 'success',
          message: {
            translate: 'Index password has been successfully updated!'
          },
          autoDismiss: true
        });
      })
      .catch((res) => {
        parseApiError(res, notification, null, setErrors);
        setIsSaving(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        oldPassword: Yup.string()
          .required(t('field.error.password.required')),
        newPassword: Yup.string()
          .matches(passwordPattern, t('field.error.password.pattern'))
          .required(t('field.error.password.required')),
        passwordConfirmation: Yup.string()
          .test('passwords-match', t('field.error.confirmPassword.match'), function (value) {
            return this.parent.newPassword === value
          })
          .required(t('field.error.confirmPassword.required')),
      })}
    >
      {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
        <form onSubmit={handleSubmit}>
          <section className="fade-content">
            <div className="section-header dash dash-after dash-primary">
              <h1>
                {t('account.user.password.header.title')}
              </h1>
              <div>
                <Trans i18nKey={'account.user.password.header.text'}/>
              </div>
            </div>
            <div className="section-content form">
              <ul className="form-fieldset">
                <li>
                  <div>
                    <TextField
                      id="oldPassword"
                      label={t('field.label.currentPassword')}
                      type="password"
                      error={errors.oldPassword}
                      value={values.oldPassword}
                      touched={touched.oldPassword}
                      pattern="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div></div>
                </li>
                <li>
                  <div>
                    <TextField
                      id="newPassword"
                      label={t('field.label.password')}
                      type="password"
                      error={errors.newPassword}
                      value={values.newPassword}
                      touched={touched.newPassword}
                      pattern="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div>
                    <TextField
                      id="passwordConfirmation"
                      label={t('field.label.confirmNewPassword')}
                      type="password"
                      error={errors.passwordConfirmation}
                      value={values.passwordConfirmation}
                      touched={touched.passwordConfirmation}
                      pattern="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div className="section-content">
              {isSaving && <Loader/>}
              {!isSaving && <div>
                <button type="submit"
                        className="button button-success"
                        onClick={() => handleSubmit()}
                >
                  {t('actions.changePassword')}
                </button>
              </div>}
            </div>
          </section>
        </form>
      )}
    </Formik>
  );
}
