import { default as React, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import CheckboxField from "../../../components/CheckboxField";
import TextField from "../../../components/Textfield";
import ReviewOfferModal from "./ReviewOfferModal";
import OfferSummary from "./OfferSummary";
import { API_URL } from "../../../constants";
import api from "src/api";
import { useSelector } from "react-redux";
import { parseApiError } from "../../../utils";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import { useNavigate, useSearchParams } from "react-router-dom";

const formatQty = function(value: string) {
  let formatted = String(value);

  formatted = formatted.replace(/[^0-9]+/g, '');

  return parseInt(formatted) || 0;
};

const formatPercentage = function(value: string) {
  let formattedStr = String(value);
  let formatted;

  formattedStr = formattedStr.replace(/[^0-9]+/g, '');
  formatted = parseInt(formattedStr) || 0;

  if(formatted < 0) {
    formatted = 0;
  }

  if(formatted > 100) {
    formatted = 100;
  }

  return formatted;
};

export default function CreateOfferForm({ brandId, products: productsInit, warehouses }:
  { brandId: string; products: any; warehouses: any }
  ) {
  const { t } = useTranslation();
  const notification = useNotification();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const preselectedProduct = searchParams.get('chosenProduct');

  const [hasInitializeOffer, setHasInitializeOffer] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [reviewOfferVisible, setReviewOfferVisible] = useState(false);
  const [offerTypes, setOfferTypes] = useState<any[]>([]);

  const user = useSelector((state: any) => state.user);

  const initialValues = {
    autoQty: {
      enabled: false,
      percentage: ''
    },
    autoMsrp: {
      enabled: false,
      percentage: ''
    },
    autoWholesale: {
      enabled: false,
      percentage: ''
    },
    products: productsInit.map((p: any) => {
      if (p.id.toString() === preselectedProduct) {
        return {
          ...p,
          selected: true,
          offer: {
            quantity: p.inventory,
            msrp: parseFloat(p.msrp),
            wholesale: parseFloat(p.wholesale),
            error: null
          }
        }
      }

      return { ...p, selected: false }
    }),
    offer: {
      productCount: 0,
      etailer: '',
      shipFrom: undefined,
      lead: 0,
      paymentTerms: 0,
      type: 'directPo'
    }
  };

  useEffect(() => {
    api.get(API_URL + '/offers/types')
      .then((response) => {
        const data = response.data.data;

        setOfferTypes(
          Object.entries(data).map(([key, value]: any) => ({
            value: key,
            label: value
          }))
        );
      })
      .catch((error) => {
        // parseApiError(error, notification, null);
      });
  }, []);

  const onSubmit = (values: any) => {
    let data = {
      brandId: brandId,
      type: values.offer.type === 'directPo' ? 'direct_po' : values.offer.type,
      shippingLeadTime: values.offer.lead,
      paymentTerms: values.offer.paymentTerms,
      dateFrom: values.offer.launchDate,
      dateTo: values.offer.endDate,
      isShippingIncluded: !!values.offer.includeShipping,
      products: values.products.filter((p: any) => p.selected).map((product: any) => ({
        productId: product.id,
        inventory: product.offer.quantity,
        msrp: product.offer.msrp,
        wholesale: product.offer.wholesale
      })),
      warehouseId: values.offer.shipFrom
    };

    setIsSaving(true);

    api.post(API_URL + '/offers', data)
      .then(function () {
        setIsSaving(false);

        notification.show({
          type: 'success',
          message: {
            translate: 'Offer created successfully'
          },
          autoDismiss: true
        });

        navigate('/browse-brands');
      })
      .catch((error) => {
        parseApiError(error, notification, null);
        setIsSaving(false);
      });
  };

  const updateAllSelected = function (values: any, setFieldValue: any) {
    const products = values.products;
    const autoQty = values.autoQty;
    const autoMsrp = values.autoMsrp;
    const autoWholesale = values.autoWholesale;

    products.forEach((product: any, index: number) => {
      if (product.selected) {
        if (autoQty.enabled) {
          if (autoQty.percentage < product.inventory) {
            setFieldValue(`products[${index}].offer.quantity`, autoQty.percentage)
          } else {
            setFieldValue(`products[${index}].offer.quantity`, product.inventory)
          }
        }
        if (autoMsrp.enabled) {
          setFieldValue(`products[${index}].offer.msrp`, parseFloat(product.msrp) * (autoMsrp.percentage / 100));
        }

        if (autoWholesale.enabled) {
          setFieldValue(`products[${index}].offer.wholesale`, parseFloat(product.wholesale) * (autoWholesale.percentage / 100));
        }
      }
    });
  };

  const productCheckboxCallback = function (e: any, product: any, index: number, values: any, setFieldValue: any) {
    const selected = e.target.checked;
    const autoQty = values.autoQty;
    const autoMsrp = values.autoMsrp;
    const autoWholesale = values.autoWholesale;

    var qty,
      msrp,
      wholesale;

    if (selected) {
      if (autoQty.enabled && autoQty.percentage < product.inventory) {
        qty = autoQty.percentage;
      } else {
        qty = product.inventory;
      }

      if (autoMsrp.enabled) {
        msrp = parseFloat(product.msrp) * (autoMsrp.percentage / 100);
      } else {
        msrp = parseFloat(product.msrp);
      }

      if (autoWholesale.enabled) {
        wholesale = parseFloat(product.wholesale) * (autoWholesale.percentage / 100);
      } else {
        wholesale = parseFloat(product.wholesale);
      }
    }

    setFieldValue(`products[${index}].offer`, {
      quantity: qty,
      msrp: msrp,
      wholesale: wholesale,
      error: null
    });
  };

  const autoQtyChange = (e: any, values: any, setFieldValue: any) => {
    if (!values.autoQty.enabled) {
      setFieldValue('autoQty.enabled', true);
    }
    const percentage = formatQty(e.target.value);
    setFieldValue('autoQty.percentage', percentage);

    updateAllSelected({
      ...values,
      autoQty: {
        enabled: true,
        percentage
      }
    }, setFieldValue);
  };

  const autoMsrpChange = (e: any, values: any, setFieldValue: any) => {
    if (!values.autoMsrp.enabled) {
      setFieldValue('autoMsrp.enabled', true);
    }

    const percentage = formatPercentage(e.target.value);
    setFieldValue('autoMsrp.percentage', percentage);

    updateAllSelected({
      ...values,
      autoMsrp: {
        enabled: true,
        percentage
      }
    }, setFieldValue);
  };

  const autoWholesaleChange = (e: any, values: any, setFieldValue: any) => {
    if (!values.autoWholesale.enabled) {
      setFieldValue('autoWholesale.enabled', true);
    }

    const percentage = formatPercentage(e.target.value);
    setFieldValue('autoWholesale.percentage', percentage);

    updateAllSelected({
      ...values,
      autoWholesale: {
        enabled: true,
        percentage
      }
    }, setFieldValue);
  };

  const handleReviewOffer = (e: any) => {
    e.preventDefault();
    setReviewOfferVisible(true)
  };

  const handleCloseReviewModal = () => {
    setReviewOfferVisible(false)
  };

  const userType = user?.profile?.type?.id;
  const isBuyer = userType === 2;

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          offer: Yup.object().shape({
            shipFrom: Yup.string()
              .required(t('field.error.shipFrom.required')),
          })
        })}
      >
        {({values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue}) => (
          <form onSubmit={handleSubmit}>
            {reviewOfferVisible && <ReviewOfferModal values={values} onClose={handleCloseReviewModal} confirmOffer={() => {
              handleCloseReviewModal();
              handleSubmit();
            }} />}
            <div className="column">
              <div className="column-left">
                <div className="offer-card">
                  <div className="offer-card-body" style={{position: 'relative'}}>
                    <div className="togg" set-top-sticky-class="sticky-tool">
                      <div className="row row-header">
                        <div className="col col-product">Products</div>
                        <div className="col col-qty">Inventory</div>
                        <div className="col col-msrp">MSRP<br/>(Selling price)</div>
                        <div className="col col-wholesale">Wholesale<br/>(Make your offer)</div>
                      </div>

                      <div className="row row-main" style={{backgroundColor: 'white'}}>
                        <div className="col col-product">Check to automatically apply values on selected
                          product.
                        </div>
                        <div className="cols">
                          <div className="row" style={{flex: 1}}>
                            <div className="col col-qty">
                              <CheckboxField name="autoQty.enabled"/>&nbsp;&nbsp;
                              <TextField
                                id="autoQty.percentage"
                                label=""
                                type="text"
                                error={errors.autoQty?.percentage}
                                value={values.autoQty.percentage}
                                touched={touched.autoQty?.percentage}
                                onChange={(e: any) => autoQtyChange(e, values, setFieldValue)}
                                onBlur={handleBlur}
                              />&nbsp;&nbsp;
                              <strong>Max</strong>
                            </div>
                            <div className="col col-msrp">
                              <CheckboxField name="autoMsrp.enabled"/>&nbsp;&nbsp;
                              <TextField
                                id="autoMsrp.percentage"
                                label=""
                                type="text"
                                error={errors.autoMsrp?.percentage}
                                value={values.autoMsrp.percentage}
                                touched={touched.autoMsrp?.percentage}
                                onChange={(e: any) => autoMsrpChange(e, values, setFieldValue)}
                                onBlur={handleBlur}
                              />&nbsp;&nbsp;
                              <strong>%</strong>
                            </div>
                            <div className="col col-wholesale">
                              <CheckboxField name="autoWholesale.enabled"/>&nbsp;&nbsp;
                              <TextField
                                id="autoWholesale.percentage"
                                label=""
                                type="text"
                                error={errors.autoWholesale?.percentage}
                                value={values.autoWholesale.percentage}
                                touched={touched.autoWholesale?.percentage}
                                onChange={(e: any) => autoWholesaleChange(e, values, setFieldValue)}
                                onBlur={handleBlur}
                              />&nbsp;&nbsp;
                              <strong>%</strong>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    {values.products?.map((product: any, index: number) => (
                      <div key={product.id} className="row row-main">
                        <div className="col col-toggle" style={{borderRight: 0}}>
                          {isBuyer && <CheckboxField
                            name={`products[${index}].selected`}
                            onChange={(e: any) => productCheckboxCallback(e, product, index, values, setFieldValue)}
                          />}
                        </div>
                        <div className="col col-product"
                             style={{borderLeft: 0, paddingLeft: 0, justifyContent: 'flex-start'}}>
                          <small>{product.sku}</small>
                          <strong>
                            {product.name}
                          </strong>
                        </div>
                        <div className="cols">
                          <div className="row" style={{flex: 1}}>
                            <div className="col col-qty">
                              <strong>
                                {product.inventory}
                              </strong>
                            </div>
                            <div className="col col-msrp">
                              <strong>
                                ${product.msrp}
                              </strong>
                            </div>
                            <div className="col col-wholesale">
                              <strong>
                                ${product.wholesale}
                              </strong>
                            </div>
                          </div>
                          {isBuyer && product.selected &&
                          <div className="row row-form txt-grey-dark">
                            <div className="col col-qty">
                              <TextField
                                id={`products[${index}].offer.quantity`}
                                label=""
                                type="text"
                                // error={errors.autoMsrp?.percentage}
                                value={product.offer?.quantity}
                                // touched={touched?.products?.[index]?.offer?.quantity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/*<textfield*/}
                              {/*ui-type="text"*/}
                              {/*ui-change-callback="$ctrl.validateOfferProduct(product)"*/}
                              {/*ui-render-format="$ctrl.formatQty(value)"*/}
                              {/*ng-model="product.offer.quantity"*/}
                              {/*ng-model-options="$ctrl.data.modelOptions.default">*/}
                              {/*</textfield>*/}
                            </div>
                            <div className="col col-msrp">
                              <TextField
                                id={`products[${index}].offer.msrp`}
                                label=""
                                type="text"
                                // error={errors.autoMsrp?.percentage}
                                value={product.offer?.msrp}
                                // touched={touched?.products?.[index]?.offer?.quantity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/*<textfield*/}
                              {/*ui-type="text"*/}
                              {/*ui-change-callback="$ctrl.validateOfferProduct(product)"*/}
                              {/*ui-render-format="$ctrl.formatFloat(value)"*/}
                              {/*ng-model="product.offer.msrp"*/}
                              {/*ng-model-options="$ctrl.data.modelOptions.default">*/}
                              {/*</textfield>*/}
                            </div>
                            <div className="col col-wholesale">
                              <TextField
                                id={`products[${index}].offer.wholesale`}
                                label=""
                                type="text"
                                // error={errors.autoMsrp?.percentage}
                                value={product.offer?.wholesale}
                                // touched={touched?.products?.[index]?.offer?.quantity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/*<textfield*/}
                              {/*ui-type="text"*/}
                              {/*ui-change-callback="$ctrl.validateOfferProduct(product)"*/}
                              {/*ui-render-format="$ctrl.formatFloat(value)"*/}
                              {/*ng-model="product.offer.wholesale"*/}
                              {/*ng-model-options="$ctrl.data.modelOptions.default">*/}
                              {/*</textfield>*/}
                            </div>
                          </div>}
                          {product.offer?.error &&
                          <div className="txt-danger" style={{padding: '10px', borderRight: '1px solid #ddd'}}>
                            {product.offer?.error}
                          </div>}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>


              {isBuyer && <div className="column-right">
                <div>

                  {hasInitializeOffer && <div className="fade-section"
                                              style={{
                                                backgroundColor: 'rgba(175,131,57,0.3)',
                                                borderRadius: '4px',
                                                padding: '20px',
                                                border: '1px solid rgba(175,131,57,0.1)',
                                                marginBottom: '40px'
                                              }}>
                    <h3 style={{color: 'rgba(0, 0, 0, 0.8)'}}>How to<br/>make an offer<br/>for this brand?</h3>
                    <p style={{
                      paddingTop: '10px',
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: 'rgba(0, 0, 0, 0.4)'
                    }}>Simply
                      selects each products that you want, then enter the quantity you need and the unit price
                      you
                      offer.</p>
                  </div>}

                  <OfferSummary
                    offerTypes={offerTypes}
                    warehouses={warehouses}
                    values={values}
                    setFieldValue={setFieldValue}
                    setHasInitializeOffer={setHasInitializeOffer}
                    hasInitializeOffer={hasInitializeOffer}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    onReviewOffer={handleReviewOffer}
                    onSubmit={handleSubmit}
                    errors={errors}
                    touched={touched}
                    isSaving={isSaving}
                  />
                </div>
              </div>}
            </div>
          </form>)}
      </Formik>
    </>
  );
}
