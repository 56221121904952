interface TextboxFieldProps {
  id: string;
  label: string;
  error?: string;
  info?: string;
  placeholder?: string;
  disabled?: boolean;
  touched?: boolean;
  value: string;
  rows: number;
  onChange?: Function;
  onBlur?: Function;
  onFocus?: Function;
}

export default function TextboxField({
  id,
  label,
  error,
  info,
  placeholder,
  disabled,
  touched,
  value,
  rows,
  onFocus = () => {},
  onChange = () => {},
  onBlur = () => {}
}: TextboxFieldProps) {
  return (
    <div className={`field textbox ${error && touched ? 'has-error' : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <textarea
        id={id}
        placeholder={placeholder}
        value={value}
        onFocus={onFocus as any}
        onChange={onChange as any}
        onBlur={onBlur as any}
        disabled={disabled}
        rows={rows}
        tabIndex={0}>
      </textarea>
      {info && !error && <small className="field-info">{info}</small>}
      {error && touched && <small className="field-error">{error}</small>}
    </div>
  );
}
