import { default as React } from "react";
import { Outlet, useMatch } from "react-router-dom";

import TabBar from "../../../components/TabBar";

export default function User() {
  const match = useMatch('/account/user/:lastPart');
  const currentSection = match?.params.lastPart || 'profile';

  const tabs = [
    {
      section: 'profile',
      translate: 'account.user.tabs.profile',
      url: '/account/user/profile'
    },
    {
      section: 'change-password',
      translate: 'account.user.tabs.password',
      url: '/account/user/change-password'
    }
  ];

  return (
    <>
      <TabBar tabs={tabs} active={currentSection} />
      <div className="section-inner">
        <Outlet />
      </div>
    </>
  );
}
