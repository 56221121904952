import { default as React, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { API_URL } from "../../../constants";
import api from "../../../api";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import { parseApiError } from "../../../utils";
import Loader from "../../../components/Loader";

interface Warehouse {
  name: string;
  id: string;
  address: {
    address: string;
    country: string;
    province?: string;
    zipCode: string;
    postalCode?: string;
    city: string;
  }
}

export default function CompanyWarehouses() {
  const {t} = useTranslation();
  const notification = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);

  useEffect(() => {
    setIsLoading(true);
    api.get(API_URL + '/companies/my')
      .then((response) => {
        const data = response.data.data;
        setWarehouses(data.warehouses.map((warehouse: any) => ({
          name: warehouse.name,
          id: warehouse.id,
          address: {
            address: warehouse.address.address,
            country: warehouse.address.country?.id,
            province: warehouse.address.region?.id,
            zipCode: warehouse.address.zipCode,
            postalCode: warehouse.address.postalCode,
            city: warehouse.address.city,
          }
        })));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        parseApiError(error, notification, null);
      });
  }, []);

  const warehousesCount = warehouses.length;

  return (
    <section className="fade-content">
      <div className="section-header dash dash-after dash-primary">
        <h1>
          {t('account.company.warehouses.header.title')}
        </h1>
        <div>
          <Trans i18nKey={'account.company.warehouses.header.text'} values={{count: warehousesCount}}/>
        </div>
      </div>

      {isLoading && <Loader />}

      {!warehousesCount && !isLoading &&
        <div className="section-content">
          <Link to="new" className="button button-primary">
            {t('actions.addItem', {context: 'warehouse'})}
          </Link>
        </div>
      }

      {!!warehousesCount && !isLoading && <div className="section-content warehouse-grid">
        <div>
          <Link to="new" className="warehouse-card warehouse-new-card">
            <span className="icon-64-plus"></span>
            <strong>
              {t('actions.addItem', {context: 'warehouse'})}
            </strong>
          </Link>
        </div>

        {warehouses.map(warehouse => (
          <div key={warehouse.name}>
            <div className="warehouse-card">
              <div className="warehouse-card-body">
                <strong>
                  {warehouse.name}
                </strong>
                <span>
                  <span>{warehouse.address.address}</span>
                </span>
                <span>
                  <span>{warehouse.address.city}</span>
                  <span>{warehouse.address.province}</span>
                  <span>{warehouse.address.postalCode}</span>
                </span>
                <span>
                    {warehouse.address.country}
                </span>
              </div>

              <div className="warehouse-card-footer">
                <Link to={`${warehouse.id}`}  className="icon">
                  <span className="icon-24-edit"></span>
                </Link>
                {/*  <a className="icon" href="#" ng-click="$ctrl.deleteWarehouse(warehouse.id)">*/}
                {/*<span className="icon-24-delete"></span>*/}
                {/*  </a>*/}
              </div>
            </div>
          </div>
        ))}
      </div>}
    </section>
  );
}
