import { useTranslation } from "react-i18next";
import { default as React, useEffect } from "react";
import ToggleField from "../../../components/ToggleField";
import TextField from "../../../components/Textfield";
import CheckboxField from "../../../components/CheckboxField";
import Dropdown from "../../../components/Dropdown";
import { Field, FieldProps } from "formik";

export default function OfferSummary({
  values,
  offerTypes,
                                       errors,
                                       touched,
  warehouses,
  setFieldValue,
  setHasInitializeOffer,
  hasInitializeOffer,
  handleChange,
  handleBlur,
  onDeleteOffer = () => {},
  onReviewOffer = () => {},
  onSubmit = () => {}
}: any) {
  const {t} = useTranslation();

  useEffect(() => {
    const products = values.products;
    let productCount = 0;
    let offerCount = 0;
    let total = 0;

    products.forEach((product: any) => {
      if (product.selected) {
        offerCount++;

        if(!product.error) {
          productCount++;
          total += (product.offer.wholesale * product.offer.quantity);
        }
      }
    });

    setHasInitializeOffer(offerCount > 0);

    setFieldValue(`offer.productCount`, productCount);
    setFieldValue(`offer.total`, total);
  }, [values.products]);

  if (!hasInitializeOffer) {
    return null
  }

  return (
    <div className="offer-summary fade-section">
      <div className="offer-summary-header">
        <strong>
          {t('offer.productsSelected', {count: values.offer?.productCount})}
        </strong>
        <a className="icon" onClick={onDeleteOffer}>
          <span className="icon-link-delete-grey-lighter"></span>
        </a>
      </div>
      <ul className="offer-summary-body">
        <li>
          <div style={{width: '100%'}}>
            <ToggleField
              id={'offer.type'}
              label={t('field.label.offerType')}
              value={values.offer.type}
              options={offerTypes}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </li>
        {values.offer.type == 'reserve' && <li ng-if="$ctrl.data.offer.type == \'reserve\'">
          <div style={{ width: '100%' }}>
            <TextField
              id="offer.launchDate"
              label="From"
              type="date"
              // error={errors.offer.launchDate}
              value={values.offer.launchDate}
              // touched={touched.offer.launchDate}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </li>}
        {values.offer.type == 'reserve' && <li ng-if="$ctrl.data.offer.type == \'reserve\'">
          <div style={{width: '100%'}}>
            <TextField
              id="offer.endDate"
              label="To"
              type="date"
              // error={errors.offer.endDate}
              value={values.offer.endDate}
              // touched={touched.offer.endDate}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </li>}
        <li>
          <div style={{width: '100%'}}>
            <TextField
              id="offer.lead"
              label="Shipping lead time (day/s)"
              type="number"
              min={0}
              error={errors.offer?.lead || ''}
              value={values.offer.lead}
              touched={touched.offer?.lead}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </li>
        <li>
          <div style={{width: '100%'}}>
            <TextField
              id="offer.paymentTerms"
              label="Payment Terms (day/s)"
              type="number"
              min={0}
              error={errors.offer?.paymentTerms || ''}
              value={values.offer.paymentTerms}
              touched={touched.offer?.paymentTerms}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </li>
        <li>
          <CheckboxField name="offer.includeShipping"/>
          <span style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            color: '#888',
            paddingLeft: '10px'
          }}>
           <span>Shipping included</span>
            {!values.offer.includeShipping && <small style={{color: '#444'}}>
              Buyer/Retailer paying for shipping (included in Wholesale price).
            </small>}
            {values.offer.includeShipping && <small style={{color: '#444'}}>
              Brand paying for shipping (excluded from Wholesale price).
            </small>}
          </span>
        </li>
        {!!warehouses?.length && <li>
          <div style={{width: '100%'}}>
            <Field name={`offer.shipFrom`}>
              {({field}: FieldProps<any>) => (
                <Dropdown
                  id={field.name}
                  label={t('field.label.shipFrom')}
                  options={warehouses}
                  error={errors.offer?.shipFrom}
                  touched={touched.offer?.shipFrom}
                  {...field}
                />
              )}
            </Field>
          </div>
        </li>}
        <li>
          <strong className="expand">
            {t('offer.total')}
          </strong>
          <strong>
            ${values.offer.total.toFixed(2)}
          </strong>
        </li>
      </ul>
      <div className="offer-summary-footer">
        <button className="button button-block" disabled={!values.offer.total} type="submit" onClick={onSubmit}>
          <span>
            {t('actions.saveForLater')}
          </span>
        </button>

        <button className="button button-block button-success" disabled={!values.offer.total} onClick={onReviewOffer}>
          <span>
           {t('actions.reviewOffer')}
          </span>
        </button>
      </div>
    </div>
  );
}
