import * as React from "react";
import { useState } from "react";

export default function ImagePreloader({ url, alt }: { url: string; alt?: string }) {
  const [isLoaded, setIsLoaded] = useState(false);

  const onLoad = () => {
    setIsLoaded(true);
  };

  return (
    <img src={url} alt={alt || "logo"} onLoad={onLoad} className={isLoaded ? 'loaded' : 'preload'} />
  );
}
