import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import './libs.css';
import './App.css';
import 'react-slideshow-image/dist/styles.css'

import { ViewLoaderContext } from './contexts';
import api from "./api";
import { API_URL } from "./constants";
import { setCurrentUser } from "./resucers/user/userSlice";
import { parseApiError } from "./utils";
import { useNotification } from "./components/NotificationAlert/useNotification";
import { NotificationProvider } from "./components/NotificationAlert/NotificationProvider";

import Home from "./views/Home";
import SignUp from "./views/SignUp";
import Login from "./views/Login";
import ForgotPassword from "./views/ForgotPassword";
import ResendActivation from "./views/ResendActivation";
import Activation from "./views/Activation";
import TermsOfService from "./views/TermsOfService";
import PrivacyPolicy from "./views/PrivacyPolicy";
import About from './views/About';
import Contact from "./views/Contact";
import Feature from "./views/Features";
import BrandsFeature from "./views/Features/Brands";
import DistributorsFeature from "./views/Features/Distributors";
import EtailersFeature from "./views/Features/Etailers";
import ResetPassword from "./views/ResetPassword";
import Account from "./views/Account";
import Dashboard from "./views/Account/Dashboard";
import Profile from "./views/Account/User/Profile";
import ChangePassword from "./views/Account/User/ChangePassword";
import User from "./views/Account/User";
import Company from "./views/Account/Company";
import CompanyProfile from "./views/Account/Company/CompanyProfile";
import Invite from "./views/Account/Company/Invite";
import CompanyWarehouses from './views/Account/Company/CompanyWarehouses';
import CompanyMembers from "./views/Account/Company/Members";
import AddWarehouse from "./views/Account/Company/AddWirehouse";
import EditWarehouse from "./views/Account/Company/EditWirehouse";
import Brands from "./views/Account/Brands";
import BrandsList from "./views/Account/Brands/BrandsList";
import BrandProfile from "./views/Account/Brands/BrandProfile";
import BrandProducts from './views/Account/Brands/BrandProducts';
import RequireAuth from "./components/ProtectedRoute";
import BrowseBrands from "./views/BrowseBrands";
import BrandPage from "./views/BrowseBrands/BrandPage";
import BrandView from "./views/BrowseBrands/BrandPage/BrandView";
import ProductView from "./views/BrowseBrands/BrandPage/ProductView";
import OffersPage from "./views/Account/Offers";
import Offers from "./views/Account/Offers/Offers";
import Offer from './views/Account/Offers/Offer';
import OfferMessages from "./views/Account/Offers/OfferMessages";
import MessagesPage from "./views/Account/Messages";
import SubscriptionCancel from "./views/SignUp/SubscriptionCancel";
import SubscriptionSuccess from "./views/SignUp/SubscriptionSuccess";
import Products from "./views/Products";
import BrandMaterials from "./views/Account/Brands/BrandMaterials";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notification = useNotification();
  const [isViewLoaderVisible, setIsViewLoaderVisible] = useState(false);
  const loaderValue = { isViewLoaderVisible, setIsViewLoaderVisible };
  const user = useSelector((state: any) => state.user);

  useEffect(() => {
    const token = localStorage.getItem("access_token");

    if (token) {
      api.get(API_URL + '/users/me')
        .then(function (response) {
          dispatch(setCurrentUser(response.data));
        })
        .catch((error) => {
          parseApiError(error, notification, navigate);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userType = user?.profile?.type?.id;
  const isBuyer = userType === 2;

  return (
    <ViewLoaderContext.Provider value={loaderValue}>
      <NotificationProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products/special" element={<Products />} />
          <Route path="/products/suggested" element={<Products />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/subscription/cancel" element={<SubscriptionCancel />} />
          <Route path="/subscription/success" element={<SubscriptionSuccess />} />
          <Route path="/invitation" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/resend-activation" element={<ResendActivation />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/activation" element={<Activation />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/features" element={<Feature />}>
            <Route path="brands" element={<BrandsFeature />} />
            <Route path="distributors" element={<DistributorsFeature />} />
            <Route path="etailers" element={<EtailersFeature />} />
            <Route index element={<Navigate to="brands" />} />
            <Route path="*" element={<Navigate to="brands" />} />
          </Route>
          <Route path="/account" element={
            <RequireAuth>
              <Account />
            </RequireAuth>
          }>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="user" element={<User />}>
              <Route path="profile" element={<Profile />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route index element={<Navigate to="profile" />} />
              <Route path="*" element={<Navigate to="profile" />} />
            </Route>
            <Route path="company" element={<Company />}>
              <Route path="profile" element={<CompanyProfile />} />
              <Route path="members" element={<CompanyMembers />} />
              <Route path="members/invite" element={<Invite />} />
              <Route path="warehouses" element={
                <RequireAuth isAllowed={!isBuyer}>
                  <CompanyWarehouses />
                </RequireAuth>
              }
              />
              <Route path="warehouses/new" element={
                <RequireAuth isAllowed={!isBuyer}>
                  <AddWarehouse />
                </RequireAuth>
              } />
              <Route path="warehouses/:id" element={
                <RequireAuth isAllowed={!isBuyer}>
                  <EditWarehouse />
                </RequireAuth>
              } />
              <Route index element={<Navigate to="profile" />} />
              <Route path="*" element={<Navigate to="profile" />} />
            </Route>
            <Route path="Brands" element={
              <RequireAuth isAllowed={!isBuyer}>
                <Brands />
              </RequireAuth>
            }>
              <Route index element={<BrandsList />} />
              <Route path=":id/profile" element={<BrandProfile />} />
              <Route path=":id/products" element={<BrandProducts />} />
              <Route path=":id/materials" element={<BrandMaterials />} />
              <Route path="*" element={<Navigate to="" />} />
            </Route>
            <Route path="offers" element={<OffersPage />}>
              <Route index element={<Offers />} />
              <Route path=":id" element={<Offer />} />
              <Route path=":id/message" element={<OfferMessages />} />
              <Route path="*" element={<Navigate to="" />} />
            </Route>
            <Route path="messages" element={<MessagesPage />} />
          </Route>
          <Route path="browse-brands" element={
            <RequireAuth>
              <BrowseBrands />
            </RequireAuth>
          } />
          <Route path="brand-view/:id" element={
            <RequireAuth>
              <BrandPage />
            </RequireAuth>
          }>
            <Route index element={<BrandView />} />
            <Route path="products" element={<BrandView />} />
            <Route path="products/:productId" element={<ProductView />} />
            <Route path="*" element={<Navigate to="" />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </NotificationProvider>
    </ViewLoaderContext.Provider>
  );
}

export default App;
