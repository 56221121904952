import * as React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "../../components/Loader";
import ImagePreloader from "../../components/ImagePreloader";
import { API_FILES_URL, API_URL } from "../../constants";
import productPlaceholder from '../../assets/images/product-placeholder.jpeg';

export default function SuggestedBrands({ isBrand }: { isBrand: boolean }) {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [brands, setBrands] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getBrands = () => {
    setIsLoading(true);
    axios.get(API_URL + '/brands/home')
      .then((response) => {
        setBrands(response.data.data.slice(0, 3));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getBrands();
  }, []);

  const selectBrand = (brand: any) => {
    navigate(`/brand-view/${brand.id}`);
  };

  return (
    <div className="block">
      <h4>{isBrand ? 'Similar' : 'Suggested'} Brands</h4>
      {isLoading && <Loader className="full-width"/>}

      {!isLoading &&
        <>
          {brands.map((brand: any) => (
            <div key={'brand-' + brand.id} className="brand" onClick={() => selectBrand(brand)}>
              <div className="brand-header brand-header-medium">
                <ImagePreloader
                  url={
                    brand.main_photo ? API_FILES_URL + '/' + brand.main_photo : productPlaceholder
                  }
                />
              </div>
              <div className="brand-body">
                <div className="brand-name">
                  {brand.name}
                </div>
                {brand.company && <div>
                  <small>
                    {t('general.distributedBy')}
                  </small>
                  <div className="brand-company-name">
                    {brand.company.name}
                  </div>
                </div>}
              </div>
              <div className="brand-footer">
                <small>
                  {brand.products_count || 0} Products
                </small>
              </div>
            </div>
          ))}
          <Link to="/browse-brands" className="show-more-btn">
            Show more <span className="arrow-forward-icon"></span>
          </Link>
        </>
      }
    </div>
  );
}
