import { useEffect, useState } from "react";

function getBgUrl(el: HTMLElement) {
  let bg = "";
  if ((el as any).currentStyle) { // IE
    bg = (el as any).currentStyle.backgroundImage;
  } else if (document.defaultView && document.defaultView.getComputedStyle) { // Firefox
    bg = document.defaultView.getComputedStyle(el, "").backgroundImage;
  } else { // try and get inline style
    bg = el.style.backgroundImage;
  }
  return bg.replace(/url\(['"]?(.*?)['"]?\)/i, "$1");
}

export default function useBgLoaded(bgImage: HTMLElement | null) {
  const [bgLoaded, setBgLoaded] = useState(false);

  useEffect(() => {
    if (bgImage) {
      let image = document.createElement('img');
      image.src = getBgUrl(bgImage);
      image.onload = function () {
        setBgLoaded(true);
      };
    }
  }, [bgImage]);

  return bgLoaded;
}
