import * as React from "react";
import { useMatch } from "react-router-dom";

import Header from "../../components/Header";
import ViewLoader from "../../components/ViewLoader";
import Footer from "../../components/Footer";

import './index.css'
import ProductsList from "./ProductsList";
import Filters from "../BrowseBrands/Filters";

export default function Products() {
  const match = useMatch('/products/:section/*');
  const currentSection = match?.params.section || 'special';

  const showFilters = currentSection === 'special';

  return (
    <div className="view-wrapper">
      <Header/>
      <ViewLoader/>

      <div className={`view view-content header-margin products`}>
        <div className="view-inner">
          <h3>{currentSection === 'special' ? 'Special' : 'Suggested'} products</h3>

          <div className={showFilters ? "columns" : 'no-filters'}>
            {showFilters && <div>
              <Filters />
            </div>}
            <div>
              <ProductsList currentSection={currentSection} />
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
