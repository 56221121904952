import { useContext, useEffect } from "react";
import { ViewLoaderContext } from "../../contexts";

interface ViewLoaderProps {
  onFinish?: () => void;
}

export default function ViewLoader({ onFinish }: ViewLoaderProps) {
  const { isViewLoaderVisible, setIsViewLoaderVisible } = useContext(ViewLoaderContext);

  useEffect(() => {
    setIsViewLoaderVisible(true);
    setTimeout(() => {
      setIsViewLoaderVisible(false);
      onFinish && onFinish();
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`view-loader ${isViewLoaderVisible ? 'show' : ''}`}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
