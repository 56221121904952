import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Dashboard() {
  const {t} = useTranslation();
  const task = {} as any;

  return (
    <div className="section-inner">
      <section className="fade-content">
        <div className="section-header">
          <h1>
            {t('_account.dashboard.header.title')}
          </h1>
          <div>
            <Trans i18nKey={'_account.dashboard.header.text'} />
          </div>
        </div>
        <div className="section-content form">
          <ul className="form-fieldset">
            <li>
              <div style={{padding: '0 0 20px 0'}}>
                <strong className="txt-primary">Latest offers</strong>
                <div className="txt-grey"><p>Please click on “View Offers“ link to get offers list</p></div>
              </div>
            </li>
            <li>
              <div className="list" style={{fontSize: '16px', backgroundColor: '#FFF', padding: 0}}>
                <div className="list-item">
                  <div className="expand" style={{ textAlign: 'left' }}>
                  </div>
                  <div>
                    <Link to="/account/offers">
                      View Offers
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="section-content form">
          <ul className="form-fieldset">
            <li>
              <div style={{padding: '0 0 20px 0'}}>
                <strong className="txt-primary">Latest messages</strong>
                <div className="txt-grey"><p>Please click on “View Messages“ link to get messages list</p></div>
              </div>
            </li>
            <li>
              <div className="list" style={{fontSize: '16px', backgroundColor: '#FFF', padding: 0}}>
                <div className="list-item">
                  <div className="expand" style={{textAlign: 'left'}}>
                  </div>
                  <div>
                    <Link to="/account/messages">
                      View Messages
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}
