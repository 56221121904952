import * as React from "react";
import * as Yup from "yup";
import { useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import TextField from "../../../../components/Textfield";
import Loader from "../../../../components/Loader";
import api from "../../../../api";
import { API_URL } from "../../../../constants";
import { parseApiError } from "../../../../utils";
import { useNotification } from "../../../../components/NotificationAlert/useNotification";

interface InviteFormValues {
  name: string;
  email: string;
}

export default function Invite() {
  const {t} = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const notification = useNotification();
  const navigate = useNavigate();

  const initialValues: InviteFormValues = {
    name: '',
    email: ''
  };

  const onSubmit = (formValue: InviteFormValues) => {
    setIsSaving(true);

    const data = {
      name: formValue.name,
      email: formValue.email
    };

    api.post(API_URL + '/invitations/send', data)
      .then(() => {
        setIsSaving(false);

        notification.show({
          type: 'success',
          message: {
            translate: 'Invite has been sent successfully!'
          },
          autoDismiss: true
        });

        navigate('/account/company/members');
      })
      .catch((error: any) => {
        parseApiError(error, notification, null);
        setIsSaving(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255, t('field.error.name.maxlength'))
            .required(t('field.error.name.required')),
          email: Yup.string()
            .email(t('field.error.email.pattern'))
            .required(t('field.error.email.required')),
        })}
      >
        {({values, errors, touched, handleSubmit, handleChange, handleBlur}) => (
          <form onSubmit={handleSubmit}>
            <section className="fade-content">
              <div className="section-content form">
                <ul className="form-fieldset">
                  <li>
                    <div>
                      <TextField
                        id="name"
                        label={t('field.label.yourName')}
                        type="text"
                        error={errors.name}
                        value={values.name}
                        touched={touched.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </li>
                  <li>
                    <div>
                      <TextField
                        id="email"
                        label={t('field.label.email')}
                        type="text"
                        error={errors.email}
                        value={values.email}
                        touched={touched.email}
                        pattern="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="section-action">
                {isSaving && <Loader/>}
                {!isSaving && <div>
                  <button type="submit" className="button button-success"
                          onClick={() => handleSubmit()}
                  >
                    {t('actions.sendInvite')}
                  </button>
                </div>}
              </div>
            </section>
          </form>
        )}
      </Formik>
    </>
  );
}
