import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import Loader from "src/components/Loader";
import Header from "../../components/Header";
import ViewLoader from "../../components/ViewLoader";
import Footer from "../../components/Footer";
import api from "../../api";
import { API_FILES_URL, API_URL } from "../../constants";
import { parseApiError } from "../../utils";
import { useNotification } from "../../components/NotificationAlert/useNotification";
import ImagePreloader from "../../components/ImagePreloader";
import Filters from "./Filters";
import productPlaceholder from '../../assets/images/product-placeholder.jpeg';

interface Brand {
  id: number;
  mainPhoto: string;
  maxPrice :string;
  minPrice: string;
  name: string;
  productsCount: number;
  company: {
    id: number;
    name: string;
  };
}

export default function BrowseBrands() {
  const {t} = useTranslation();
  const notification = useNotification();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isMoreResults, setIsMoreResults] = useState(false);
  const [page, setPage] = useState(1);
  const [brands, setBrands] = useState<Brand[]>([]);

  const getBrands = (nextPage: number) => {
    const category = params.get('category');
    const subCategories = params.get('subCategory');

    setIsLoading(true);
    api.get(API_URL + '/brands', {
        params: {
          page: nextPage,
          category_id: category,
          subcategory_ids: subCategories ? subCategories?.split(',') : undefined
        }
      })
      .then((response) => {
        setBrands((state: any) => [...state, ...response.data.data]);
        setIsMoreResults(nextPage < response.data.meta.lastPage);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        parseApiError(error, notification, null);
      });
  };

  useEffect(() => {
    setBrands([]);
    setPage(1);
    getBrands(1);
  }, [params]);

  const selectBrand = (id: number) => {
    navigate(`/brand-view/${id}`);
  };

  const loadMore = () => {
    setPage(page + 1);
    getBrands(page + 1);
  };

  // @ts-ignore
  const user = useSelector((state) => state.user);
  const isConnected = user?.isConnected;
  const productTotal = brands.reduce((acc: number, b: Brand) => acc + b.productsCount, 0);
  const brandTotal = brands.length;

  return (
    <div className="view-wrapper">
      <Header/>
      <ViewLoader/>
      <div className="view view-content header-margin browse">
        <section className="browse-brands">
          {isLoading && <div className={`section-loader ${isLoading ? 'show' : ''}`}>
            <Loader />
          </div>}

          <div className="view-inner" style={{ textAlign: 'center' }}>
            <h1 className="dash dash-gold">
              {!!brands.length && <span>
              {t('browse.title.results', { productTotal, brandTotal  })}
            </span>}
              {!brands.length && <span>
                {t('browse.title.noResults')}
            </span>}
            {isLoading && <span>
                {t('browse.title.default')}
            </span>}
            </h1>
            <div className="columns">
              <div>
                <Filters />
              </div>
              <div>
                {!!brands.length && <div className="grid">
                  <div className="grid-list">
                    {brands.map(brand => (
                      <div key={brand.id} className="old-brand-card" onClick={() => selectBrand(brand.id)}>
                          <div className="old-brand-card-header">
                            <div className="inner">
                              <ImagePreloader url={
                                brand.mainPhoto ? API_FILES_URL + brand.mainPhoto : productPlaceholder
                              } />
                            </div>
                          </div>
                          <div className="old-brand-card-body">
                            <Link to={`/brand-view/${brand.id}`}>
                              {brand.name}
                            </Link>
                            {brand.company && <div>
                              <small>
                                {t('general.distributedBy')}
                              </small>
                              <a>
                                {brand.company.name}
                              </a>
                            </div>}
                          </div>
                          <div className="old-brand-card-footer">
                            <small>
                              {brand.productsCount}
                            </small>
                            {/*<div ng-if="$ctrl.isUserConnected" className="badge badge-large">*/}
                            {/*  <span ng-bind-html="brand.product.price"></span>*/}
                            {/*</div>*/}
                          </div>

                      </div>
                    ))}
                  </div>
                  {isMoreResults && <div className="grid-more">
                    {isLoading && <Loader ng-if="$ctrl.isLoadingMore" />}
                    {!isLoading && <a className="button gold" onClick={loadMore}>
                      {t('actions.loadMoreResults')}
                    </a>}
                  </div>}
                </div>}
              </div>
            </div>
          </div>
        </section>
        {!isConnected && <section className="browse-cta">
          <div className="section-inner" style={{ textAlign: 'center' }}>
            <h2>Hey stranger come join us!</h2>
            <div className="content">
              <div>Don\'t be shy and come see how we change the way to do business online!</div>
            </div>
            <div className="actions">
              <Link className="button button-large" to="/features">
                {t('actions.seeFeatures')}
              </Link>
              <Link className="button button-large gold" to="/signup">
                {t('actions.signupFree')}
              </Link>
            </div>
          </div>
        </section>}
      </div>

      <Footer/>
    </div>
  );
}
