import * as React from "react";
import { useTranslation } from "react-i18next";

export default function ConfirmModal({ title, text, onClose, confirm, confirmBtnText }: {
  title: string;
  text: string;
  confirmBtnText?: string;
  onClose?: () => void;
  confirm?: () => void;
}) {
  const {t} = useTranslation();

  return (
    <div className="modal modal-review-offer" style={{overflow: "scroll"}}>
      <div className="modal-window" style={{ width: "50%" }}>
        <div className="modal-window-header">
          <strong>
            {title}
          </strong>
          <a className="icon" onClick={onClose}>
            <i className="icon-modal-close"/>
          </a>
        </div>
        <div>
          <div>
            <div className="modal-window-body" style={{ padding: "30px 15px" }}>
              <span>
                {text}
              </span>
            </div>
            <div className="modal-window-footer">
              <button type="button" className="button" onClick={onClose}>
                <span>
                  {t('actions.cancel')}
                </span>
              </button>
              <button type="button" className="button button-primary" onClick={confirm}>
                <span>
                   {confirmBtnText || "Submit"}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
