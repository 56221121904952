import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import TabBar from "../../../components/TabBar";
import api from "../../../api";
import { API_URL } from "../../../constants";
import TextField from "../../../components/Textfield";
import Avatar from "../../../components/Avatar";
import { parseApiError } from "../../../utils";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import Loader from "../../../components/Loader";

export default function OfferMessages() {
  const {t} = useTranslation();
  const notification = useNotification();

  const match = useMatch('/account/offers/:id/:section');
  const id = match?.params.id;
  const currentSection = match?.params.section || 'offer';
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<any[]>([]);

  const tabs = [
    {
      section: 'offer',
      translate: 'account.offers.tabs.offer',
      url: `/account/offers/${id}`
    },
    {
      section: 'message',
      translate: 'account.offers.tabs.message',
      url: `/account/offers/${id}/message`
    }
  ];

  useEffect(() => {
    setIsLoading(true);
    api.get(API_URL + '/offers/' + id + '/messages')
      .then(function (res) {
        const data = res.data.data;
        setMessages(
          data
            .sort((a: any, b: any) => a.createdAt - b.createdAt ? 1 : -1)
            .map((m: any) => ({
              ...m,
              createdAt: moment(m.createdAt).format('YYYY-MM-DD hh:mm A')
            }))
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        parseApiError(error, notification, null);
      });
  }, [id]);

  const initialValues = {
    message: ''
  };

  const onSubmit = (values: any, {resetForm}: any) => {
    const data = {
      text: values.message
    };
    api.post(API_URL + '/offers/' + id + '/messages', data)
      .then(function (res) {
        const data: any = res.data.data;
        setMessages((state: any) => [...state, {
          ...data,
          shortText: data.text,
          createdAt: moment(data.createdAt).format('YYYY-MM-DD hh:mm A')
        }]);
        resetForm();
      })
      .catch((error) => {
        parseApiError(error, notification, null);
      });
  };

  // @ts-ignore
  const user = useSelector((state) => state.user);
  const currentUserId = user?.profile?.id;

  return (
    <>
      <TabBar tabs={tabs} active={currentSection}/>
      <section className="fade-content" style={{margin: '0 auto', maxWidth: '960px'}}>
        <div className="section-header dash dash-after dash-primary">
          <h1 style={{marginBottom: '0', color: '#444', textTransform: 'uppercase'}}>
            {t('account.offers.message.header.title')}
          </h1>
        </div>
        <div className="section-content">
          {isLoading && <Loader/>}
          {!messages.length && !isLoading && <div>
            <p style={{fontSize: '18px', lineHeight: '27px'}}>This offer doesn't have any messages yet!</p>
          </div>}
          {!!messages.length && !isLoading && <div>
            {messages.map((message: any) => (
              <div key={message.id} className={`message-item ${currentUserId === message.sender.id ? 'reverse' : ''}`}>
                <div className="item-avatar">
                  <Avatar
                    model={{
                      ...message.sender,
                      image: message.sender.avatar
                    }}
                    className="avatar-small"
                  />
                </div>
                <div className="item-body">
                  <strong>
                    {message.sender.name}
                  </strong>
                  <p>
                    {message.shortText}
                  </p>
                </div>
                <div>
                  <span style={{color: '#aaa'}}>
                    {message.createdAt}
                  </span>
                </div>
              </div>
            ))}
          </div>}

          <div className="message-form">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={onSubmit}
              validationSchema={Yup.object().shape({
                message: Yup.string()
                  .max(255, t('field.error.name.maxlength'))
                  .required(t('field.error.name.required')),
              })}
            >
              {({values, errors, touched, handleSubmit, handleChange, handleBlur}) => (
                <form className="form-body" onSubmit={handleSubmit}>
                  <div className="form-body-message">
                    <TextField
                      id="message"
                      label=""
                      type="text"
                      error={errors.message}
                      value={values.message || ''}
                      touched={touched.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div>
                    <button type="submit" className="button button-success" onSubmit={handleSubmit as any}>
                      {t('button.label.send')}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>

        </div>
      </section>
    </>
  );
}
