import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

interface ImageEditorCropProps {
  mode: {
    width: number;
    height: number;
  };
  zoom: number;
  image: File;
}

interface Original {
  width: number;
  height: number;
}

interface Current {
  width: number;
  height: number;
  top: number;
  left: number;
}

interface Drag {
  x: number;
  y: number;
  top: number;
  left: number;
  width: number;
  height: number;
}

interface Source {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface Output {
  height: number;
  imageType: string;
  mode: string;
  quality: number;
  width: number;
}

interface SaveCropProps {
  upload: {
    height: number;
    imageType: string;
    mode: string;
    quality: number;
    width: number;
  }[];
  callback: Function;
}

export interface CropResult {
  [key: string]: {
    url: string;
    blob: Blob;
  }
}

export interface Ref {
  saveCrop: (data: SaveCropProps) => void;
}

const ImageEditorCrop = forwardRef<Ref, ImageEditorCropProps>(({ mode, zoom, image }, ref) => {
  const cropRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [original, setOriginal] = useState<Original>({} as Original);
  const [current, setCurrent] = useState<Current>({} as Current);
  const [drag, setDrag] = useState<Drag | null>(null);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [position, setPosition] = useState({
    top: 0.5,
    left: 0.5
  });
  const [origin, setOrigin] = useState({
    top: 0.5,
    left: 0.5
  });
  const [isSaving, setIsSaving] = useState(false);

  const updateOrigin = useCallback(() => {
    if (cropRef.current) {
      setOrigin({
        top: Math.floor((cropRef.current.clientHeight - mode.height) * 0.5),
        left: Math.floor((cropRef.current.clientWidth - mode.width) * 0.5)
      });
    }
  }, [mode]);

  const renderImage = useCallback(() => {
    const currentTmp = {} as Current;

    if(original.width < original.height) {
      currentTmp.width = original.width * zoom;
      currentTmp.height = Math.round(currentTmp.width * (original.height / original.width));
    } else {
      currentTmp.height = original.height * zoom;
      currentTmp.width = Math.round(currentTmp.height * (original.width / original.height));
    }

    currentTmp.top = (currentTmp.height - mode.height) * position.top;
    currentTmp.left = (currentTmp.width - mode.width) * position.left;

    if (imgRef.current) {
      imgRef.current.style.top = (origin.top - currentTmp.top) + 'px';
      imgRef.current.style.left = (origin.left - currentTmp.left) + 'px';
      imgRef.current.style.width = currentTmp.width + 'px';
      imgRef.current.style.height = currentTmp.height + 'px'
    }

    setCurrent(currentTmp);
  }, [zoom, original, position, mode, origin]);

  const onDown = useCallback((e: MouseEvent | TouchEvent) => {
    e.preventDefault();

    if (e.target) {
      setDrag({
        x: typeof (e as TouchEvent).touches !== 'undefined' ? (e as TouchEvent).touches[0].pageX : (e as MouseEvent).clientX,
        y: typeof (e as TouchEvent).touches !== 'undefined' ? (e as TouchEvent).touches[0].pageY : (e as MouseEvent).clientY,
        top: (e.target as HTMLInputElement).offsetTop,
        left: (e.target as HTMLInputElement).offsetLeft,
        width: (e.target as HTMLInputElement).offsetWidth,
        height: (e.target as HTMLInputElement).offsetHeight
      });
    }

  }, []);

  const onMove = useCallback((e: any) => {
    if(drag) {
      let x = typeof e.touches !== 'undefined' ? e.touches[0].pageX : e.clientX,
        y = typeof e.touches !== 'undefined' ? e.touches[0].pageY : e.clientY,
        top = {} as any,
        left = {} as any;

      top = {
        pos: drag.top + (y - drag.y),
        min: origin.top - (current.height - mode.height),
        max: origin.top
      };

      left = {
        pos: drag.left + (x - drag.x),
        min: origin.left - (current.width - mode.width),
        max: origin.left
      };

      if (current.height < mode.height) {
        if(top.pos > top.min) {
          top.pos = top.min;
        } else if(top.pos < top.max) {
          top.pos = top.max;
        }
      }
      else {
        if (top.pos < top.min) {
          top.pos = top.min;
        } else if (top.pos > top.max) {
          top.pos = top.max;
        }
      }

      if (current.width < mode.width) {
        if (left.pos > left.min) {
          left.pos = left.min;
        } else if (left.pos < left.max) {
          left.pos = left.max;
        }
      }
      else {
        if(left.pos < left.min) {
          left.pos = left.min;
        } else if (left.pos > left.max) {
          left.pos = left.max;
        }
      }

      const positionTmp = { ...position };

      if (current.height - mode.height === 0) {
        positionTmp.top = 0;
      } else {
        positionTmp.top = 1 - ((top.pos - top.min) / (current.height - mode.height));
      }

      if (current.width - mode.width === 0) {
        position.left = 0;
      } else {
        positionTmp.left = 1 - ((left.pos - left.min) / (current.width - mode.width));
      }

      setPosition(positionTmp);
    }

    return;
  }, [drag, position, origin, current, mode]);

  const onUp = useCallback(() => {
    setDrag(null);
  }, []);

  useEffect(() => {
    const canvas = document.createElement('canvas');
    const reader = new FileReader();
    let temp = new Image();
    let ctx: CanvasRenderingContext2D | null = null;

    reader.readAsDataURL(image);

    reader.onload = function (e) {
      let original = {} as Original;

      updateOrigin();

      if (typeof e.target?.result === "string") {
        temp.src = e.target.result;
      }

      temp.onload = function () {
        original = {
          width: temp.naturalWidth,
          height: temp.naturalHeight
        };

        canvas.width = original.width;
        canvas.height = original.height;

        ctx = canvas.getContext('2d');
        ctx?.drawImage(temp, 0, 0, original.width, original.height);

        if (imgRef.current) imgRef.current.src = canvas.toDataURL('image/jpeg');

        setOriginal(original);
        setCanvas(canvas);
      };
    };
  }, [image, updateOrigin]);

  useEffect(() => {
    const img = imgRef.current;

    if (img) {
      img.addEventListener('mousedown', onDown);
    }

    document.addEventListener('mousemove', onMove);
    document.addEventListener('mouseup', onUp);
    document.addEventListener('resize', updateOrigin);

    return () => {
      if (img) {
        img.removeEventListener('mousedown', onDown);
      }
      document.removeEventListener('mousemove', onMove);
      document.removeEventListener('mouseup', onUp);
      document.removeEventListener('resize', updateOrigin);
    }
  }, [onDown, onUp, onMove, updateOrigin]);

  useEffect(() => {
    if (original.height && !isSaving) {
      renderImage();
    }
  }, [zoom, original, renderImage, isSaving]);

  const generateImage = function(mode: string, source: Source, output: Output): Promise<{
    mode: string;
    url: string;
    blob: Blob
  }> {
    return new Promise((resolve, reject) => {
      let ctx: CanvasRenderingContext2D | null = null;
      let x,
        y,
        w,
        h,
        quality = output.quality ? output.quality : 1;

      if(source.width < output.width) {
        w = source.width;
        x = 0;
      } else {
        w = output.width;
        x = source.x;
      }

      if(source.height < output.height) {
        h = source.height;
        y = 0;
      } else {
        h = output.height;
        y = source.y;
      }

      if (canvas) {
        canvas.width = w;
        canvas.height = h;

        ctx = canvas.getContext('2d');
        // @ts-ignore
        ctx?.drawImage(imgRef.current, x, y, source.width, source.height);
        canvas.toBlob(function(blob: Blob | null) {
          if (blob) {
            let url = URL.createObjectURL(blob);

            resolve({
              mode: mode,
              url: url,
              blob: blob
            });
          } else {
            reject();
          }
        }, output.imageType, quality);
      } else {
        reject();
      }
    });
  };

  const saveCrop = (data: SaveCropProps) => {
    let source: Source;
    let ratio;
    let result: CropResult = {};

    setIsSaving(true);

    for(let key in data.upload) {
      if (data.upload.hasOwnProperty(key)) {
        ratio = data.upload[key].width / mode.width;

        source = {
          x: -Math.abs(current.left) * ratio,
          y: -Math.abs(current.top) * ratio,
          width: current.width * ratio,
          height: current.height * ratio
        };

        let output = data.upload[key];

        generateImage(data.upload[key].mode, source, output).then(function (response: {
          mode: string;
          url: string;
          blob: Blob;
        }) {
          result[response.mode] = {
            url: response.url,
            blob: response.blob
          };

          setIsSaving(false);

          if (parseInt(key) === data.upload.length - 1) {
            if (typeof data.callback === 'function') {
              data.callback(result);
            }
          }
        })
          .catch(() => {
            setIsSaving(false);
          });
      }
    }
  };

  useImperativeHandle(ref, () => {
    return {
      saveCrop: (data: SaveCropProps) => {
        saveCrop(data);
      }
    }
  });

  return (
    <div ref={cropRef} className="photo-crop">
      <div className="dimension" style={{ width: mode.width + 'px', height: mode.height + 'px' }}></div>
      <img ref={imgRef} alt="" />
    </div>
  );
});

export default ImageEditorCrop;
