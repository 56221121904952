import { default as React } from "react";
import { Outlet, useMatch } from "react-router-dom";
import TabBar from "../../../components/TabBar";

export default function Brands() {
  const match = useMatch('/account/brands/:id/:lastPart');
  const currentSection = match?.params.lastPart;
  const id = match?.params.id;
  const isNew = id === 'new';

  const tabs = [
    {
      section: 'profile',
      translate: 'account.brand.tabs.profile',
      url: `/account/brands/${id}/profile`
    },
    ...(isNew ? [] : [{
      section: 'products',
      translate: 'account.brand.tabs.products',
      url: `/account/brands/${id}/products`
    },
      {
        section: 'materials',
        translate: 'account.brand.tabs.materials',
        url: `/account/brands/${id}/materials`
      }]),
  ] as any;

  return (
    <>
      {currentSection && <TabBar tabs={tabs} active={currentSection}/>}
      <div className="view-inner">
        <Outlet />
      </div>
    </>
  );
}
