import { Trans, useTranslation } from "react-i18next";
import * as React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "../../../components/Textfield";
import { useEffect } from "react";
import api from "../../../api";
import { API_FILES_URL, API_URL } from "../../../constants";
import { parseApiError } from "../../../utils";
import { useParams } from "react-router-dom";
import { useNotification } from "../../../components/NotificationAlert/useNotification";
import { useState } from "react";

export default function BrandMaterials() {
  const {t} = useTranslation();
  const params = useParams();
  const notification = useNotification();

  const id = params.id;

  const [initialValues, setInitialValues] = useState<{ materialDesign: string }>({
    materialDesign: ''
  });
  const [brand, setBrand] = useState<any>({});

  useEffect(() => {
    api.get(API_URL + '/brands/' + id)
      .then(function (res) {
        const data = res.data.data;

        setInitialValues({
          materialDesign: data.materialDesign
        });
        setBrand(data);
      })
      .catch((error) => {
        console.log(error);
        parseApiError(error, notification, null);
      });

  }, [id]);

  const onSubmit = (formValue: any, { setErrors }: any) => {
    const data = {
      ...brand,
      materialDesign: formValue.materialDesign,
    };

    api.put(API_URL + '/brands/' + id, data)
      .then(function () {
        // setIsSaving(false);
        notification.show({
          type: 'success',
          message: {
            translate: 'Brand has been successfully upd!'
          },
          autoDismiss: true
        });

        // navigate('/account/brands');
      })
      .catch((error) => {
        console.log(error);
        parseApiError(error, notification, null, setErrors);
        // setIsSaving(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        materialDesign: Yup.string()
          .max(255, t('field.error.website.maxlength'))
          .required(t('field.error.website.required')),
      })}
    >
      {({values, errors, touched, handleSubmit, handleChange, handleBlur}) => (
        <form className="section-inner fade-content" onSubmit={handleSubmit}>
          <div className="section-header dash dash-after dash-primary">
            <h1>
              {t('account.brand.materials.header.title')}
            </h1>
            <div>
              <Trans i18nKey={'account.brand.materials.header.text'}/>
            </div>
          </div>
          <div className="section-content form">
            <ul className="form-fieldset">
              <li>
                {/*// ng-repeat="material in $ctrl.data.brand.marketingMaterials track by $index"*/}
                {/*<div>*/}
                {/*  /!*<textfield*!/*/}
                {/*  /!* data-name="name"*!/*/}
                {/*  /!* ui-id="name"*!/*/}
                {/*  /!* ui-type="text"*!/*/}
                {/*  /!* ui-label="{{ \'field.label.name\' | translate }}"*!/*/}
                {/*  /!* ui-error="{{ $ctrl.data.form.error.name | translate }}"*!/*/}
                {/*  /!* ui-change-callback="$ctrl.validateField(\'name\')"*!/*/}
                {/*  /!* ui-blur-callback="$ctrl.validateField(\'name\')"*!/*/}
                {/*  /!* ng-model="$ctrl.data.form.value.name"*!/*/}
                {/*  /!* ng-model-options="$ctrl.data.modelOptions.default"*!/*/}
                {/*  /!* ng-required="true"*!/*/}
                {/*  /!* ng-maxlength="255">*!/*/}
                {/*  /!*</textfield>*!/*/}
                {/*</div>*/}
                <div>
                  <TextField
                    id="materialDesign"
                    label={t('field.label.url')}
                    type="text"
                    error={errors.materialDesign}
                    value={values.materialDesign}
                    touched={touched.materialDesign}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // validate-pattern="url"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div className="section-action">
            {/*<loader ng-if="$ctrl.isSaving"></loader>*/}
            <div ng-if="!$ctrl.isSaving">
              <button type="submit" className="button button-success">
                {t('actions.saveChanges')}
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
