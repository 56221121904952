import * as React from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/Header";
import ViewLoader from "../../components/ViewLoader";
import Footer from "../../components/Footer";

export default function PrivacyPolicy() {
  const {t} = useTranslation();

  return (
    <div className="view-wrapper">
      <Header/>

      <ViewLoader/>

      <div className="view header-margin privacy">
        <div className="view-inner">
          <div className="section-header dash dash-after">
            <h1>
              {t('privacy.header.title')}
            </h1>
            <div>
              {t('privacy.header.text')}
            </div>
          </div>
          <div className="section-content" style={{textAlign: 'left'}}>
            <p><strong>Privacy Policy</strong></p>
            <p>Husboro Inc. d/b/a Husboro (&#x201c;<strong>we,</strong>&#x201d; &#x201c;<strong>us,</strong>&#x201d;
              &#x201c;<strong>our,</strong>&#x201d; or &#x201c;<strong>Husboro</strong>&#x201d;) understands how important your
              privacy is to you and takes your right to privacy very seriously. This privacy policy (the, &#x201c;<strong>Privacy
                Policy</strong>&#x201d;) will allow you to learn more about how we collect, retain, use and disclose your
              personal identifiable information (&#x201c;<strong>Personal Information</strong>&#x201d;). This Privacy Policy
              further explains your access rights to your Personal Information and the safeguards Husboro implements to
              protect it.</p>
            <p><strong>Collection of Personal Information</strong></p>
            <p>&#x201c;<strong>Personal Information</strong>&#x201d; means any information about an identifiable individual
              by
              which the individual can be identified. The types of Personal Information we collect may include, your
              name,
              email address, address, phone number, gender, date of birth, language preference, IP address, location,
              browser information, username, password, payment card numbers, purchase, return and exchange
              history/information, any other information you may choose to provide and any other information necessary
              for
              the provision of certain services.</p>
            <p><strong>Provided Information</strong></p>
            <p>We receive and store any information, including Personal Information that you provide directly to us from
              that of which you have entered on our Website or have otherwise provided us through any other means. For
              example, we collect information, including Personal Information, when you communicate with us.</p>
            <p><strong>Information Collected Automatically (Cookies, Web Beacons, Tags and Other Similar
              Technologies)</strong></p>
            <p>When you access or use our Website, we may use automatic data collection technologies such as
              &#x201c;Cookies,&#x201d; &#x201c;Web Beacons,&#x201d; &#x201c;Tags&#x201d; and other similar technologies to collect
              and
              store information about you (collectively, the &#x201c;<strong>Technologies</strong>&#x201d;). Our use of
              these
              Technologies may include the sending/storing of cookies onto your device\u2019s hard drive or its memory
              by
              your use of our Website, Services or through our correspondences. The information collected through the
              use
              of these Technologies may include information which identifies your use of the Website, such as, however
              not
              limited to, the type of browser you use, your IP address, access times, referring and exit pages, language
              selection/preferences, pages viewed, URLs, keywords, date and time, what sections of our Website you
              visit,
              information relating to the device you use to access the Website (hardware model, operating system and
              version, unique device identifiers and mobile network information) and other similar information. These
              Technologies allow us to improve our Website and Services ultimately leading to a better overall user
              experience.</p>
            <p>For information purposes, cookies are small text files that are stored on your devices hard drive or in
              its
              memory which assist your device with remembering your settings from when you last visited a website. Web
              Beacons and Tags are electronic images which assist with delivering cookies and are used in combination
              with
              same, to help us understand, record and obtain additional information relating to your user activity and
              actions, on or relating to, the Website, our Services and our correspondences.</p>
            <p>The information obtained through the use of the Technologies may be shared with or obtained by, third
              parties, on our behalf. In the event that the third parties receive information we collected, the third
              parties use of any information that we collected and transmitted to them shall be governed by the privacy
              policies of those third parties. (For more details, please see the section Third-Party Services)</p>
            <p>As you may already know, most web browsers are pre-set to allow for the acceptance and installation of
              cookies. As a user, you can freely reject or disable cookies by managing the applicable settings of your
              web
              browser. Should you decide to disable or reject cookies, your ability to visit the Website shall not be
              impacted. However, it is important to note that this change in settings could potentially affect the
              functionality of certain areas of our Website.</p>
            <p><strong>Third-Party Services</strong></p>
            <p>From time to time, we may use the services of third-parties to, amongst other things, serve
              advertisements
              on our behalf across the Internet and analyze the information collected by the use of the Technologies for
              our marketing and advertising purposes. These third parties may collect non-identifiable information about
              you such as your user activity and actions, on or relating to, the Website, our services and our
              correspondences. In order to render their services, the third parties may use Technologies as defined in
              the
              Privacy Policy.</p>
            <p><strong>Third Party Links</strong></p>
            <p>The Husboro Website may link to other websites which are not under its control. Husboro shall not be
              responsible for the privacy practices or content of such other websites. Husboro advises its users to
              carefully review the privacy policies of all other websites that Husboro may link to as each and every of
              those websites policies may differ from that of Husboro\u2019s.</p>
            <p><strong>The Identified Purposes for Collection of Personal Information</strong></p>
            <p>We, at Husboro, limit the collection of your Personal Information to that which is necessary for the
              purposes identified in this Privacy Policy. We shall collect, use and disclose this information only in
              accordance with this Privacy Policy and as otherwise permitted or required by law.</p>
            <p>We collect, use and disclose Personal Information in order to provide you with goods and services and/or
              information relating to same.</p>
            <p>Husboro collects Personal Information for the purposes identified hereinbelow, namely to:</p>
            <ul style={{margin: '20px 0 0 18px'}}>
              <li style={{display: 'list-item'}}>identify and contact you when, you place orders, make purchases and/or
                returns and to fulfill the services relating to same, such as the processing of payments and the
                shipment/delivery of products and/or services;
              </li>
              <li style={{display: 'list-item'}}>communicate with and/or notify you about, our products and services,
                warranty, warranty claims, recalls or safety issues;
              </li>
              <li style={{display: 'list-item'}}>provide you with on-going service(s);</li>
              <li style={{display: 'list-item'}}>respond to inquiries;</li>
              <li style={{display: 'list-item'}}>carry on commercial relations;</li>
              <li style={{display: 'list-item'}}>maintain and improve our internal business processes, the contents of
                our
                Website and our services;
              </li>
              <li style={{display: 'list-item'}}>improve the content of our Website;</li>
              <li style={{display: 'list-item'}}>market and advertise;</li>
              <li style={{display: 'list-item'}}>meet our legal and regulatory requirements; and</li>
              <li style={{display: 'list-item'}}>(where applicable) process, review and respond to an employment
                application.
              </li>
            </ul>
            <p><strong>Use and Disclosure of Your Personal Information</strong></p>
            <p>Personal Information shall not be used without your consent for purposes other than, the identified
              purposes for collection mentioned in this Privacy Policy or in accordance with applicable laws. Before
              using
              your Personal Information for new or additional purposes, we will obtain your prior consent. In limited
              circumstances, we may also use and disclose your Personal Information to third parties when:</p>
            <ul style={{margin: '20px 0 0 18px'}}>
              <li style={{display: 'list-item'}}>we use the services of third parties for the provision of services or
                supplies for our Wesbite and services. We may use these services or require such supplies for the
                fulfillment of our users requests or inquiries, marketing and advertising, data analysis, payment
                processing, and the management of our programs and operations;
              </li>
              <li style={{display: 'list-item'}}>it becomes necessary so as to protect the safety, property, or other
                rights
                of Husboro, our clients, our users and our employees;
              </li>
              <li style={{display: 'list-item'}}>we along with another party irrespective of its corporate form and
                regardless of whether it is a related, affiliated or an arms-length party, undertake negotiations for or
                proceed to the execution of a business transaction, such as, any financing, corporate reorganization,
                merger, acquisition, sale of assets or of equity, or any other type of corporate transaction, relating
                to
                any or all of the Husboro business; and/or
              </li>
              <li style={{display: 'list-item'}}>when otherwise required or permitted by law.</li>
            </ul>
            <p>These third parties have been carefully selected by Husboro. Husboro shall require of them, through the
              use
              of contracts or other measures, that they agree to protect and limit the use and disclosure of your
              Personal
              Information in accordance with the present Privacy Policy and the applicable Canadian legislation, both
              during and after the completion of a business transaction and/or the provision of services or supplies.
              Certain of our service providers or suppliers may store, transfer and process Personal Information on
              servers located outside of Canada, for example, the United States. These other jurisdictions data and
              privacy protection laws differ from those of Canada. As such, Personal Information may be subject to
              access
              requests, pursuant to the laws of those other jurisdictions, from governments, courts, or law enforcement
              agencies. For example, it may be requested of Husboro to share Personal Information in response to valid
              demands or requests from government authorities, courts and law enforcement officials in such other
              jurisdictions. Subject to applicable laws in these other jurisdictions, Husboro will use reasonable
              efforts
              to ensure that appropriate protections are in place to require our service providers and suppliers to
              maintain protections on Personal Information that are equivalent to those that apply in Canada.</p>
            <p><strong>Exception</strong></p>
            <p>This Privacy Policy shall not be interpreted or construed as imposing any limits whatsoever on Husboro in
              relation to the collection, use or disclosure of the following information:</p>
            <ul style={{margin: '20px 0 0 18px'}}>
              <li style={{display: 'list-item'}}>publicly available information; and</li>
              <li style={{display: 'list-item'}}>non-personal information.</li>
            </ul>
            <p><strong>Security</strong></p>
            <p>Husboro, while committed to safeguarding your Personal Information, cannot guarantee or warrant the
              security of any information transmitted over the Internet. Husboro, however does and shall take reasonable
              measures to safeguard your Personal Information from loss, theft, misuse, alteration and unauthorized
              access.</p>
            <p><strong>Account Information, Accuracy and Access</strong></p>
            <p>Our users may, at any time by logging into their online account, access, update, correct or delete
              certain
              categories of Personal Information available and found on same. Our users may also access, update, correct
              or delete their Personal Information by emailing our Privacy Officer at (info@husboro.com). Our Privacy
              Officer shall respond to a user\u2019s request within the delays provided under applicable laws. In all
              cases, our users access rights shall be subject to the possibility of the separating of their Personal
              Information from that of others and our identification of the requesting user. Unless otherwise prohibited
              by law, reasons shall be provided when access is denied.</p>
            <p><strong>Marketing and Promotional Communications</strong></p>
            <p>Husboro complies with Canada\u2019s anti-spam legislation (CASL) and will not send you electronic
              communications in violation of this law. Husboro\u2019s Platform allows you to register an online account
              and expressly consent to receiving electronic communications. These electronic communications may at times
              take the form of marketing and promotional communications. Husboro will ensure that each e-mail includes
              an
              opt-out feature and instructions on how to unsubscribe if you no longer wish to receive such e-mails from
              Husboro. Should you not provide your express consent to receive electronic communications, Husboro will
              only
              communicate with you during the time period and for the limited purposes permitted under CASL.</p>
            <p>If you do not wish to receive mail, phone calls or emails, you may contact us at info@husboro.com or at
              (insert phone number if you will have one) or unsubscribe to the receiving of e-mails via the opt-out link
              included in each such e-mail.</p>
            <p><strong>Consent</strong></p>
            <p>By visiting, accessing or using the Husboro.com Website and/or domain name, and/or any other linked
              pages,
              features, content, and/or application services offered from time to time by Husboro in connection with the
              foregoing (collectively, the &#x201c;<strong>Website</strong>&#x201d;), and/or when you purchase and/or use
              any
              of our services, you acknowledge that you accept the application of the practices and policies relating to
              your privacy as detailed herein.</p>
            <p>Given the foregoing, you acknowledge and agree that the Privacy Policy shall apply to all Personal
              Information collected, used or disclosed by Husboro when you:</p>
            <ul style={{margin: '20px 0 0 18px'}}>
              <li style={{display: 'list-item'}}>request information or assistance;</li>
              <li style={{display: 'list-item'}}>purchase any product or service made available on our Website;</li>
              <li style={{display: 'list-item'}}>complete a customer profile or create an account on our Website;</li>
              <li style={{display: 'list-item'}}>inquire, register or provide information by telephone, email, internet,
                our
                Website or by any other means;
              </li>
              <li style={{display: 'list-item'}}>apply for employment positions specifically featured on our Website and
                relating to Husboro;
              </li>
              <li style={{display: 'list-item'}}>issue a complaint relating to a product or service made available on
                our
                Website and/or make a warranty claim; and/or
              </li>
              <li style={{display: 'list-item'}}>participate and use any other feature of the Website that we may offer
                from
                time to time.
              </li>
            </ul>
            <p><strong>Changes to the Privacy Policy</strong></p>
            <p>Husboro may, from time to time, revise and amend this Privacy Policy and reserves its right to do so.
              Should Husboro make any revision(s) and amendment(s), Husboro will notify you by updating and indicating
              at
              both the top and the bottom of the Privacy Policy, the date in which the revision(s) and amendment(s) have
              come into effect thereby clearly displaying the effective date. In certain circumstances, we may provide
              you
              with additional notice, which may, for example purposes only, take the form of a statement on our homepage
              or by us sending you an email notification. As a way of remaining informed on our current privacy
              practices
              and policies, it is recommended that you review our Privacy Policy upon your access to our Website.</p>
            <p>This Privacy Policy shall not apply to the practices of any entity that Husboro does not own and/or
              control
              (including any third-party products or services used for the logging into or which are integrated with,
              the
              Website), or to individuals that are not managed or employed by Husboro.</p>
            <p><strong>Contact Us</strong></p>
            <p>If you have any questions about this Privacy Policy, please contact our Privacy Officer at:
              info@husboro.com.</p>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
