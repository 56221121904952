import { useFormikContext, getIn } from 'formik';

interface Address {
  address: '',
  country: '',
  province: '',
  zipCode: '',
  postalCode: '',
  city: '',
}

function useFormProperty(prefix: string) {
  const { values, setFieldValue } = useFormikContext();

  const properties: Address[] = getIn(
    values,
    prefix,
  );

  const addNewProperty = () => {
    const newProperty: Address = {
      address: '',
      country: '',
      province: '',
      zipCode: '',
      postalCode: '',
      city: '',
    };

    const newProperties = [...properties, newProperty];

    setFieldValue(prefix, newProperties);
  };

  const removeProperty = (propertyIndex: number) => {
    const newProperties = [...properties];

    newProperties.splice(propertyIndex, 1);

    setFieldValue(prefix, newProperties);
  };

  return {
    properties,
    addNewProperty,
    removeProperty,
  };
}

export default useFormProperty;
