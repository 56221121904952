interface ToggleFieldProps {
  id: string;
  options: {
      value: string;
      label: string;
    }[];
  label: string;
  error?: string;
  info?: string | React.ReactNode;
  disabled?: boolean;
  touched?: boolean;
  value: string;
  onChange?: Function;
  onBlur?: Function;
  onFocus?: Function;
}

export default function ToggleField({
  id,
  label,
  info,
  value,
  options,
  error,
  disabled,
  touched = false,
  onFocus = () => {},
  onChange = () => {},
  onBlur = () => {}
}: ToggleFieldProps) {
  return (
    <div className={`field toggle ${error && touched ? 'has-error' : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <div id={id} className="toggle-inner">
        {options.map(option => (
          <div key={option.label} className={option.value?.toString() === value?.toString() ? 'selected' : ''}>
            <input
              name={id}
              type="radio"
              value={option.value}
              onFocus={onFocus as any}
              onChange={onChange as any}
              onBlur={onBlur as any}
              disabled={disabled}
              tabIndex={0}
            />
            <span>{option.label}</span>
          </div>
        ))}
      </div>
      {info && !error && <small className="field-info">{info}</small>}
      {error && touched && <small className="field-error">{error}</small>}
    </div>
  );
}
