import * as React from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/Header";
import ViewLoader from "../../components/ViewLoader";
import Footer from "../../components/Footer";

export default function TermsOfService() {
  const {t} = useTranslation();

  return (
    <div className="view-wrapper">
      <Header/>

      <ViewLoader/>

      <div className="view header-margin terms">
        <div className="view-inner">
          <div className="section-header dash dash-after">
            <h1>
              {t('terms.header.title')}
            </h1>
            <div>
              {t('terms.header.text')}
            </div>
          </div>
          <div className="section-content" style={{textAlign: 'left'}}>
            <p><strong>Effective Date: The Terms of Service was last revised on October 18, 2017.</strong></p>
            <p><strong>TERMS OF SERVICE</strong></p>
            <p><strong>PLEASE READ THESE TERMS OF SERVICE (&#x201c;Agreement&#x201d; or &#x201c;Terms&#x201d;) CAREFULLY BEFORE
              USING THE SERVICES OFFERED BY HUSBORO INC. D/B/A (&#x201c;Husboro&#x201d;).</strong></p>
            <p><strong>PREAMBLE</strong></p>
            <p>These Terms are an agreement between Husboro (including any of its direct or indirect subsidiaries) and
              you
              (i.e., a natural person or any other legal entity) (including, if applicable, any direct or indirect
              subsidiaries) who amongst others, develops, distributes or supplies products for sale to electronic
              retailers using or through the Husboro Platform (the &#x201c;Brand(s)&#x201d;) or between Husboro and you
              (i.e.,
              a natural person or any other legal entity) (including, if applicable, any direct or indirect
              subsidiaries)
              who uses the Husboro Platform to gain access to the Brand\u2019s products (&#x201c;Buyer(s)&#x201d;). Buyer
              and
              Brand shall be individually referred to herein as &#x201c;you&#x201d; and collectively as &#x201c;your&#x201d;.
              Please note that if you are an individual who is accessing and using the Husboro Platform on behalf of a
              corporation or any other legal entity, you represent and warrant to Husboro that you have the required
              authority to bind that corporation or other legal entity to this Agreement.</p>
            <p>This Agreement sets forth the legally binding terms and conditions for your use of the Husboro Platform
              and
              shall govern such use regardless of how you access or use it. This Agreement shall apply when these Terms
              are made available and/or are posted and/or linked, on or to, the Husboro Platform.</p>
            <p>To the extent where Husboro enacts separate guidelines, protocols, policies, rules, regulations or terms
              of
              service, use or sale setting forth additional or different terms and/or conditions (the &#x201c;Additional
              Terms&#x201d;), these Additional Terms along with these Terms shall apply concurrently with your use of the
              Husboro Platform. The Additional Terms, unless expressly stipulated otherwise therein, shall prevail over
              these Terms in the event of a conflict.</p>
            <p>All capitalized terms utilized in these Terms shall have the meaning ascribed to them herein.</p>
            <p>The &#x201c;Husboro Platform&#x201d; includes the definition ascribed to herein for the defined term
              Websites &amp; Applications as well as the Services offered thereof. It shall also be understood to
              include,
              without limitation, product search and categorization tools, curation and optimization tools for the
              showcasing of Brands\u2019 products to Buyers, including, without limitation, interfaces and APIs provided
              to access the Husboro Platform, as well as Husboro\u2019s white glove service offering marketing and sales
              services to Brands and Buyers (collectively, the &#x201c;Husboro Platform&#x201d;).</p>
            <p>The (&#x201c;Websites &amp; Applications) shall constitute any online service location, (for example, the
              Husboro.com website, any other website and/or domain name and/or any other linked pages and/or mobile
              application) and shall include without limitation, any or all features, downloads, content, plug-ins,
              widgets and/or other services offered from time to time by Husboro in connection with the foregoing.</p>
            <p>The (&#x201c;Services&#x201d;) offered by Husboro under these Terms or any Additional Terms include various
              services to facilitate the connection and sales between Brands and Buyers, including without limitation,
              providing a forum allowing the connection and direct negotiations between Brands and Buyers as well as the
              placing of orders reflecting the agreement between the Brand and the Buyer and managing, through the
              Husboro
              Platform, matters relating to the initial shipping and payment of the services or products. Any such
              services offered by Husboro are referred to in these Terms as the &#x201c;Services&#x201d;. Any new features
              or
              tools which are added to the current Services shall also be subject to these Terms and form an integral
              part
              of the present defined term.</p>
            <p>You hereby represent that you acknowledge and agree that by visiting, accessing or using any part of the
              Services provided by or through the Husboro Platform, you will comply with and be legally bound by, the
              Terms and the Additional Terms, which were, subject to any updates to these Terms or any Additional Terms,
              in effect at the time of such visit, access or use. For purposes of clarity, Husboro considers these Terms
              and the Additional Terms essential elements for its business relationship with you and the making of its
              platform available to you. Should you fail to agree with these Terms, you shall have no Licence to use the
              Husboro Platform and should cease using it immediately.</p>
            <ol style={{margin: '20px 0 0 18px'}}>
              <li style={{display: 'list-item'}}>
                <p><strong>REGISTRATION, USER INFORMATION, ONLINE ACCOUNT &amp; USER PROFILE</strong></p>
                <ol style={{margin: '20px 0 0 18px', listStyleType: 'upper-alpha'}}>
                  <li style={{display: 'list-item'}}><p><u>Registration</u> The Husboro Platform is available only to
                    individuals who are at least 18 years of age and thereby capable of forming a binding contract. In
                    order to gain access to the Husboro Platform, you will first have to register by creating an online
                    account and an online user profile (collectively, the &#x201c;Online Account&#x201d;) through our
                    registration process which is available on <u>www.husboro.com</u>.</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>User Information</u> You acknowledge
                    that, when
                    registering for your Online Account, it is important to provide Husboro with current, complete, true
                    and accurate information and you agree to maintain such information as current as possible. As such,
                    Husboro reserves its right to terminate or suspend your Online Account should it have reasonable
                    grounds to believe that you have failed to provide current, complete, true and accurate information
                    and/or that the information provided violates these Terms, the Additional Terms or any applicable
                    law.
                    More generally, Husboro equally reserves its right, without advance notice to terminate, suspend
                    and/or bar access to your Online Account for any other reason in its sole discretion.</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>Online Account &amp; User Profile</u> You
                    will
                    be required when registering your Online Account, to provide information relating to the products
                    you
                    intend to market and advertise using the Husboro Platform. Such marketing and advertising must
                    comply
                    with the provisions of these Terms and any Additional Terms relating to marketing and advertising on
                    or through the Husboro Platform.</p><p>Husboro does not review or monitor Online Account(s). Should
                    a
                    dispute arise regarding whether an Online Account has been created by an authorized representative,
                    Husboro shall have the right (but not the obligation) to resolve such dispute and in so doing may
                    take, in its sole discretion, any appropriate measure to resolve such dispute.</p><p>You hereby
                    acknowledge and agree that the registration of an Online Account is personal to you and in that
                    respect you shall not be permitted, without the express written consent of an officer of Husboro, to
                    sell, assign or transfer by any other means, your Online Account or any rights related thereto. You
                    understand and agree that you are solely responsible for: i) the maintenance of the confidentiality
                    of
                    your Online Account by any and all means, including limiting the access thereto and the safeguarding
                    of your username and password; ii) the reporting of any unauthorized use of your Online Account and
                    in
                    the event of such unauthorized use the immediate disclosure to Husboro in writing; and iii) any and
                    all activities relating to your Online Account, including unauthorized activity.</p><p>You agree to
                    release Husboro (including any of its officers, directors, partners, equity holders, shareholders,
                    subsidiaries, affiliates, employees, agents, successors, assigns and any other individual in any
                    capacity whatever) from any and all liability resulting in any loss or damage of any kind, for any
                    reason whatever, relating to any termination, suspension, bar or unauthorized creation of your
                    Online
                    Account.</p></li>
                </ol>
              </li>
              <li style={{display: 'list-item', paddingTop: '20px'}}>
                <p><strong>PRIVACY</strong></p>
                <p>Please review our privacy practices and policies at\xA0[NTR: Insert hyperlink to Privacy Policy on
                  website]\xA0(the &#x201c;Privacy Policy&#x201d;) so as to understand the extent of the information we
                  collect, disclose and use through your use of the Husboro Platform and Services. Our privacy policy is
                  incorporated into these Terms forming an integral part hereof. You acknowledge and agree that in
                  consideration for the Services and your Licence to use the Husboro Platform, your use of the Husboro
                  Platform and the Services shall be, in addition to these Terms and any Additional Terms, subject to
                  our
                  Privacy Policy.</p>
              </li>
              <li style={{display: 'list-item', paddingTop: '20px'}}>
                <p><strong>OWNERSHIP OF: THE HUSBORO PLATFORM, CONTENT, RESERVATION OF RIGHTS &amp; LIMITED LICENCE
                  RIGHTS</strong></p>
                <ol style={{margin: '20px 0 0 18px', listStyleType: 'upper-alpha'}}>
                  <li style={{display: 'list-item'}}><p><u>Ownership of Husboro Platform</u> The Husboro Platform, which
                    shall be understood as including all versions of such platform, whether past, present or future, is
                    owned and controlled exclusively by Husboro or in certain instances by Husboro\u2019s licensors and
                    other third parties (collectively &#x201c;their&#x201d; or &#x201c;them&#x201d;; licensors and other third
                    parties &#x201c;Others&#x201d;).</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>Ownership of Content</u> All of the
                    content,
                    information or other materials relating to Husboro and Others as well as to their products and
                    services, which are generated or made available by any means whatever, by them or Others, through
                    the
                    Husboro Platform, wherein such content, information and other materials include without limitation,
                    any and all material, items, text, data, files, graphics, images, designs, music, pictures, videos,
                    audio, technology and software, websites, instructions, illustrations, the &#x201c;look and feel&#x201d;
                    of the Husboro Platform, works of authorship of any kind which include copyright, copyrightable
                    materials, the copyright in the design selection, compilation, assembly, arrangement, and
                    enhancement
                    of the Content on the Husboro Platform, trademark(s) (whether registered or not), material(s) that
                    can
                    or may be trademarked, and all other forms of intellectual property (all of the foregoing
                    hereinafter
                    collectively referred to as the &#x201c;Content&#x201d;) is owned and controlled exclusively by Husboro
                    or
                    by Others whereby these latter individuals or entities have permitted Husboro\u2019s use of such
                    Content.</p><p>Unless otherwise explicitly approved and authorized in writing by an officer of
                    Husboro, no Content shall be, in whole or in part, copied, reproduced, altered, distributed or used
                    in
                    any other way or form by/through any other possible means. You acknowledge that certain aspects of
                    Content are protected by copyright, trademark, and other laws of Canada and in some instances of
                    other
                    countries.</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>Limited Licence Rights</u> Subject to
                    your
                    strict compliance with these Terms as well as with the Additional Terms, such as but not limited to,
                    the respect of the intellectual property rights of Husboro and of Others as stipulated herein,
                    Husboro
                    grants you a personal, limited, revocable, non-exclusive, non-transferable, non-assignable,
                    non-sublicenceable licence to (i) download, access, view, and/or use the Husboro Platform; as well
                    as
                    to (ii) download, access, view, use, play, and/or print the Content; and in both such cases such
                    grant
                    of a Limited Licence is for your own personal, non-commercial use only on a computer or mobile
                    device
                    (the &#x201c;Limited Licence&#x201d;). This Limited Licence does not give you any ownership or any
                    intellectual property interest (including moral rights) in the Content, whether such Content belongs
                    to Husboro or Others.</p><p>Husboro reserves the right, in its sole discretion, without advance
                    notice, to immediately terminate or suspend your Limited Licence. In the event of such suspension or
                    termination, you agree to release Husboro (including any of its officers, directors, partners,
                    equity
                    holders, shareholders, subsidiaries, affiliates, employees, agents, successors, assigns and any
                    other
                    individual in any capacity whatever) from any and all liability resulting in any loss or damage of
                    any
                    kind, for any reason whatever, relating thereto.</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>Reservation of Rights</u> Husboro and
                    Others
                    reserve all rights not granted as to the Husboro Platform and Content. As such, these Terms and any
                    Additional Terms must be understood and interpreted as providing only narrow and limited grants of
                    rights to use and access the Husboro Platform and Content. No right or Licence, other than the
                    Limited
                    Licence granted under and pursuant to the terms and conditions herein, may be construed or
                    interpreted
                    by any means whatever, including by implication, under any legal theory or otherwise.</p></li>
                </ol>
              </li>
              <li style={{display: 'list-item', paddingTop: '20px'}}>
                <p><strong>USER-GENERATED CONTENT &amp; MARKS &amp; LICENCES</strong></p>
                <ol style={{margin: '20px 0 0 18px', listStyleType: 'upper-alpha'}}>
                  <li style={{display: 'list-item'}}><p><u>User-Generated Content (&#x201c;UGC&#x201d;)</u> You may post,
                    upload,
                    and/or contribute (&#x201c;post&#x201d; or &#x201c;posted&#x201d;) content to the Husboro Platform (which
                    may
                    include, without limitation, pictures, catalogs, price and inventory lists, messages, information
                    and/or other types of content) (&#x201c;User-Generated Content&#x201d; or &#x201c;UGC&#x201d;). For purposes
                    of clarity, UGC includes any content posted to the Husboro Platform.</p><p>You represent and warrant
                    that, with respect to any UGC you post onto the Husboro Platform, (i) you have the right to post
                    such
                    UGC, and (ii) such UGC, or its use by Husboro as contemplated by the Terms or any Additional Terms,
                    does not violate the Terms, applicable law, or the intellectual property, publicity, personality, or
                    other rights of others or imply any affiliation with or endorsement of you or your UGC by
                    Husboro.</p>
                    <p>Husboro has no obligation to monitor, review, or edit UGC. In all cases, Husboro reserves the
                      right
                      to remove or disable access to any UGC for any or no reason, including, without limitation, UGC
                      that, in Husboro\u2019s sole discretion, violates the Terms. Husboro may take these actions
                      without
                      providing you or any third party prior notice. Removal or disabling of access to UGC shall be at
                      Husboro\u2019s sole discretion, and Husboro does not promise to remove or disable access to any
                      specific UGC.</p><p>You are solely responsible for all UGC that you post onto the Husboro
                      Platform.
                      Husboro is not responsible for UGC nor does it endorse it. If anyone brings a claim against
                      Husboro
                      for any of your UGC, you agree to indemnify Husboro in the manner provided for by the
                      indemnification provisions found herein.</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>User-Marks (&#x201c;UM&#x201d;)</u> Names,
                    logos,
                    symbols, abbreviation, word, marks, slogans, nicknames, artwork, trademarks, trade names, service
                    marks, a combination of these items or any other similar item that were at any point in time, owned,
                    controlled, registered or applied for registration or neither, cleared for use by or licenced by you
                    (the &#x201c;User-Marks&#x201d;).</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>UGC &amp; UM Licences</u> In
                    consideration for
                    the rights granted to you, including the Limited Licence to use the Husboro Platform and the
                    provision
                    of Services under the Terms or any Additional Terms, you hereby agree to grant to Husboro and
                    represent and warrant that you have the right to grant to Husboro, i) a non-exclusive, irrevocable,
                    transferable, sub-licensable, fully paid, royalty-free, perpetual (or, in jurisdictions where
                    perpetuity is not permitted, for a term equal to the duration of the applicability of these Terms
                    plus
                    twenty (20) years), worldwide licence to use, display, reproduce, make available to the public,
                    publish, translate, modify, create derivative works from, and distribute any of your User-Generated
                    Content in connection with the Husboro Platform, through any medium, whether alone or in combination
                    with other content or materials, in any manner and by any means, method or technology, whether now
                    known or hereafter created (the &#x201c;User-Generated Licence&#x201d;) and ii) a non-exclusive,
                    irrevocable, transferable, sub-licensable, fully paid, royalty-free, perpetual (or, in jurisdictions
                    where perpetuity is not permitted, for a term equal to the duration of the applicability of these
                    Terms plus twenty (20) years), worldwide licence to use, display, reproduce any of your User-Marks
                    in
                    connection with the Husboro Platform, through any medium, whether alone or in combination with other
                    content or materials, in any manner and by any means, method or technology, whether now known or
                    hereafter created (the &#x201c;User-Marks Licence&#x201d;).</p><p>Save and except the rights
                    specifically
                    granted to Husboro herein, you retain ownership of all rights, including intellectual property
                    rights,
                    in the User-Generated Content and User- Marks. Where applicable and permitted under applicable law,
                    you also agree to waive any moral rights or the equivalent under applicable law. Such moral rights
                    include, without limitation, your right to be identified as the author of any content or the right
                    to
                    object to the treatment of any content.</p></li>
                </ol>
              </li>
              <li style={{display: 'list-item', paddingTop: '20px'}}>
                <p><strong>ORDERS</strong></p>
                <ol style={{margin: '20px 0 0 18px', listStyleType: 'upper-alpha'}}>
                  <li style={{display: 'list-item'}}><p><u>Order Processing</u> Following the negotiation phase between
                    the
                    Brand and the Buyer through the Husboro Platform, wherein such negotiations between the Buyer and
                    the
                    Brand lead to the exchange of their valid mutual consents thereto and form thereof a binding
                    agreement
                    amongst them (the &#x201c;Binding PO&#x201d;), Buyers must thereafter place, using and through the
                    Husboro
                    Platform, their order(s) for the products reflected in the Binding PO. Once an order of the Binding
                    PO
                    is placed on the Husboro Platform, the order(s) shall be charged, treated and processed by Husboro.
                  </p><p>For purposes of clarity, it is understood by the Buyer that the products made available on the
                    Husboro Platform are not to be considered offerings, but rather invitations from the Brands to the
                    Buyers to make the Brands an offer or offers for the products they have made available on the
                    Husboro
                    Platform.</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>Specifications</u> Husboro does its very
                    best,
                    without any or without creating any obligation on its part to do so, to ensure that Brands describe
                    every product as accurately as possible, however, human-error may or can occur and therefore,
                    Husboro
                    does not warrant the accuracy of any information of any kind whatever relating to the products
                    offered
                    by Brands. For purposes of clarity, it is understood by you that pricing, stocking and the
                    descriptions of the products are out of Husboro\u2019s control and is more appropriately in the
                    control of the Brands.</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>Cancellation</u> Husboro reserves the
                    right,
                    upon its review of any order, to cancel such order(s) for any reason, at its entire and sole
                    discretion. For information purposes only and without limiting Husboro\u2019s reservation/right of
                    cancellation, order(s) placed may be cancelled for, including without limitation, issues with stock,
                    errors or inaccuracies relating to the products pricing and/or the product itself or in any other
                    circumstance in which Husboro deems appropriate or for any other reason in which Husboro may be
                    instructed by the Brand or the Buyer (individually, the &#x201c;Issue&#x201d; or collectively, the
                    &#x201c;Issues&#x201d;). Husboro shall notify the Brand or Buyer by email should any Issue arise and
                    may,
                    where applicable, proceed to the cancellation of your order(s) and provide you with the
                    corresponding
                    refund. The cancellation policy of the Buyer shall be the policy which shall govern any cancellation
                    requested in relation to the Binding PO which has been processed through the Husboro Platform.</p>
                  </li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>Payment</u> The Buyer hereby acknowledges
                    and
                    agrees that upon its placing of an order for a Binding PO on and through the Husboro Platform, it
                    shall be charged by Husboro or Husboro\u2019s third party payment processing service providers, for
                    the benefit of the Brand and Husboro, thirty (30) days following the Buyer\u2019s placing of such
                    order. The Buyer\u2019s payment shall remain in Husboro\u2019s possession until the Brand i)
                    invoices
                    Husboro and ii) confirms that the ordered products have been shipped to the Buyer. Immediately upon
                    the shipment by the Brand of the Buyers ordered products placed through the Husboro Platform,
                    Husboro
                    shall have the right to set-off from the payment for such ordered products any applicable fees set
                    forth by these Terms or any Additional Terms that Husboro is entitled to and are payable by the
                    Brand
                    to Husboro. Husboro shall only be required to release the remaining payment to the Brand thirty (30)
                    days following the Buyer\u2019s payment to Husboro for the Binding PO.</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>Delivery</u> Husboro understands the
                    importance
                    of receiving your order(s) within the estimated delivery time and Husboro will do what it can,
                    within
                    the limits of commercial reasonability and without creating any additional obligation on its part,
                    to
                    assist you in obtaining from the Brand, your order(s) by the estimated delivery date. You
                    acknowledge
                    by the present Terms that the shipping process is beyond Husboro\u2019s control and beyond the scope
                    of the Services, and that such process is more appropriately in the control of the Brand.
                    Additionally, you acknowledge that delays may occur for reasons which are beyond the Brand\u2019s
                    control and only when and where applicable, beyond Husboro\u2019s control, and, in any and all
                    regards, you agree to hold Husboro (including any of its officers, directors, partners, equity
                    holders, shareholders, subsidiaries, affiliates, employees, agents, successors, assigns and any
                    other
                    individual in any capacity whatever) harmless from any and all liability resulting in any loss or
                    damage of any kind, for any reason whatever, relating to any such delays.</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>Risk of Loss</u> The applicable rights
                    that
                    shall govern the doctrine of the risk of loss shall be the terms and conditions which have been
                    negotiated and determined solely amongst and between the Buyer and the Brand in relation to their
                    Binding PO. For purposes of clarity, there shall be no transfer of ownership of any product(s) to
                    Husboro under this agreement or otherwise. However, only in the event that Husboro is or is deemed
                    by
                    a court of competent jurisdiction, to be in physical control of the shipment of a Brand\u2019s
                    product(s) or an Buyer\u2019s ordered product(s), then in such an event, it is understood that
                    ownership of the ordered product(s) and the risk of loss, shall transfer forthwith from Husboro to
                    the
                    Buyer or the Brand (as is applicable in that instance), upon the delivery of the ordered products to
                    the shipping carrier for delivery to the Buyer or the Brand (as is applicable in that instance).</p>
                  </li>
                </ol>
              </li>
              <li style={{display: 'list-item', paddingTop: '20px'}}>
                <p><strong>RETURN POLICY</strong></p>
                <p>The return policy of the Brand shall be the policy which shall govern any returns made or requested
                  in
                  relation to the Binding PO which has been ordered through the Husboro Platform. Consequently, Husboro
                  shall not accept any returns whatever even when such returns comply with those policies. It is the
                  responsibility of the Buyer to take up and seek the appropriate measures for any and all returns it
                  intends to make.</p>
                <p>If a product is defective, misrepresented or is laden with any other similar issue, Husboro
                  recommends
                  that the Buyer contact the Brand directly and forthwith upon the discovery of the purported issue. For
                  more information on the different Brands return policies, please consult the return policy information
                  provided by the Brand through the Husboro Platform.</p>
              </li>
              <li style={{display: 'list-item', paddingTop: '20px'}}>
                <p><strong>FEES FOR SERVICES</strong></p>
                <p>Husboro charges you (specifically the Brand) i) a fee of ten percent (10%) on the gross sale amount
                  of
                  a Binding PO (excluding applicable taxes and administration fees) and ii) administration fees in a
                  fixed
                  amount of 14.50$ USD which shall be charged as a one-time monthly fee, on a per calendar month basis,
                  irrespective of the number of Binding PO\u2019s placed on or through the Husboro Platform during a
                  specific calendar month and which shall only be charged during a specific calendar month if a Binding
                  PO
                  was placed on or through the Husboro Platform during that specific calendar month (the &#x201c;Husboro
                  Fee&#x201d;). The Husboro Fee relates to the Services provided by Husboro to you in connection with and
                  relating to the Binding PO placed through or in connection with the Husboro Platform and Husboro has
                  the
                  right to require payment of the Husboro Fee for such Services.</p>
                <p>For purposes of clarity, and solely for illustration purposes, the application of the Husboro Fee can
                  be demonstrated through the two following examples, Example #1: should five (5) Binding PO\u2019s be
                  placed on or through the Husboro Platfom, during a specific calendar month, and the aggregate of those
                  five (5) Binding PO\u2019s have a gross sale amount of 500.00$ (excluding applicable taxes and
                  administration fees), the Husboro Fee on that placed order shall equate to 50.00$ plus the
                  administration fee of 14.50$ USD. Example #2: Assuming that Example #1 is modified and that five (5)
                  Binding PO\u2019s were placed over a two (2) calendar month period, then the Husboro Fee would equate
                  to
                  50.00$ plus the administration fee of 29.00$ USD (hence the 14.50$ USD administration fee multiplied
                  by
                  the number of calendar months during which Binding PO\u2019s were placed on or through the Husboro
                  Platform).</p>
                <p>Should you use the Services, you agree to pay the Hubsoro Fee as described and defined in these Terms
                  or any Additional Terms. Husboro shall therefore, debit the Husboro Fee it is entitled to receive
                  under
                  these Terms or any Additional Terms, directly from the payments received for Brands, from the orders
                  (i.e. the Binding PO or Binding PO\u2019s) placed by E- tailers through the Husboro Platform. Husboro
                  may from time to time, change the Husboro Fee for its Services. These changes may cause there to be,
                  amongst others, new charges for the use of the Services. Your further use after any such change to the
                  Husboro Fee for the Services shall be deemed to constitute your acceptance of the changes. Prior to
                  effecting any such changes, Husboro will notify you of any changes.</p>
              </li>
              <li style={{display: 'list-item', paddingTop: '20px'}}>
                <p><strong>HUSBORO PLATFORM RULES AND CONDUCT</strong></p>
                <ol style={{margin: '20px 0 0 18px', listStyleType: 'upper-alpha'}}>
                  <li style={{display: 'list-item'}}>
                    <p><u>Rules</u> As a user of the Husboro Platform, whether as a Brand or Buyer (individually
                      &#x201c;User&#x201d; or collectively &#x201c;Users&#x201d;), you are required to respect the Husboro
                      Platform rules (&#x201c;Rules&#x201d;). Consequently, your use of the Husboro Platform is subject to
                      the
                      present Terms, any Additional Terms and the Rules and you shall be restricted from and agree not
                      to
                      do, through or in connection with the Husboro Platform, any of the following:</p>
                    <ol style={{margin: '20px 0 0 18px', listStyleType: 'lower-roman'}}>
                      <li style={{display: 'list-item'}}>submit UGC onto the Husboro Platform or engage in any activity,
                        that
                        is illegal, inappropriate, derogatory, insulting, defamatory, lewd, obscene, pornographic,
                        sexually explicit or otherwise intolerable to Husboro at the latter\u2019s sole and entire
                        discretion;
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>affect or damage, by any means, the devices
                        of
                        any other User or the Husboro Platform itself (for example, Users must not submit harmful bugs,
                        viruses, Trojan horses, malicious code, spyware, or the likes onto the Husboro Platform, to
                        Husboro or to any other User);
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>use any meta tags, meta data or any other
                        &#x201c;hidden text&#x201d; utilizing any of Husboro\u2019s trademarks, whether registered or not,
                        without the express written consent an officer of Husboro;
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>copy or use the Husboro Platform, in whole
                        or in
                        part, by means of reverse engineering, decompiling, reverse assembling, disassembling, modifying
                        or manipulating source or object code or any software or processes of the Husboro Platform, for
                        the purposes of, without limitation, creating a rival or competing platform or more generally,
                        to
                        adversely affect the Husboro business;
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>engage in any harmful activity that
                        interferes
                        with the proper operation of the Husboro Platform or the use thereof by a User, or otherwise
                        causes harm to Husboro or any of its Users;
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>interfere with, tamper with, probe or
                        attempt to
                        evade, any security feature of i) the Husboro Platform, ii) Husboro\u2019s network or systems,
                        or
                        iii) any other technological measure which has been implemented so as to protect the Husboro
                        business;
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>attempt to access, use or tamper with,
                        non-public
                        areas of the Husboro Platform (including the technical systems, networks and softwares of both
                        Husboro and that of third parties which the latter shall be considered to form an integral part
                        of
                        the Husboro Platform);
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>attempt to collect, download or extract any
                        Content or more generally any information of any kind from the Husboro Platform through the use
                        of, including without limitation, framing techniques, data mining or gathering tools, scrapers,
                        crawlers, bots or the likes;
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>copy, modify, reproduce, archive, sell,
                        lease,
                        rent, exchange, create derivative works from, publish, display, disseminate, distribute,
                        broadcast, retransmit, circulate or transfer to any third party or on any third-party
                        application
                        or website, or otherwise use or exploit the Content in any way for any purpose except as
                        expressly
                        permitted by these Terms, any Additional Terms, the Rules, the Code of Conduct or with the prior
                        written consent of an officer of Husboro or, in the case of Content belonging to any other
                        party,
                        the owner of the Content;
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>share your own or other Users information
                        (i.e.
                        financial information or personal information such as their name, address, email, age, phone
                        number, etc.) by means other than those prescribed by Husboro or otherwise required for the
                        smooth
                        operation of the Husboro Platform;
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>otherwise violate or encourage, facilitate,
                        assist or allow by any other means, whether directly or indirectly, any other individual to
                        violate, any of the foregoing Rules, Terms, any Additional Terms or the Code of Conduct.
                      </li>
                    </ol>
                  </li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}>
                    <p><u>Conduct</u> Furthermore, as a User of the Husboro Platform, you are expected and required to
                      follow a certain form of conduct (the, &#x201c;Code of Conduct&#x201d;), which is as follows:</p>
                    <ol style={{margin: '20px 0 0 18px', listStyleType: 'lower-roman'}}>
                      <li style={{display: 'list-item'}}>any or all of your activities on the Husboro Platform, must at
                        all
                        times, be appropriate and remain respectful of all other Users;
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>you must either have ownership of, or have
                        all
                        the necessary permission to use and make available, the Content that you make available on the
                        Husboro Platform and you must not by any means, infringe on the intellectual property rights of
                        others;
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>you must credit those who have, any rights
                        in,
                        contributed to, appear in or are referred to in, your Content, and obtain their permission to
                        submit same onto the Husboro Platform;
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>you must ensure that any picture, video, or
                        image
                        you decide to upload onto the Husboro Platform is of your products and/or of yourself, unless
                        the
                        appropriate permissions have otherwise been obtained by you;
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>you must not misrepresent your UGC,
                        products,
                        identity or affiliations;
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>you must, at all times, conduct yourself
                        appropriately when dealing with other Users or with Husboro representatives;
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>you must immediately report in writing to a
                        Husboro representative, any violations of these Terms, any Additional Terms, the Rules and the
                        Code of Conduct.
                      </li>
                    </ol>
                  </li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}>
                    <p><u>Violation &amp; Recourse</u> Should Husboro reasonably believe that a User has violated any of
                      the foregoing Rules or the Code of Conduct, Husboro may, at its sole and entire discretion, and
                      for
                      purposes of clarity, without creating any legal obligation on its part to do so, take any recourse
                      or legal recourse it deems appropriate to enforce the Rules and the Code of Conduct.</p>
                  </li>
                </ol>
              </li>
              <li style={{display: 'list-item', paddingTop: '20px'}}>
                <p><strong>RESTRICTIVE COVENANTS &amp; RIGHTS &amp; REMEDIES</strong></p>
                <ol style={{margin: '20px 0 0 18px', listStyleType: 'upper-alpha'}}>
                  <li style={{display: 'list-item'}}>
                    <p><u>Exclusivity</u> Neither you nor any of your subsidiaries or affiliates shall, directly or
                      indirectly, through any officer, director, partner, shareholder, equity holder, affiliate,
                      subsidiary, employee, agent, representative, contractor, outsourcer or otherwise (i) use or
                      encourage any person or entity (including any of its officers, directors, partners, shareholders,
                      equity holders, affiliates, subsidiaries, employees, agents, representatives, contractors,
                      outsourcers or otherwise), to use, a platform other than the Husboro Platform, which relates to
                      (x)
                      the Services or any services similar to those offered by Husboro, which relate to (y) the Husboro
                      Platform, or which include or involve (z) any other transaction or dealing the consummation of
                      which
                      would prevent, impede or have an adverse effect on the consummation of the offering of Services
                      contemplated herein; or (ii) assist, participate in any discussions or negotiations regarding, or
                      furnish to any other person or entity any information with respect to, or otherwise cooperate in
                      any
                      way with, or assist or participate in, facilitate or encourage any effort or attempt by any other
                      person or entity to do or seek to do, any of the foregoing (the &#x201c;Exclusivity&#x201d;).</p><p>In
                    exchange for good and valuable consideration, namely the use of the Limited Licence and the
                    provision
                    of Services by Husboro, you covenant and agree that, for a period of one (1) year following the last
                    day on which the Limited Licence ends, you shall continue to be bound by the Exclusivity.</p>
                  </li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}>
                    <p><u>Non-Competition</u> You acknowledge and agree that Husboro would be irreparably harmed if you,
                      including without limitation any of your officers, directors, partners, shareholders, equity
                      holders, affiliates, subsidiaries, employees, agents, representatives, contractors, outsourcers or
                      otherwise, were or became, a competitor of Husboro, by circumventing the Services or providing
                      services similar to those provided through or in connection with the Husboro Platform (the
                      &#x201c;Non-Competition&#x201d;).</p><p>In exchange for good and valuable consideration, namely the
                    use
                    of the Limited Licence and the provision of Services by Husboro, you covenant and agree that, for a
                    period of two (2) years following the last day on which the Limited Licence ends, you shall continue
                    to be bound by the Non-Competition.</p>
                  </li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}>
                    <p><u>Non-Solicitation</u> You acknowledge and agree that Husboro would be irreparably harmed if
                      you,
                      including without limitation any of your officers, directors, partners, shareholders, equity
                      holders, affiliates, subsidiaries, employees, agents, representatives, contractors, outsourcers or
                      otherwise, solicit or endeavor to entice away from Husboro or the Husboro Platform, or otherwise
                      interfere with the business relationship of Husboro or the Husboro Platform with any Buyer or
                      Brand
                      (the &#x201c;Non-Solicitation&#x201d;).</p><p>In exchange for good and valuable consideration, namely
                    the use of the Limited Licence and the provision of Services by Husboro, you covenant and agree
                    that,
                    for a period of two (2) years following the last day on which the Limited Licence ends, you shall
                    continue to be bound by the Non-Solicitation.</p>
                  </li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}>
                    <p><u>Confidentiality</u> &quot;Confidential Information&quot; means any information which either is
                      expressly or implicitly of a confidential nature relating in any which way to the Services to you,
                      including, without limitation, the following:</p><p>prices and price lists (i.e.
                    manufacturer\u2019s
                    suggested retail prices, cost prices, whole sale prices), inventories, inventory lists, lists of
                    Brands and Buyers, identity of or information relating to Brands and Buyers (including their sales
                    representatives, agents, distributors and suppliers) customer lists, customer information, current
                    and
                    anticipated customer requirements, strategies, marketing materials, trade terms, products, supply
                    sources, supply contracts, discounts and volume discounts, rebates and rebate programs, and any
                    other
                    information in relation with any of the foregoing, whether directly or indirectly.</p><p>Husboro
                    will
                    not disclose, sell, share or rent your Confidential Information to third parties in ways different
                    from what is disclosed in the Terms as well as in the Privacy Policy and except as required in the
                    course of providing the Services.</p><p>Once Confidential Information is disclosed, provided or
                    otherwise made available to you through or in connection with the Husboro Platform, you are solely
                    responsible for the protection of the Confidential Information, now being that such Confidential
                    Information is in your possession. If anyone brings a claim against Husboro for any breach or
                    violation of the Confidentiality provision, you agree to indemnify Husboro in the manner provided
                    for
                    by the indemnification provisions found herein.</p><p>You acknowledge and agree that Husboro would
                    be
                    irreparably damaged if you, including without limitation any of your officers, directors, partners,
                    shareholders, equity holders, affiliates, subsidiaries, employees, agents, representatives,
                    contractors, outsourcers or otherwise, disclose, provide or otherwise make available any
                    Confidential
                    Information received, disclosed or otherwise made available to you.</p><p>During the term of the
                    Limited Licence and for a period of five (5) years following the termination of the Limited Licence
                    regardless of how same occurred (which you and Husboro consider to be a reasonable period of time in
                    the circumstances to protect the Confidential Information disclosed, provided or otherwise made
                    available to you through or in connection with the Husboro Platform and the provision of the
                    Services), you covenant and agree that you shall not and cannot use Confidential Information,
                    whether
                    directly or indirectly, for any purpose other than such Confidential Information is provided,
                    disclosed or otherwise made available to you, and that purpose being for your use of such
                    Confidential
                    Information through or in connection with the Husboro Platform (the
                    &#x201c;Confidentiality&#x201d;).</p>
                    <p>You further acknowledge and agree that the Restrictive Covenants and agreements set forth in
                      these
                      Terms or any Additional Terms were a material inducement for Husboro to enter into this Agreement
                      and to perform its obligations thereunder, and that Husboro would not obtain the benefit of the
                      bargain set forth in the Agreement if you breach or threaten to breach, any or all of the
                      Exclusivity, the Non-Competition, the Non-Solicitation and the Confidentiality provisions
                      (the &quot;Restrictive Covenants&quot;).</p>
                  </li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}>
                    <p><u>Rights &amp; Remedies</u> If an Buyer or Brand (a &quot;Restricted Person&quot;) breaches, or
                      threatens to commit a breach of, any of the provisions of this section, namely the Restrictive
                      Covenants, not only shall the Limited Licence granted to the Restricted Person be revoked and
                      terminated automatically, but Husboro shall additionally have the following rights and remedies,
                      each of which rights and remedies shall be independent of the others and severally enforceable,
                      and
                      each of which is in addition to, and not in lieu of, any other rights and remedies available at
                      law
                      or in equity:</p>
                    <ol style={{margin: '20px 0 0 18px', listStyleType: 'lower-roman'}}>
                      <li style={{display: 'list-item'}}>the right and remedy to have the Restrictive Covenants
                        specifically
                        enforced by any court of competent jurisdiction, it being agreed that any breach or threatened
                        breach of the Restrictive Covenants would cause irreparable injury to Husboro, and that money
                        damages would not provide an adequate remedy to Husboro; and
                      </li>
                      <li style={{display: 'list-item', paddingTop: '20px'}}>the right and remedy to require the
                        Restricted
                        Person to account for and pay over to Husboro any profits, monies, accruals, increments or other
                        benefits derived or received by the Restricted Person as the result of any transaction or
                        dealing
                        constituting a breach of the Restrictive Covenants.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li style={{display: 'list-item', paddingTop: '20px'}}>
                <p><strong>NON RESTRICTIVE DEALINGS WITH BuyerS &amp; BRANDS</strong></p>
                <p>Husboro transacts with a wide array of Brands and Buyers. Husboro\u2019s pool of users is what allows
                  Husboro to offer what it does best, hence offer the Services by way of the Husboro Platform. Husboro
                  wants to create a community which puts the unique qualities of all of its users at the forefront. In
                  so
                  doing, Husboro is not capable of restricting its dealings with certain Brands and certain Buyers all
                  the
                  while creating the network that Husboro\u2019s users are ultimately registering to the Husboro
                  Platform
                  for. It is therefore understood that Husboro shall not be, and that nothing in these Terms or any
                  Additional Terms shall be interpreted or construed under any legal theory or otherwise, to restrict
                  Husboro\u2019s dealings with any E- tailer or Brand.</p>
              </li>
              <li style={{display: 'list-item', paddingTop: '20px'}}>
                <p><strong>LINKS TO THIRD PARTY WEBSITES OR RESOURCES</strong></p>
                <p>The Husboro Platform may at times contain links to third-party websites or resources. Husboro
                  provides
                  these links for convenience purposes only and shall not be considered responsible for the content,
                  products or services on or available from those websites or resources or links displayed on such
                  websites. You acknowledge sole responsibility for and assume all risk arising from, your use of any
                  third-party websites or resources.</p>
              </li>
              <li style={{display: 'list-item', paddingTop: '20px'}}>
                <p><strong>WARRANTY DISCLAIMER RELATING TO THE SERVICES &amp; PRODUCTS</strong></p>
                <p>THE SERVICES AND CONTENT ARE PROVIDED &#x201c;AS IS,&#x201d; WITHOUT WARRANTY OF ANY KIND. WITHOUT
                  LIMITING
                  THE FOREGOING, HUSBORO AND ANY OF ITS OFFICERS, DIRECTORS, PARTNERS, EQUITY HOLDERS, SHAREHOLDERS,
                  AFFILIATES, SUBSIDIARIES, EMPLOYEES, AGENTS, SUCCESSORS, ASSIGNS AND OTHER REPRESENTATIVES, DO NO
                  WARRANT THAT: (A) THE SERVICES AND THE HUSBORO PLATFORM WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR
                  TIME OR LOCATION; (B) ANY DEFECTS, ERRORS OR OMISSIONS WILL BE CORRECTED OR THAT THE SERVICES OR
                  CONTENT
                  IS ERROR-FREE; OR (C) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE HUSBORO PLATFORM IS FREE OF
                  VIRUSES OR OTHER HARMFUL COMPONENTS. YOUR USE OF THE SERVICES AND THE HUSBORO PLATFORM IS SOLELY AT
                  YOUR
                  OWN RISK AND PERILS.</p>
                <p>YOU ASSUME ALL RISKS RELATING TO YOUR ONLINE OR OFFLINE COMMUNICATIONS AND INTERACTIONS WITH OTHER
                  USERS OF THE HUSBORO PLATFORM AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT
                  OF YOUR USE OF THE SERVICES OR THE HUSBORO PLATFORM. IT IS UNDERSTOOD BY YOU THAT HUSBORO DOES NOT
                  SCREEN OR INQUIRE INTO THE BACKGROUND OF ANY USERS OF THE HUSBORO PLATFORM AND SERVICES. HUSBORO MAKES
                  NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS OF THE SERVICES. YOU AGREE TO TAKE
                  REASONABLE PRECAUTIONS IN ALL COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE HUSBORO PLATFORM
                  AND SERVICES AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT WITH AS A RESULT OF YOUR USE
                  OF THE HUSBORO PLATFORM OR SERVICES.</p>
                <p>THE PRODUCTS MADE AVAILABLE OR PROVIDED TO YOU THROUGH THE HUSBORO PLATFORM HAVE BEEN DONE SO BY THE
                  USER(S) OF THE HUSBORO PLAFORM ON THEIR OWN TERMS AND CONDITIONS. CONSEQUENTLY, ANY PURCHASE OF THOSE
                  PRODUCTS SHALL BE GOVERNED BY THE INDIVIDUAL USERS TERMS AND CONDITIONS, AND UNLESS OTHERWISE
                  SPECIFIED
                  EXPLICITLY IN WRITING BY HUSBORO, HUSBORO AND ANY OF ITS OFFICERS, DIRECTORS, PARTNERS, EQUITY
                  HOLDERS,
                  SHAREHOLDERS, AFFILIATES, SUBSIDIARIES, EMPLOYEES, AGENTS, SUCCESSORS, ASSIGNS AND OTHER
                  REPRESENTATIVES, DO NOT WARRANT THAT THE PRODUCTS WILL MEET YOUR EXPECTATIONS.</p>
                <p>YOU MAY BE ELIGIBLE FOR THE USERS WARRANTIES, WHETHER THOSE WARRATIES ARE MANUFACTURER\u2019S
                  WARRANTIES OR NOT, THAT APPEAR ON OR WITH THE PRODUCTS YOU BUY. EXCEPT FOR SUCH MANUFACTURER\u2019S
                  WARRANTIES, TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, HUSBORO DISCLAIMS ALL WARRANTIES,
                  EXPRESS
                  OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
                  PARTICULAR PURPOSE AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE. HUSBORO
                  DOES NOT WARRANT THAT THE PRODUCTS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. HUSBORO WILL NOT
                  BE
                  LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF ANY PRODUCTS.</p>
              </li>
              <li style={{display: 'list-item', paddingTop: '20px'}}>
                <p><strong>LIMITATIONS OF LIABILITY</strong></p>
                <ol style={{margin: '20px 0 0 18px', listStyleType: 'upper-alpha'}}>
                  <li style={{display: 'list-item'}}><p><u>Limitation as to Products</u> IN NO EVENT SHALL HUSBORO AND
                    ANY OF
                    ITS OFFICERS, DIRECTORS, PARTNERS, EQUITY HOLDERS, SHAREHOLDERS, AFFILIATES, SUBSIDIARIES,
                    EMPLOYEES,
                    AGENTS, SUCCESSORS, ASSIGNS AND OTHER REPRESENTATIVES, BE LIABLE UNDER CONTRACT, TORT, STRICT
                    LIABILITY, OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICE (INCLUDING, WITHOUT
                    LIMITATION, ANY CONTENT) (I) FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE
                    GOODS
                    OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND
                    WHATEVER
                    (HOWEVER ARISING), (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE
                    OF
                    ORIGINATION), (III) FOR YOUR RELIANCE ON THE SERVICE OR PRODUCT DESCRIPTIONS, OR (IV) FOR ANY DIRECT
                    DAMAGES IN EXCESS OF (IN THE AGGREGATE) THE GREATER OF A) AMOUNTS PAID OR PAYABLE BY YOU IN
                    CONNECTION
                    WITH THE SERVICE; OR B) ONE-HUNDRED CANADIAN DOLLARS ($100.00).</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>Limitation as to Users</u> AS A USER, YOU
                    HAVE
                    THE FREEDOM TO INTERACT DIRECTLY WITH OTHER USERS THROUGH THE HUSBORO PLATFORM. HUSBORO, MAY FROM
                    TIME
                    TO TIME, BUT SHALL HAVE NO OBLIGATION TO DO SO, MONITOR YOUR INTERACTIONS WITH OTHER USERS AND MAY
                    ALSO BECOME INVOLVED IN DISPUTES BETWEEN YOU AND OTHER USERS OF THE PLATFORM. HOWEVER, SUCH
                    MONITORING
                    AND INVOLVEMENT SHALL NOT BE INTERPRETED OR CONSTRUED AS CREATING AN OBLIGATION TO MONITOR USERS OR
                    TO
                    GET INVOLVED WITH DISPUTES INVOLVING USERS. AS A USER OF THE HUSBORO PLATFORM, YOU ARE SOLELY
                    RESPONSIBLE FOR YOUR INTERACTION WITH OTHER USERS OF THE HUSBORO PLATFORM, WHETHER ONLINE OR
                    OFFLINE.
                    HUSBORO IS NOT RESPONSIBLE OR LIABLE FOR THE CONDUCT OR CONTENT OF ANY USER.</p></li>
                </ol>
              </li>
              <li style={{display: 'list-item', paddingTop: '20px'}}>
                <p><strong>INDEMNIFICATION</strong></p>
                <p>You agree that Husboro (including any of its officers, directors, partners, equity holders,
                  shareholders, subsidiaries, affiliates, employees, agents, successors, assigns and any other
                  individual
                  in any capacity whatever) shall not be liable for any injury or damage caused by your violation or
                  breach of these Terms or any Additional Terms, to any user of the Husboro Platform or any other person
                  or entity, through your use of the Services or the Husboro Platform or in any other way in connection
                  therewith.</p>
                <p>You agree to, at all times, indemnify and save harmless Husboro, (including any of its officers,
                  directors, partners, equity holders, shareholders, subsidiaries, affiliates, employees, agents,
                  successors, assigns and any other individual in any capacity whatever) from and against all claims,
                  demands, losses, costs, damages, action, suits or other proceedings made, sustained, brought or made
                  upon Husboro in respect of any costs, expenses, loss, damage or injury, including death, and
                  reasonable
                  legal fees, arising out of any cause, whether direct or indirect, by reason of or in connection with
                  your (including any of your officers, directors, partners, equity holders, shareholders, subsidiaries,
                  affiliates, employees, agents, successors, assigns and any other individual in any capacity whatever)
                  negligent acts or omissions, breaches or violation of these Terms or any Additional Terms in
                  connection
                  with these Terms or the Additional Terms.</p>
              </li>
              <li style={{display: 'list-item', paddingTop: '20px'}}>
                <p><strong>GENERAL PROVISIONS</strong></p>
                <ol style={{margin: '20px 0 0 18px', listStyleType: 'upper-alpha'}}>
                  <li style={{display: 'list-item'}}><p><u>Termination</u> Husboro may, at its sole discretion,
                    immediately
                    suspend or terminate your access to all or part of the Husboro Platform, Content or Services at any
                    time, for any reason whatever, without notice or liability, effective immediately.</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>Survival</u> Upon the termination of
                    these
                    Terms, for any reason whatever, including, without limitation, the provisions relating to Restricted
                    Covenants, ownership provisions, Termination, Warranty Disclaimers, Limitations of Liability,
                    Dispute
                    Resolution, Governing Law and those provisions which by their very nature should survive
                    termination,
                    set forth herein, shall survive termination and shall continue in full force and effect.</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>Entire Agreement</u> These Terms and any
                    Additional Terms, together with the Privacy Policy, constitute the entire agreement among the
                    parties
                    with respect to the subject matter hereof and there are no conditions, promises, covenants,
                    agreements, representations, warranties or other provisions, express or implied, collateral,
                    statutory
                    or otherwise, relating to the subject matter hereof except as provided in these Terms or any
                    Additional Terms. No reliance is placed on, and no party has been induced to enter into or amend
                    these
                    Terms or any Additional Terms because of, any representation, opinion, advice or assertion of fact
                    made by any party to these Terms or the Additional Terms, or its directors, officers and agents, to
                    any other party to these Terms or the Additional Terms, or its directors, officers and agents,
                    except
                    to the extent that it has been reduced to writing and included as a term referred to in these Terms
                    or
                    in any Additional Terms. Accordingly, there shall be no liability, either in tort or in contract,
                    assessed in relation to any representation, opinion, advice or assertion of fact, except to the
                    extent
                    stated above.</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>Severability</u> If any provision of
                    these
                    Terms or the Additional Terms is determined by a court of competent jurisdiction to be invalid,
                    illegal or unenforceable in any respect, such determination shall not impair or affect the validity,
                    legality or enforceability of the remaining provisions hereof and each provision is hereby declared
                    to
                    be separate, severable and distinct.</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p>
                    <u>Interpretation &amp; Sections &amp; Headings</u> If two or more provisions of this Agreement are
                    deemed to conflict with each other\u2019s operation, it is understood that Husboro shall have the
                    sole
                    discretionary right to elect which provision shall remain in force. The division of these Terms or
                    the
                    Additional Terms into sections, paragraphs and subparagraphs and the insertion of headings are for
                    convenience and for reference purposes only and shall not affect the construction or interpretation
                    of
                    these Terms or the Additional Terms.</p></li>
                  <li style={{display: 'list-item', paddingTop: '20px'}}><p><u>Governing Law &amp; Jurisdiction</u> This
                    Agreement shall be construed, interpreted and enforced in accordance with, and the respective rights
                    and obligations of the parties shall be governed by, the laws of the Province of Quebec and the
                    federal laws of Canada applicable in such province, and each party hereby irrevocably and
                    unconditionally submits to the exclusive jurisdiction of Montreal, Quebec, Canada and all courts
                    competent to hear appeals therefrom in same jurisdiction.</p></li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
