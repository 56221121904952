import { Trans, useTranslation } from "react-i18next";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";

import ImagePreloader from "../../components/ImagePreloader";
import Avatar, { strToHex } from "../../components/Avatar";
import Header from "../../components/Header";
import ViewLoader from "../../components/ViewLoader";
import Footer from "../../components/../components/Footer";

import ryanAvatar from '../../assets/images/about/ryan.png';
import francisAvatar from '../../assets/images/about/francis.png';
import steveAvatar from '../../assets/images/about/steve.png';
import sylvainAvatar from '../../assets/images/about/sylvain.png';
import alexandreAvatar from '../../assets/images/about/alexandre.png';
import amazonLogo from '../../assets/images/about/amazon.png';
import touchLogo from '../../assets/images/about/touch-of-modern.png';
import ebayLogo from '../../assets/images/about/ebay.png';

interface TeamMember {
  name: string;
  position: string;
  email?: string;
  image: string;
  avatar?: string;
  initials?: string;
}

export default function About() {
  const {t} = useTranslation();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);

  useEffect(() => {
    const members: TeamMember[] = [
      {
        name: 'Ryan Rees',
        position: 'Business Dev. & Co-Founder',
        email: 'ryan.rees@husboro.com',
        image: ryanAvatar
      },
      {
        name: 'Francis Fontaine',
        position: 'Business Dev. & Co-Founder',
        email: 'francis.fontaine@husboro.com',
        image: francisAvatar
      },
      {
        name: 'Steve Paquette',
        position: 'Technical Lead',
        email: 'steve.paquette@husboro.com',
        image: steveAvatar
      },
      {
        name: 'Sylvain Beaudet',
        position: 'President of the Board',
        image: sylvainAvatar
      },
      {
        name: 'Alexandre Masse',
        position: 'Board Members',
        image: alexandreAvatar
      }
    ];

    setTeamMembers(members.map(teamMember => {
      if (!teamMember.avatar) {
        let nameSplit = teamMember.name.split(' ');
        let initials = nameSplit[0][0];

        if (nameSplit.length > 1) {
          initials += nameSplit[nameSplit.length - 1][0];
        }

        return {
          ...teamMember,
          initials,
          hex: strToHex(initials)
        }
      }
      return teamMember;
    }));
  }, []);


  const etailers = useMemo(() => [
    {
      name: 'Amazon',
      logo: amazonLogo
    },
    {
      name: 'Touch of Modern',
      logo: touchLogo
    },
    {
      name: 'Ebay',
      logo: ebayLogo
    }
  ], []);

  return (
    <div className="view-wrapper">
      <Header/>

      <ViewLoader/>

      <div className="view header-margin about">
        <div className="section-header">
          <div className="section-inner">
            <h1 className="dash dash-after">
              {t('about.title')}
            </h1>
            <div>
              <Trans i18nKey={'about.text'} />
            </div>
          </div>
        </div>
        <div className="about-etailers">
          <div className="section-inner">
            <div className="section-content">
              <h2 className="dash dash-after">
                {t('about.etailers.title')}
              </h2>
              <div>
                <Trans i18nKey={'about.etailers.text'} />
              </div>
            </div>
            <div className="section-content">
              <div className="about-etailers-grid">
                {etailers.map(etailer => (
                  <div key={etailer.name}>
                    <ImagePreloader url={etailer.logo} alt={etailer.name}/>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="about-team">
          <div className="section-inner">
            <div className="section-content">
              <h2 className="dash dash-after">
                {t('about.team.title')}
              </h2>
              <div>
                <Trans i18nKey={'about.team.text'} />
              </div>
            </div>
            <div className="section-content">
              <div className="about-team-grid">
                {teamMembers.map(member => (
                  <div key={member.name}>
                    <Avatar className="avatar-large" model={member} apiFilesUrl={''} />
                    <strong>{member.name}</strong>
                    <span>{member.position}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
