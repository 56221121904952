import * as React from "react";
import { Outlet, useMatch } from "react-router-dom";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import Header from "../../components/Header";
import ViewLoader from "../../components/ViewLoader";
import Footer from "../../components/Footer";
import TabBar from "src/components/TabBar";

export default function Account() {
  const match = useMatch('/account/:section/*');

  const currentSection = match?.params.section || 'dashboard';
  const user = useSelector((state: any) => state.user);
  const userType = user?.profile?.type?.id;
  const isBuyer = userType === 2;

  const tabs = [
    {
      section: 'dashboard',
      translate: '_account.dashboard.tabs.dashboard',
      url: '/account/dashboard'
    },
    {
      section: 'offers',
      translate: '_account.dashboard.tabs.offer',
      url: '/account/offers'
    },
    {
      section: 'messages',
      translate: '_account.dashboard.tabs.message',
      url: '/account/messages'
    },
    {
      section: 'company',
      translate: '_account.dashboard.tabs.company',
      url: '/account/company'
    },
    ...(!isBuyer ? [{
      section: 'brands',
      translate: '_account.dashboard.tabs.brand',
      url: '/account/brands'
    }] : []),
    {
      section: 'user',
      translate: '_account.dashboard.tabs.account',
      url: '/account/user'
    }
  ] as any;

  const currentClass = useMemo(() => {
    switch (currentSection) {
      case 'dashboard':
        return 'account-dashboard';
      case 'company':
        return 'account-company';
      case 'brands':
        return 'account-brand';
      case 'offers':
        return 'account-offer';
      case 'messages':
        return 'account-message';
      default:
        return '';
    }
  }, [currentSection]);

  return (
    <div className="view-wrapper">
      <Header/>
      <ViewLoader/>
      <div className={`view view-content header-margin ${currentClass}`}>
        <TabBar tabs={tabs} active={currentSection} />
        <Outlet />
      </div>
      <Footer/>
    </div>
  );
}
